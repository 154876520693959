import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { msalConfig } from '../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import config from '../config';

//const buildings_api = process.env.REACT_APP_BUILDING_API_URL;
const buildings_api = config.apiRoot;

const msalInstance = new PublicClientApplication(msalConfig);

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${buildings_api}/building-api`,

    prepareHeaders: async (headers) => {
      try {
        const account = msalInstance.getAllAccounts()[0];
        const token = await msalInstance.acquireTokenSilent({
          //scopes: [`${process.env.REACT_APP_CLIENT_SCOPE}`], // API scopes
          scopes: [`${config.apiScope}`], // API scopes
          forceRefresh: false, // set to true to skip cache
          account: account,
        });
        if (token) {
          headers.set('authorization', `Bearer ${token.accessToken}`);
          headers.set('x-requested-with', 'XMLHttpRequest');
          headers.set('Accept', 'application/json');
          let currentTime = new Date();
          localStorage.setItem('accessTokenIESConnectTime', currentTime);
          //localStorage.setItem('accessTokenIESConnect', token.accessToken);
        }
        return headers;
      } catch (e) {
        window.location.href = '/login';
        console.log(e);
      }
    },
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => '/users',
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'User', id })), { type: 'User', id: 'LIST' }]
          : [{ type: 'User', id: 'LIST' }],
    }),
    getUserById: builder.query({
      query: (idp_user_id) => `/users/${idp_user_id}`,
      providesTags: (result, error, idp_user_id) => [{ type: 'User', idp_user_id }],
    }),
    getUserByEmail: builder.query({
      query: ({ idp_user_id, email }) => `/users/${idp_user_id}/email/${email}`,
      providesTags: (result, error, email) => [{ type: 'User', email }],
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: '/users',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useGetUserByEmailQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;
