import React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Breadcrumbs from '../Breadcrumbs';
import BuildingList from './BuildingList';
import { Box, Logo } from '@ies-ds/react-ui';
import BuildingDetail from './BuidingDetail';
import UserdropDown from '../../components/ui/UserDropdown';
import { level2 } from '../ui/colors';
import Surface from '../ui/Surface';
import BuildingListToggle from './BuildingListToggle';
import { useGetAuthInfoQuery } from '../../services/building';
import HCLOGOTONE1 from '../../assets/img/hc-logo-tone1.png';
import HCLOGOWHITE from '../../assets/img/hc-logo.png';

const StyledBuildingContainer = styled.div`
  padding: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  width: 100%;

  > ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #1e293b;
    background: #24334a;
  }

  ::-webkit-scrollbar-thumb {
    background: #2b3e5c;
    background: #1b4d9d;

    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Buildings = () => {
  //const { loading, data } = useGetAuthInfoQuery();

  return (
    <>
      <Box
        alignItems="center"
        style={{ padding: '0 24px', height: '56px', background: '#1a2332', borderBottom: '1px solid #1e2c42' }}
        justifyContent="space-between"
      >
        <Box gap={16}>
          {/* <Logo /> */}
          <img src={HCLOGOWHITE} alt="Highland Council" style={{ width: '80px' }} />
          {/* {loading ? 'yes' : console.log('auth', data)} */}
          <Breadcrumbs />
        </Box>

        <UserdropDown />
      </Box>

      <Box direction="column" alignItems="stretch" flex={1} style={{ overflow: 'hidden' }}>
        <BuildingListToggle />
      </Box>
    </>
  );
};

export default Buildings;
