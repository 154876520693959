import { configureStore } from '@reduxjs/toolkit';
import msalMiddleware from './msalMiddleware';
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from 'redux';
import { userApi } from '../services/user';
import { roleApi } from '../services/role';
import { invitationApi } from '../services/invitation';

import { buildingApi } from '../services/building';
import { floorApi } from '../services/floor';

import { channelsApi } from '../services/channels';
import { scanApi } from '../services/scan';

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [invitationApi.reducerPath]: invitationApi.reducer,
  [buildingApi.reducerPath]: buildingApi.reducer,
  //  [floorApi.reducerPath]: floorApi.reducer,
  [channelsApi.reducerPath]: channelsApi.reducer,
  [scanApi.reducerPath]: scanApi.reducer,
});

export const store = configureStore({
  devTools: {
    name: 'IES App Manager',
  },
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      //msalMiddleware,
      userApi.middleware,
      roleApi.middleware,
      invitationApi.middleware,
      buildingApi.middleware,
      //floorApi.middleware
      channelsApi.middleware,
      scanApi.middleware
    ),
});

setupListeners(store.dispatch);
