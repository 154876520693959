import React from 'react';
import styled from 'styled-components';

const size = 21;
const border = 4;

const statusColor = {
  warning: '#ffd314', // amber
  normal: '#00ff33',
  unknown: '#6e798b', // grey
  good: '#00ff33', // green
  bad: '#ef4443', // red
  loading: '#fff', // grey
  external: '#1b72ff', // blue
};

const StyledPin = styled.div`
  width: 29px;
  height: 29px;
  border: solid transparent 2px;
  border-radius: 50%;
  position: relative;

  .border-cut-bl {
    top: 0px;
    left: 0px;
    position: absolute;
    width: ${size}px;
    height: ${size}px;
    z-index: 0;
    border-right: solid ${(props) => statusColor[props.status]} ${border}px;
    border-top: solid transparent ${border}px;
    border-bottom: solid transparent ${border}px;
    border-radius: 50%;
    transform: rotate(45deg);
  }
  .border-cut-br {
    top: 0px;
    left: 0px;
    position: absolute;
    width: ${size}px;
    height: ${size}px;
    z-index: 0;
    border-right: solid ${(props) => statusColor[props.status]} ${border}px;
    border-top: solid transparent ${border}px;
    border-bottom: solid transparent ${border}px;
    border-radius: 50%;
    transform: rotate(135deg);
  }
  .border-cut-tl {
    top: 0px;
    left: 0px;
    position: absolute;
    width: ${size}px;
    height: ${size}px;
    z-index: 0;
    border-right: solid ${(props) => statusColor[props.status]} ${border}px;
    border-top: solid transparent ${border}px;
    border-bottom: solid transparent ${border}px;
    border-radius: 50%;
    transform: rotate(225deg);
  }
  .border-cut-tr {
    top: 0px;
    left: 0px;
    position: absolute;
    width: ${size}px;
    height: ${size}px;
    z-index: 0;
    border-right: solid ${(props) => statusColor[props.status]} ${border}px;
    border-top: solid transparent ${border}px;
    border-bottom: solid transparent ${border}px;
    border-radius: 50%;
    transform: rotate(315deg);
  }
  .line {
    position: absolute;
    width: 100%;
    height: 1px;
    background: #1a2332;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    opacity: 0.5;
  }
  .line-vertical {
    position: absolute;
    width: 1px;
    height: 100%;
    background: #1a2332;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0.5;
  }
`;

const FloorStatusPin = ({ position, status }) => {
  return (
    <StyledPin status={status}>
      {position === 'tl' && <div className="border-cut-tl"></div>}
      {position === 'tr' && <div className="border-cut-tr"></div>}
      {position === 'bl' && <div className="border-cut-bl"></div>}
      {position === 'br' && <div className="border-cut-br"></div>}
      {position === 'all' && (
        <>
          <div className="border-cut-tl"></div>
          <div className="border-cut-tr"></div>
          <div className="border-cut-bl"></div>
          <div className="border-cut-br"></div>
        </>
      )}
      {position === 'external' && (
        <>
          <div className="border-cut-tl"></div>
          <div className="border-cut-tr"></div>
          <div className="border-cut-bl"></div>
          <div className="border-cut-br"></div>
        </>
      )}
      {/* <div className="line"></div>
      <div className="line-vertical"></div> */}
    </StyledPin>
  );
};

export default FloorStatusPin;
