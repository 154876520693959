import moment from 'moment';

import { useGetChannelDataSummaryRangeMeteredQuery } from '../services/scan';
import { Warning } from '@phosphor-icons/react';
import { Loader } from '@ies-ds/react-ui';

let start_date = moment().subtract(0, 'years').subtract(3, 'days').toISOString().substring(0, 10);

export function useShowResultDataQuery(
  channelID,
  period = '1440',
  fixed = 0,
  startDate = start_date,
  project = 'HighlandCouncil',
  building = 'CharlesKennedyBuilding'
) {
  const {
    isLoading: load,
    isFetching: fetch,
    data,
    error,
  } = useGetChannelDataSummaryRangeMeteredQuery(
    {
      project: project,
      building: building,
      channelId: channelID,
      period: period,
      startDate: startDate,
    },
    {
      //refetchOnMountOrArgChange: true,
      //pollingInterval: 3600
    }
  );

  if (load) {
    return undefined;
  }

  if (error) {
    return <Warning color="#fff" />;
  }

  return fetch || load || null || typeof data.Mean?.length == undefined
    ? '--'
    : // : data.Mean[data.Mean.length - 1] == null
    // ? '--'
    isNaN(parseInt(getLastNotNullValue(data), 10))
    ? '--'
    : //: parseInt(getLastNotNullValue(data), 10);
      getLastNotNullValue(data).toFixed(fixed);
}

export function useGetWellness(min, max, channelID, period = '1440', startDate = start_date) {
  let currentValue = '--';

  const {
    isLoading: loading,
    isFetching: fetching,
    data,
    error,
  } = useGetChannelDataSummaryRangeMeteredQuery(
    {
      project: 'HighlandCouncil',
      building: 'CharlesKennedyBuilding',
      channelId: channelID,
      period: period,
      startDate: startDate,
    },
    {
      //refetchOnMountOrArgChange: true,
      //pollingInterval: 3600
    }
  );

  const level =
    fetching || loading || typeof data.Mean?.length == undefined ? (currentValue = '--') : getLastNotNullValue(data);

  if (level === '--') {
    currentValue = '--';
  } else if (level < min) {
    currentValue = 'Low';
  } else if (level > max) {
    currentValue = 'High';
  } else {
    currentValue = 'Good';
  }

  return currentValue;
}

export function getLastNotNullValue(value, type = 'Mean') {
  const current = value[type];
  const invertedArry = [];
  const newArry = [];

  for (var i = current?.length - 1; i >= 0; i--) {
    invertedArry.push(current[i]);
  }

  invertedArry.map((item) => {
    return item != null ? newArry.push(item) : null;
  });

  return newArry[0];
}
