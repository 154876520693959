import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Modal, Select, Textfield, Text } from '@ies-ds/react-ui';
import { usePostSendInvitationMutation } from '../../services/invitation';
import { useGetAllRolesQuery } from '../../services/role';

const InvitationInvite = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { data: roles, error, isLoading: isLoadingRoles } = useGetAllRolesQuery();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [postSendInvitation, { isError, isSuccess, isLoading, error: errorInvite }] = usePostSendInvitationMutation();

  const onSubmit = (data) => {
    console.log({ ...data, inviterId: '1' });
    postSendInvitation({ ...data, inviterId: '1' });
  };

  if (isLoadingRoles) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Send Invite
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        <Modal.Header>
          <Text weight="medium" size="large">
            Invite New User
          </Text>
        </Modal.Header>
        <Modal.Content>
          {isLoading && <p>Loading...</p>}
          {errorInvite && <p style={{ color: 'red' }}>{errorInvite.data.error}</p>}
          {isError && <p>Error sending invite</p>}
          {isSuccess && <p style={{ color: 'green' }}>Invite sent successfully</p>}

          <form>
            <Textfield type="email" label="Email" placeholder="Enter email address" {...register('email')} required />
            <select label="Role" placeholder="Select role" {...register('roleId')}>
              {roles.map((role) => (
                <option value={role.id}>{role.name}</option>
              ))}
            </select>
          </form>
        </Modal.Content>
        <Modal.Footer>
          <Button color="primary" onClick={handleSubmit(onSubmit)}>
            Send Invite
          </Button>{' '}
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InvitationInvite;
