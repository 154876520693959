import { Box, Text } from '@ies-ds/react-ui';
import styled from 'styled-components';
import Surface from '../ui/Surface';
import { SurfaceColour2 } from '../config/app';
import { CheckCircle } from '@phosphor-icons/react';

const kpiSize = {
  small: { textSize: '24px', height: '40px', textSizeUnit: '14px', titleSize: '14px' },
  large: { textSize: '32px', height: '80px', textSizeUnit: '16px', titleSize: '16px' },
  xlarge: { textSize: '40px', height: '130px', textSizeUnit: '18px', titleSize: '18px' },
};
const Title = styled(Text)`
  text-align: center;
  font-size: ${(props) => kpiSize[props.size].titleSize};
  color: ${(props) => props.color};
  font-weight: 500;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const Value = styled(Box)`
  text-align: center;
  height: ${(props) => kpiSize[props.size].height};
`;

const Data = styled(Text)`
  color: #fff;
  text-align: center;
  font-size: ${(props) => kpiSize[props.size].textSize};
  font-weight: 600;
`;

const Unit = styled(Text)`
  color: #94a3b8;
  width: 50%;
  text-align: left;
  font-size: ${(props) => kpiSize[props.size].textSizeUnit};
`;

const UnitPrepend = styled(Text)`
  color: #94a3b8;
  width: 50%;
  text-align: right;
  font-size: ${(props) => kpiSize[props.size].textSizeUnit};
`;

const MiniKpi = ({
  title = 'title',
  size = 'small',
  color = '#fde500',
  inRange = false,
  value = 200,
  prependUnit = '',
  unit = '',
  bgColor = SurfaceColour2,
  height,
}) => {
  return (
    <Surface bgColor={bgColor} height={height}>
      <Box direction="column" justifyContent="center" style={{ height }}>
        <Title size={size} color={color}>
          {title}
        </Title>
        <Value size={size} direction="row" justifyContent="space-around" gap={6} alignItems="center" flexGrow={1}>
          <UnitPrepend size={size}>{prependUnit}</UnitPrepend>
          <Data size={size}>{value}</Data>
          <Unit size={size}> {unit}</Unit>
        </Value>
        {inRange && (
          <Box gap={4} style={{ textAlign: 'center' }} direction="row" alignItems="center" justifyContent="center">
            <CheckCircle size={18} color={'rgb(74, 222, 128)'} />
            <div style={{ color: 'rgb(74, 222, 128)', fontSize: '10px' }}>In expected Range</div>
          </Box>
        )}
      </Box>
    </Surface>
  );
};

export default MiniKpi;
