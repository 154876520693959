import React from 'react';

import { Box, Loader, ThemeProvider, dark } from '@ies-ds/react-ui';
import { StyledDashboardContainer } from '../LayoutDashboardv2';

const PageLoading = () => {
  return (
    <ThemeProvider theme={dark}>
      <StyledDashboardContainer>
        <Box justifyContent="center" style={{ height: '100%', width: '100%' }}>
          <Box direction="column" alignItems="center" justifyContent="center">
            <Loader type="dots" />
          </Box>
        </Box>
      </StyledDashboardContainer>
    </ThemeProvider>
  );
};

export default PageLoading;
