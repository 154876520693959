import React, { useEffect, useState } from 'react';
import { NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useGetBuildingByIdQuery, useGetFloorsByBuildingIdQuery } from '../../services/building';
import { Box, Loader, Text, Textfield } from '@ies-ds/react-ui';
import ScrollBox from '../ui/ScrollBox';
import styled from 'styled-components';
import { StackSimple } from '@phosphor-icons/react';
import FloorDetail from '../FloorDetail';
import { SurfaceColour, SurfaceColour2, SurfaceColour3 } from '../config/app';
import Surface from '../ui/Surface';

const StyledLink = styled(NavLink)`
  padding: 6px 8px;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
  }
  &.active {
    background: #171e2c;
  }
`;

const StyledTextfield = styled(Textfield)`
  padding: 6px 12px;
  font-size: 14px;
  background: #2e3649;
`;

const BuildingFloors = ({ floors }) => {
  const { buildingId } = useParams();
  const navigate = useNavigate();

  const { isLoading, isFetching, data: building } = useGetBuildingByIdQuery(buildingId);
  // const { isLoading: loadingFloors, data: floors } = useGetFloorsByBuildingIdQuery(buildingId);

  const [inputValueFloor, setInputFloor] = useState('');

  const [filteredDataFloor, setFilteredDataFloor] = useState([]);

  useEffect(() => {
    const filteredFloor = floors?.filter((data) => data.name.toLowerCase().includes(inputValueFloor.toLowerCase()));
    //floors.length > 0 && navigate(`${floors[0]?.id}`);
    setFilteredDataFloor(filteredFloor);
  }, [inputValueFloor, floors]);

  if (isLoading || isFetching)
    return (
      <Box justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <Loader type="dots" />
      </Box>
    );

  return (
    <>
      <Box flex={1} direction="row" style={{ padding: '16px 16px 0 16px ', overflow: 'hidden' }}>
        <Box direction="column" style={{ width: '250px' }}>
          <StyledTextfield
            placeholder="Filter Floors..."
            onChange={(e) => setInputFloor(e.target.value)}
            value={inputValueFloor}
          />
          <Text color="lightest" size="xsmall" style={{ marginTop: '-12px', marginBottom: '12px' }}>
            Count: {floors?.length}
          </Text>
          <ScrollBox>
            {filteredDataFloor < 1 ? (
              <Text color="light"> No Floor</Text>
            ) : (
              filteredDataFloor?.map((floor) => {
                return (
                  <StyledLink to={`../floor/${floor.id}`}>
                    <Box direction="column" alignItems="flex-start" gap={8}>
                      <Box direction="row" gap={8}>
                        <StackSimple size={20} color={'#3b82f6'} />
                        <Box direction="column">
                          <Text color="light" style={{ lineHeight: '1.4' }}>
                            {' '}
                            {floor.name}
                          </Text>
                          <Text color="lightest" size="xsmall">
                            {floor.zones.length ? `${floor.zones.length} Sensors  ` : null}
                            {/* {floor.sensors.length} Data Points */}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </StyledLink>
                );
              })
            )}
          </ScrollBox>
        </Box>
        <ScrollBox
          style={{ padding: '16px  24px', marginLeft: '16px', background: SurfaceColour3, borderRadius: '6px' }}
        >
          <Routes>
            <Route path="/:floorId/*" element={<FloorDetail building={building} />} />
            <Route path="/*" element={<CheckIfRouteExistAndNaviteToFirstId id={floors[0]?.id} />} />
          </Routes>
        </ScrollBox>
      </Box>
    </>
  );
};

export default BuildingFloors;

const CheckIfRouteExistAndNaviteToFirstId = ({ id }) => {
  const navigate = useNavigate();
  return <>{navigate(`${id}`)}</>;
};
