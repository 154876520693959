import { Box, Text } from '@ies-ds/react-ui';
import { CaretRight } from '@phosphor-icons/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {
  useGetBmsSensorByIdQuery,
  useGetBuildingByIdQuery,
  useGetFloorByIdQuery,
  useGetSensorByIdQuery,
  useGetZoneByIdQuery,
} from '../services/building';

const BuildingName = ({ match }) => {
  const { isLoading: isLoadingBuilding, data: building } = useGetBuildingByIdQuery(match.params.id);

  if (isLoadingBuilding) {
    return <span>...</span>;
  }
  return <span>{building.name}</span>;
};

const FloorName = ({ match }) => {
  const { isLoading: isLoadingFloor, data: floor } = useGetFloorByIdQuery(match.params.floorId);

  if (isLoadingFloor) {
    return <span>...</span>;
  }
  return <span>{floor.name}</span>;
};

const SensorName = ({ match }) => {
  const { isLoading: isLoadingSensor, data: sensor } = useGetSensorByIdQuery(match.params.sensorId);

  if (isLoadingSensor) {
    return <span>...</span>;
  }
  return <span>{sensor.name}</span>;
};

const ZoneName = ({ match }) => {
  const { isLoading: isLoadingZone, data: zone } = useGetZoneByIdQuery(match.params.zoneId);

  if (isLoadingZone) {
    return <span>...</span>;
  }
  return <span>{zone.name}</span>;
};

const BmsSensorName = ({ match }) => {
  const { isLoading: isLoadingSensor, data: sensor } = useGetBmsSensorByIdQuery(match.params.sensorId);

  if (isLoadingSensor) {
    return <span>...</span>;
  }
  return <span>{sensor.name}</span>;
};

const routes = [
  // { path: '/', breadcrumb: null },
  { path: '/admin', breadcrumb: 'Home' },
  { path: '/buildings/:id', breadcrumb: BuildingName },
  { path: '/buildings/:id/floor', breadcrumb: null },
  { path: '/buildings/:id/floor/:floorId', breadcrumb: FloorName },
  { path: '/buildings/:id/zone', breadcrumb: null },
  { path: '/buildings/:id/zone/:zoneId', breadcrumb: ZoneName },
  { path: '/buildings/:id/zone/:zoneId/sensor', breadcrumb: null },
  { path: '/buildings/:id/zone/:zoneId/sensor/:sensorId', breadcrumb: SensorName },

  { path: '/buildings/:id/sensor', breadcrumb: null },
  { path: '/buildings/:id/sensor/:sensorId', breadcrumb: SensorName },
  { path: '/buildings/:id/floor/:floorId/sensor', breadcrumb: null },
  { path: '/buildings/:id/floor/:floorId/sensor/:sensorId', breadcrumb: SensorName },

  { path: '/buildings/:id/iot-calorifier', breadcrumb: 'IoT-calorifier' },
  { path: '/buildings/:id/iot-calorifier/:sensorId', breadcrumb: BmsSensorName },
];

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Box direction="row" alignItems="center" gap={8}>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <Box alignItems="center" gap={8}>
          <NavLink key={match.pathname} to={match.pathname} style={{ textDecoration: 'none' }}>
            <Text size="small" color="lighter">
              {breadcrumb}{' '}
            </Text>
          </NavLink>
          {index < breadcrumbs.length - 1 && <CaretRight size={12} color="#aaa" weight="bold" />}
        </Box>
      ))}
    </Box>
  );
};

export default Breadcrumbs;
