import React from 'react';
import styled from 'styled-components';
import * as DT from '@ies-ds/design-tokens';
import { Text } from '@ies-ds/react-ui';
import { NavLink } from 'react-router-dom';

const StyledTab = styled(NavLink)`
  padding: 8px 16px;
  height: 36px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  color: ${DT.COLOR_GRAY_500};
  text-decoration: none;
  &.active {
    border-bottom: 2px solid ${DT.COLOR_BLUE_500};
    color: ${DT.COLOR_BLUE_700};
  }
  &:hover {
    color: ${DT.COLOR_BLUE_600};
  }
`;

const Tab = ({ children, ...props }) => {
  return (
    <StyledTab {...props}>
      <Text weight="medium" size="small">
        {children}
      </Text>
    </StyledTab>
  );
};

export default Tab;
