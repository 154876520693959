import { Box, Grid, Text } from '@ies-ds/react-ui';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserList from '../user/UserList';
import RoleList from '../role/RoleList';

import Breadcrumbs from './Breadcrumbs';
import UserdropDown from './UserDropdown';

const Page = ({ title = 'title not set', tabnav, element, children }) => {
  return (
    <Box direction="column" flex={1}>
      <Box direction="column" style={{ background: '#fff' }}>
        <Box direction="row" justifyContent="space-between" style={{ minHeight: '60px', padding: '20px 32px' }}>
          <Box direction="column" gap={12} style={{ borderBottom: '1px solid #f3f2f7' }}>
            <Breadcrumbs />
            <Text size={'2xl'} weight="bold">
              {title}
            </Text>
          </Box>
          <UserdropDown />
        </Box>
      </Box>{' '}
      {element}
    </Box>
  );
};

export default Page;
