import styled from 'styled-components';
import { SurfaceColour } from '../config/app';

const StyledContainer = styled.div`
  padding: ${(props) => props.padding};
  background: ${(props) => props.bgColor};

  min-height: ${(props) => props.height};
  border-radius: 8px;
`;

const Surface = ({ padding = '12px', height, children, bgColor = SurfaceColour, ...props }) => {
  return (
    <StyledContainer padding={padding} height={height} bgColor={bgColor} {...props}>
      {children}
    </StyledContainer>
  );
};

export default Surface;
