import React from 'react';
import { useDeleteSensorTypeMutation } from '../../services/building';
import { Box, Button, Modal, Text } from '@ies-ds/react-ui';

const SensorTypeDelete = ({ id }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [deleteSensorType, { isError, isLoading, isSuccess }] = useDeleteSensorTypeMutation();

  return (
    <div>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        Delete
      </Button>
      <Modal
        closeButton
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Modal.Header>
          <Text weight={'medium'} size="large">
            Delete sensor type
          </Text>
        </Modal.Header>
        <Modal.Content>
          <Text weight="medium">Are you sure you want to delete this sensor type?</Text>
          <br />
          {/* <p>All floor and sensor data associated with the sensor will be permanently deleted as well!</p> */}
        </Modal.Content>

        <Modal.Footer>
          <Box gap={8}>
            <Button color="warning" onClick={() => deleteSensorType(id)}>
              Delete
            </Button>
            <Button onClick={() => setIsOpen(false)}>Close</Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SensorTypeDelete;
