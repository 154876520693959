import React from 'react';
import { useGetAllInvitationsQuery } from '../../services/invitation';
import { Box, Container, Table, Text } from '@ies-ds/react-ui';
import InvitationInvite from './InvitationInvite';
import InvitationDelete from './InvitationDelete';
import moment from 'moment';

const InvitationList = () => {
  const { data: invitations, error, isLoading } = useGetAllInvitationsQuery();

  return (
    <div>
      <Box alignItems="center" justifyContent="space-between">
        <Text size="large" weight="medium">
          Invitations
        </Text>
        <InvitationInvite />
      </Box>

      <br />

      <Container shadow="low">
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Status</th>
              <th>createdAt</th>
              <th>Invitation Link</th>
              <th>Role</th>
              <th>Invited By</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <p>Loading invitations...</p>
            ) : (
              invitations.map((invitation) => (
                <tr key={invitation.id}>
                  <td>{invitation.id} </td>
                  <td>{invitation.email}</td>
                  <td>{invitation.status}</td>
                  <td>{moment(invitation.created_at).format(' Do MMM YYYY @ HH:mm')}</td>
                  <td>
                    <a target="_blank" href={`/accept-invitation?token=${invitation.token}`} rel="noreferrer">
                      Invitation Link
                    </a>
                  </td>
                  <td>
                    {invitation.role.name} [{invitation.role_id}]
                  </td>
                  <td>
                    {invitation.inviter.email} [{invitation.inviter.id}]
                  </td>
                  <td>
                    <InvitationDelete id={invitation.id} />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default InvitationList;
