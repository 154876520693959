import { Box, Table, Text } from '@ies-ds/react-ui';
import React from 'react';
import { useParams } from 'react-router';
import ScrollBox from '../ui/ScrollBox';
import { Link } from 'react-router-dom';
import StatusCell from '../ui/StatusCell';
import styled from 'styled-components';
import { level2 } from '../ui/colors';
import { SurfaceColour, SurfaceColour3 } from '../config/app';

const StyledTableContainer = styled.div`
padding-bottom: 20px;
border-radius: 6px;
  background-color: ${level2};
  margin-bottom: 20px;
  table {
    border-bottom: 1px solid ${SurfaceColour3};
  }

  th {
    padding: 20px;
    //background-color: ${SurfaceColour};
    text-align: left;
    color: #fff;
    align-items: center;
    text-align: center;
    width: 20%;
    border-bottom: 1px solid ${SurfaceColour3};

    &:first-child {
      text-align: left;
      width: 15%;
      
    }
  }
  tr {
    &:hover {
      background-color: unset;
    }
  td {
    padding: 1px;
    // background-color: ${SurfaceColour};
    border-bottom: 1px solid ${SurfaceColour3};
    border-right: 1px solid #1e293b;
    text-align: center;
    &:first-child {
      padding: 12px 16px;
      text-align: left;
      border-right: 1px solid #1e293b;
    }
    &:last-child {
      border-right: 1px solid ${SurfaceColour3};

    }
  }
`;

const BuildingBMS = ({ bmsSensors, type }) => {
  const { buildingId } = useParams();

  const filteredSensors = bmsSensors?.filter((sensor) => sensor.type.type === type);

  return (
    <Box flex={1} direction="row" style={{ padding: '16px ', overflow: 'hidden' }}>
      <ScrollBox>
        <StyledTableContainer>
          <Table stripedColors={true}>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Value</th>

              {/* <th>actions</th> */}
            </tr>
            {filteredSensors < 1 ? (
              <Text color="light"> No BMS Sensors</Text>
            ) : (
              filteredSensors?.map((bmsSensor) => {
                return (
                  <tr>
                    <td>
                      <Text color="dark"> {bmsSensor.name}</Text>
                    </td>
                    <td>
                      <Text color="light">
                        {' '}
                        {bmsSensor.type.type} {bmsSensor.type.name}
                      </Text>
                    </td>
                    <td>
                      <Text color="dark">
                        <StatusCell
                          to={'sensor/' + bmsSensor.id}
                          label={bmsSensor.name}
                          channel={bmsSensor.channel}
                          unit={'C'}
                          min={0}
                          max={10000000000}
                          building={bmsSensor.scanBuildingName}
                        />
                      </Text>
                    </td>
                    {/* <td>
                      <Text color="light">{bmsSensor.channel}</Text>
                    </td> */}
                  </tr>
                );
              })
            )}
          </Table>
        </StyledTableContainer>
      </ScrollBox>
    </Box>
  );
};

export default BuildingBMS;
