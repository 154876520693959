import { IdentificationBadge } from '@phosphor-icons/react';
import React from 'react';
import { useDeleteMeterMutation } from '../../services/building';
import { Button } from '@ies-ds/react-ui';

const MeterDelete = ({ id }) => {
  const [deleteMeter, { isError, isLoading, isSuccess }] = useDeleteMeterMutation();

  return (
    <Button size="xsmall" onClick={() => deleteMeter(id)}>
      Delete Meter
    </Button>
  );
};

export default MeterDelete;
