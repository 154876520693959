import { Box, Button, Text, ThemeProvider, dark } from '@ies-ds/react-ui';
import React from 'react';
import { loginRequest } from '../../authConfig';
import { useSearchParams } from 'react-router-dom';
import { useGetAcceptInvitationQuery } from '../../services/invitation';
import { PanelLv1, StyledDashboardContainer } from '../LayoutDashboardv2';
import HCLOGOCOLOR from '../../assets/img/hc-logo-white.png';
import { CheckCircle, WarningCircle } from '@phosphor-icons/react';

const InvitationAccept = ({ instance }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get('token');

  const { isLoading, data, isError, error } = useGetAcceptInvitationQuery(token);

  if (isLoading) return <div>Loading...</div>;

  /// get error message from api 500
  //if (isError) return <div>{console.log('EEEEEE', error.data.error)}</div>;

  return (
    // <>
    //   <h1>Invitation Accept- {data?.message}</h1>
    //   {token}
    //   <Button style={{ width: '100%' }} color="primary" onClick={() => instance.loginRedirect(loginRequest)}>
    //     Enter Dashboard
    //   </Button>
    // </>

    <ThemeProvider theme={dark}>
      <StyledDashboardContainer>
        <Box justifyContent="center" style={{ height: '100%', width: '100%' }}>
          <Box direction="column" alignItems="center" justifyContent="center">
            <PanelLv1 style={{ width: '400px' }}>
              <Box justifyContent="center" direction="column" alignItems="center" gap={24}>
                {/* <Logo logo="ies-light" width={60} height={60} /> */}
                <img src={HCLOGOCOLOR} alt="Highland Council Logo" width={150} />
                <Box justifyContent="center" direction="row" alignItems="center" gap={8}>
                  {isError ? (
                    <>
                      <WarningCircle color="#eb2664" size={35} />{' '}
                      <Text color="light" align="center" size="xlarge">
                        {error.data.error}
                      </Text>
                    </>
                  ) : (
                    <>
                      <CheckCircle color="#1ed357" size={35} />

                      <Text color="light" align="center" size="xlarge">
                        {data?.message}
                      </Text>
                    </>
                  )}{' '}
                </Box>
              </Box>
              <br />
              <Button
                style={{ width: '100%' }}
                id="login"
                color="primary"
                onClick={() => instance.loginRedirect(loginRequest)}
              >
                Enter Dashboard
              </Button>
            </PanelLv1>
          </Box>
        </Box>
      </StyledDashboardContainer>
    </ThemeProvider>
  );
};

export default InvitationAccept;
