const devDefault = {
  apiRoot: 'http://localhost:3003',
  clientId: 'd56bd19d-b6d9-4a19-8d9e-f23d930f7eb6',
  apiScope: 'https://connect.iesve.cloud/cef0d30c-27f3-4560-b31a-e8f6202f633a/access_as_user',
};

const qaDeployment = {
  apiRoot: 'xx',
  clientId: 'xx',
  apiScope: 'xx',
};

const prodDeployment = {
  apiRoot: 'https://hcd-api.saas.iesve.cloud',
  clientId: '2d6fc0a3-7ec7-427e-a56e-657a83ab8f6b',
  apiScope: 'https://connect.iesve.cloud/b1fabf7b-5a98-448b-a875-dc7fa6564558/access_as_user',
};

// const config = process.env.REACT_APP_ENV === 'production' ? prodDeployment : qaDeployment;

let config;

switch (process.env.REACT_APP_ENV) {
  case 'production':
    config = prodDeployment;
    break;
  case 'qa':
    config = qaDeployment;
    break;
  default:
    config = devDefault;
}

export default config;
