import { Box, Text } from '@ies-ds/react-ui';
import { CaretRight } from '@phosphor-icons/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useGetBuildingByIdQuery } from '../../services/building';

const BuildingName = ({ match }) => {
  const { isLoading, data } = useGetBuildingByIdQuery(match.params.id);

  if (isLoading) {
    return <span>...</span>;
  }
  return <span>{data.name}</span>;
};

const routes = [
  { path: '/', breadcrumb: null },
  { path: '/admin', breadcrumb: 'Home' },
  { path: '/admin/buildings/:id', breadcrumb: BuildingName },
];

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Box direction="row" alignItems="center" gap={8}>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <Box alignItems="center" gap={8}>
          <NavLink key={match.pathname} to={match.pathname} style={{ textDecoration: 'none' }}>
            <Text size="small" color="lighter">
              {breadcrumb}{' '}
            </Text>
          </NavLink>
          {index < breadcrumbs.length - 1 && <CaretRight size={12} color="#aaa" weight="bold" />}
        </Box>
      ))}
    </Box>
  );
};

export default Breadcrumbs;
