import React from 'react';
import { useGetAllSensorTypeQuery } from '../../services/building';
import { Box, Container, Table, Text } from '@ies-ds/react-ui';
import SensorTypeCreate from './SensorTypeCreate';
import SensorTypeDelete from './SensorTypeDelete';
import SensorTypeEdit from './SensorTypeEdit';

const SensorTypeList = () => {
  const { isloading, data: sensorTypes } = useGetAllSensorTypeQuery();

  return (
    <>
      <Box justifyContent="space-between" alignItems="center">
        <Text size="large" weight="bold">
          {' '}
          Sensor Types{' '}
        </Text>
        <br />
        <SensorTypeCreate />
      </Box>
      <br />
      <Container shadow="low">
        {isloading && <div>Loading...</div>}
        <Table>
          <tr>
            <th style={{ width: '20px' }}>ID</th>
            <th>Name</th>
            <th>Type</th>
            <th>Location</th>
            <th>Min Setpoint</th>
            <th>Max Setpoint</th>
            <th>Unit</th>
            <th></th>
          </tr>
          {sensorTypes?.map((sensorType) => (
            <tr key={sensorType.id}>
              <td>{sensorType.id}</td>
              <td>{sensorType.name}</td>
              <td>{sensorType.description}</td>
              <td>{sensorType.location}</td>
              <td>{sensorType.disableMin ? '- -' : sensorType.min}</td>
              <td>{sensorType.disableMax ? '--' : sensorType.max}</td>
              <td>{sensorType.unit}</td>
              <td>
                <Box gap={4} direction="row" justifyContent="flex-end">
                  <SensorTypeDelete id={sensorType.id} />
                  <SensorTypeEdit sensorType={sensorType} />
                </Box>
              </td>
            </tr>
          ))}
        </Table>
      </Container>
    </>
  );
};

export default SensorTypeList;
