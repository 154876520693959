import React from 'react';
import { useGetUtilitiesByBuildingIdQuery } from '../../services/building';
import { Box, Container, Table, Text } from '@ies-ds/react-ui';
import { useParams } from 'react-router-dom';
import UtilitiesCreate from './UtilitiesCreate';
import UtilitiesEdit from './UtilitiesEdit';
import UtilitiesDelete from './UtilitiesDelete';

const UtilitiesListByBuilding = () => {
  const { id } = useParams();

  const { isLoading, data: utilities, error } = useGetUtilitiesByBuildingIdQuery(id);

  if (isLoading) return 'Loading';

  return (
    <div>
      <Box justifyContent="space-between" alignItems="center">
        <Text> Utility List: You may only have 1 of each utility type </Text>
        <br />
        <UtilitiesCreate id={id} />
      </Box>
      <br />
      <Container shadow="low">
        <Table>
          <tr>
            <th style={{ width: '20px' }}>ID</th>
            <th>Type</th>
            <th>Use</th>
            <th> Cost</th>
            <th> EUI or per m2</th>
            <th> Carbon | Water N/A</th>
            <th>Supplier Info</th>
            <th></th>
          </tr>
          {isLoading ? (
            'Loader'
          ) : utilities.length === 0 ? (
            <tr>
              <td colSpan={3}>
                <Text>No utilities</Text>
              </td>
            </tr>
          ) : (
            utilities.map((utility) => (
              <>
                <tr style={{ margin: '8px 0' }}>
                  <td>{utility.id}</td>
                  <td style={{ textTransform: 'capitalize' }}>{utility.type}</td>
                  <td>{utility.channelTotal}</td>
                  <td>{utility.channelCost}</td>
                  <td>{utility.channelEui}</td>
                  <td>{utility.channelCarbon}</td>
                  <td>{utility.description}</td>
                  <td>
                    <Box justifyContent="flex-end" gap={8}>
                      <UtilitiesEdit utility={utility} />
                      <UtilitiesDelete id={utility.id} />
                    </Box>
                  </td>
                </tr>
              </>
            ))
          )}
        </Table>
      </Container>
    </div>
  );
};

export default UtilitiesListByBuilding;
