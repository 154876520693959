import React, { useEffect, useState } from 'react';
import { useGetAllBuildingsQuery } from '../../services/building';
import { Box, Text, Textfield } from '@ies-ds/react-ui';
import { level1, level2, level3, primary } from '../ui/colors';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import BuildingDetail from './BuildingDetail';
import styled from 'styled-components';
import ScrollBox from '../ui/ScrollBox';
import { Buildings, MagnifyingGlass } from '@phosphor-icons/react';
import BuildingLocations from '../Map/BuildingLocations';

const StyledTextfield = styled(Textfield)`
  padding: 8px 12px;
  font-size: 14px;
  background: rgb(33 45 64);
`;

const StyledBuildingItem = styled(NavLink)`
  padding: 8px 24px;
  display: block;
  text-decoration: none;
  &:hover {
    background: ${level3};
    text-decoration: none;
  }
  &.active {
    background: #262e3e;
    border-right: 2px solid ${primary};
  }
`;

const BuildingList = () => {
  const { isLoading, data: buildings, error } = useGetAllBuildingsQuery();

  const [inputValue, setInputValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filtered = buildings?.filter(
      (data) =>
        data.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        data.location.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredData(filtered);
  }, [inputValue, buildings]);

  return (
    <>
      <Box direction="row" alignItems="stretch" flex={1} style={{ overflow: 'hidden' }}>
        <Box style={{ width: '290px' }}>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <Box
              direction="column"
              flex={1}
              style={{
                background: level2,
                width: '280px',

                padding: ' 0px',

                // border: '1px solid red',
              }}
            >
              <div style={{ padding: '24px 24px 0 24px' }}>
                <Text size="xlarge" color="light" style={{ marginBottom: '12px' }}>
                  Buildings ({filteredData?.length || buildings?.length})
                </Text>

                <StyledTextfield
                  placeholder="Filter Buildings..."
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                />
                {error && <div>Something went wrong...</div>}
              </div>
              <ScrollBox>
                {filteredData < 1
                  ? 'No results'
                  : filteredData?.map((building) => <BuildingItem building={building} />)}
              </ScrollBox>
            </Box>
          )}
        </Box>
        <Routes>
          <Route
            path="/"
            element={
              <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                {isLoading ? <div>Loading...</div> : <BuildingLocations />}
              </div>
            }
          />
          <Route path=":buildingId/*" element={<BuildingDetail />} />
          <Route
            path="*"
            element={
              <Box flex={1} alignItems="center" justifyContent="center" direction="column" gap={8}>
                <MagnifyingGlass size={56} color="#64748b" />
                <Text size="large" color="lightest">
                  Select a building...
                </Text>
              </Box>
            }
          />
        </Routes>
      </Box>
    </>
  );
};

export default BuildingList;

const BuildingItem = ({ building }) => {
  return (
    <StyledBuildingItem to={`/buildings/${building.id}`}>
      <Box direction="column" gap={0}>
        <Box direction="row" gap={8} alignItems="center">
          {/* <Buildings size={18} color="#3b82f6" /> */}
          <Text style={{ color: '#cbd5e1' }}>{building.name}</Text>
        </Box>
        <Text color="light"> </Text>
        <Text color="lighter" size="xsmall">
          {building.location} | Floors: {building.floors.length}
        </Text>
      </Box>
    </StyledBuildingItem>
  );
};
