import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import moment from 'moment';

import styled from 'styled-components';
import { Box } from '@ies-ds/react-ui';
//import { WhiteText } from '../../components/KpiCard';

const StyledButton = styled.div`
  display: inline-block;
  border: 1px solid rgba(200, 200, 200, 0.5);
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
    background: #fde500;
    color: #000;
  }
`;

// this function will give 24 date vaules in 1 hour intervals from the current time on exact hour in the format ie YYYY-MM-DD HH:MM:SS
// function get24Hours() {
//   let currentDate = moment().format('YYYY-MM-DD HH:MM:SS');
//   let dateArray = [];
//   for (let i = 0; i < 24; i++) {
//     dateArray.push(moment(currentDate).subtract(i, 'hours').format('YYYY-MM-DD HH:MM:SS'));
//   }

//   return dateArray;
// }

var currentTimeStamp = new Date().getTime();
// Calculate the timestamp for 7 days ago (7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
var sevenDaysAgoDate = new Date(currentTimeStamp - 7 * 24 * 60 * 60 * 1000).getTime();

export default function ApexChart({
  id = 'defaultID',
  type = 'area',
  height = 230,
  title,
  color,
  units = 'units',
  toolbar = false,
  yTicks = 2,
  strokeShow = false,
  channel1 = [45, 52, 38, 44, 52, 38, 44],
  channel2 = [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
  yellow = [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
  timestamps = ['01 Jan', '02 Jan', '03 Jan'],
  toFixed = 1,
  minRange = -1000000000000,
  maxRange = 1000000000000,
  minDisabled = false,
  maxDisabled = false,
  startDate,
  endDate,
}) {
  useEffect(() => {
    //ApexCharts.exec('SC000058', 'updateSeries', channel1);
    //zoomFunction('this_week');
    setChartData({
      series: [
        {
          name: '',
          data: channel1,
        },
        // {
        //   name: 'Proposed',
        //   data: channel2,
        // },
        // {
        //   name: 'Yellow',
        //   data: yellow,
        // },
      ],
      options: {
        colors: [
          function ({ value, seriesIndex, w }) {
            if (
              Math.trunc(value) >= (minDisabled ? -Infinity : minRange) &&
              Math.trunc(value) <= (maxDisabled ? Infinity : maxRange)
            ) {
              return color;
            } else {
              return 'rgb(239, 68, 67)';
            }
          },
        ],
        // colors: [
        //   color,
        //   //'#87e1b1',
        //   // '#f3b647',
        //   // '#6a93f0',
        //   //'#ef4443',
        //   // '#22ccdd',
        //   '#FFA500',
        //   '#FFFF00',
        //   '#E91E63',
        //   '#FF9800',
        // ],
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        chart: {
          id: id,

          height: 100,
          type: 'line',
          zoom: {
            enabled: true,
          },
          animations: {
            enabled: false,
          },
          toolbar: {
            show: toolbar,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              },
            },
            autoSelected: 'zoom',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: strokeShow,
          width: [2, 2],
          curve: 'straight',
          dashArray: [8, 3, 5],
        },
        title: {
          text: '',
          align: 'left',
          style: {
            color: '#999',
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
        legend: {
          show: true,
          // tooltipHoverFormatter: function (val, opts) {
          //   return (
          //     val +
          //     ' - ' +
          //     opts.w.globals.series[opts.seriesIndex][
          //       opts.dataPointIndex
          //     ] +
          //     ''
          //   );
          // },
          labels: {
            colors: '#aaa',
            useSeriesColors: false,
          },
        },
        yaxis: {
          tickAmount: yTicks,
          labels: {
            style: {
              colors: '#64748b',
            },
            formatter: function (value) {
              return value == null ? '0' : value.toFixed(toFixed) + ' ' + units;
            },
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          min: sevenDaysAgoDate,
          max: currentTimeStamp,
          zoomX: {},
          type: 'datetime',
          tickAmount: 3,
          labels: {
            datetimeUTC: true,
            style: {
              colors: '#64748b',
            },
          },
          axisBorder: {
            show: true,
            color: '#333',
            height: 1,
            width: '100%',
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#999',
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          categories: timestamps,
        },
        tooltip: {
          theme: 'dark',
          x: {
            format: 'dd MMM yyyy, h:mm',
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return moment(value).zone('+0100').format('ddd D MMM yyyy, H:mm');
            },
          },
          y: [
            {
              title: {
                formatter: function (val) {
                  return val + '';
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val + '';
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                },
              },
            },
          ],
        },
        grid: {
          show: true,
          borderColor: 'rgba(255,255,255,0.1)',
          strokeDashArray: [4, 5],
        },
      },
    });
  }, [channel1, id]);

  const [chartData, setChartData] = useState({
    series: [
      {
        name: '',
        data: channel1,
      },
      // {
      //   name: 'name2',
      //   data: channel2,
      // },
      // {
      //   name: 'Yellow',
      //   data: yellow,
      // },
    ],
    options: {
      //colors: ['#FFA500', '#FFFF00', '#E91E63', '#FF9800'],
      colors: ['#1a2332'],
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      chart: {
        id: id,

        height: 100,
        type: 'line',
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          show: toolbar,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['transparent'],
        width: 5,
      },
      title: {
        text: '',
        align: 'left',
        style: {
          color: '#999',
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          cssClass: 'apexcharts-xaxis-title',
        },
      },
      legend: {
        show: true,
        // tooltipHoverFormatter: function (val, opts) {
        //   return (
        //     val +
        //     ' - ' +
        //     opts.w.globals.series[opts.seriesIndex][
        //       opts.dataPointIndex
        //     ] +
        //     ''
        //   );
        // },
        labels: {
          colors: '#aaa',
          useSeriesColors: false,
        },
      },
      yaxis: {
        tickAmount: yTicks,
        labels: {
          style: {
            colors: '#64748b',
          },
          formatter: function (value) {
            return value == null ? '0' : value.toFixed(0) + ' ' + units;
          },
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        min: sevenDaysAgoDate,
        max: currentTimeStamp,
        zoomX: {},
        type: 'datetime',
        tickAmount: 3,
        labels: {
          datetimeUTC: true,
          style: {
            colors: '#64748b',
          },
        },
        axisBorder: {
          show: true,
          color: '#333',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#999',
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        categories: timestamps,
      },
      tooltip: {
        theme: 'dark',
        x: {
          format: 'dd MMM yyyy, h:mm',
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return moment(value).zone('+0100').format('ddd D MMM yyyy, H:mm');
          },
        },
        y: [
          {
            title: {
              formatter: function (val) {
                return val + '';
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + '';
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        show: true,
        borderColor: 'rgba(255,255,255,0.1)',
        strokeDashArray: [4, 5],
      },
    },
  });

  const zoomFunction = (timeline) => {
    const now = new Date(moment().add(45, 'm')).getTime();
    const today = new Date(moment().subtract(1, 'd')).getTime();
    const this_week = new Date(moment().subtract(8, 'd')).getTime();
    const two_week = new Date(moment().subtract(14, 'd')).getTime();
    const one_month = new Date(moment().subtract(1, 'M')).getTime();
    const three_month = new Date(moment().subtract(3, 'M')).getTime();
    const one_year = new Date(moment().subtract(1, 'y')).getTime();
    const ytd = new Date(moment().subtract(8, 'd')).getTime();
    const all = new Date(timestamps[0]).getTime();

    switch (timeline) {
      case 'today':
        ApexCharts.exec(id, 'zoomX', today, now);
        break;
      case 'this_week':
        ApexCharts.exec(id, 'zoomX', this_week, now);
        break;
      case 'two_week':
        ApexCharts.exec(id, 'zoomX', two_week, now);
        break;
      case 'one_month':
        ApexCharts.exec(id, 'zoomX', one_month, now);
        break;
      case 'three_month':
        ApexCharts.exec(id, 'zoomX', three_month, now);
        break;
      case 'one_year':
        ApexCharts.exec(id, 'zoomX', one_year, now);
        break;
      case 'ytd':
        ApexCharts.exec(id, 'zoomX', ytd, now);
        break;
      case 'all':
        ApexCharts.exec(id, 'zoomX', all, today);

        break;
      default:
    }
    // Chart.exec(
    //   id,
    //   'zoomX',
    //   new Date('2022-09-01').getTime(),
    //   new Date().getTime()
    // );
    // console.log(timestamps[0]);
  };

  return (
    <>
      {' '}
      {/* <WhiteText weight="bold" style={{ marginLeft: '20px', color: color }}>
        {' '}
        {title}
      </WhiteText> */}
      <Chart options={chartData.options} series={chartData.series} type={type} height={height} />
    </>
  );
}
