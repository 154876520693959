import React from 'react';
import styled from 'styled-components';

const StatusBadge = ({ status, ...props }) => {
  // const statusColor = {
  //   warning: '#ffd314', // amber
  //   normal: '#00ff33',
  //   unknown: '#6e798b', // grey
  //   good: '#00ff33', // green
  //   bad: '#ef4443', // red
  //   loading: '#fff', // grey
  //   external: '#1b72ff', // blue
  // };

  const statusColours = {
    good: {
      background: '#ecf6e5',
      color: '#70b840',
    },
    bad: {
      background: '#f6e5e5',
      color: '#dd6666',
    },
    warning: {
      background: '#ffd314',
      color: '#000',
    },
    normal: {
      background: '#00ff33',
      color: '#fff',
    },
  };

  const StyledBadge = styled.div`
    display: inline-block;
    padding: 6px 8px;
    border-radius: 4px;
    background: ${(props) => statusColours[props.status].background};
    color: ${(props) => statusColours[props.status].color};
    font-size: 12px;
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
    line-height: 1 !important;
  `;

  return <StyledBadge status={status} {...props}></StyledBadge>;
};

export default StatusBadge;
