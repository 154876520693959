import React, { useEffect } from 'react';
import { useCreateZoneMutation } from '../../services/building';
import { Button, Modal, Textfield, Text, Box, Select, Form } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const ZoneCreate = ({ id, building, floorId = null }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { handleSubmit, register, reset: resetForm } = useForm();

  const [createZone, { isSuccess, reset }] = useCreateZoneMutation();

  const onSubmit = (data) => {
    createZone({ ...data, buildingId: parseInt(id), floorId: floorId || parseInt(data.floorId) });
    setIsOpen(false);
  };

  useEffect(() => {
    reset();
    resetForm({ name: '' });
  }, [isOpen, floorId]);

  return (
    <>
      <Button size="small" color="primary" onClick={() => setIsOpen(true)}>
        Add Room
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        <Modal.Header>
          <Text size="large" weight="bold">
            Add New Room / Zone
          </Text>
        </Modal.Header>
        <Modal.Content>
          {isSuccess && (
            <>
              <p style={{ color: 'green' }}>Room created successfully</p>
              <br />
            </>
          )}

          <Textfield label="Name" {...register('name')} placeholder="Room / Zone Name" />
          {/* <Select label="Choose Floor" {...register('floorId')}>
            {building.floors.map((floor) => (
              <Select.Option value={floor.id}>{floor.name}</Select.Option>
            ))}
          </Select> */}
          {!floorId && (
            <Select label="Choose Floor" {...register('floorId')}>
              <option value="" selected disabled hidden>
                Please select
              </option>

              {building.floors.map((floor) => (
                <Select.Option value={floor.id}>{floor.name}</Select.Option>
              ))}
            </Select>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Box gap={6}>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Create
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ZoneCreate;
