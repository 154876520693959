import React from 'react';
import { useCreateMeterMutation } from '../../services/building';
import { Button, Modal, Textfield, Text, Box, Textarea, Select } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const MeterCreate = ({ id }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { handleSubmit, register } = useForm();

  const [createMeter, { isSuccess }] = useCreateMeterMutation();

  const onSubmit = (data) => {
    createMeter({ ...data, buildingId: parseInt(id) });
  };

  return (
    <>
      <Button color="primary" onClick={() => setIsOpen(true)}>
        Add Meter
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        <Modal.Header>
          <Text size="large" weight="bold">
            Add New Meter
          </Text>
        </Modal.Header>
        <Modal.Content>
          {isSuccess && (
            <>
              <p style={{ color: 'green' }}>Meter created successfully</p>
              <br />
            </>
          )}
          <Textfield required label="Name" {...register('name', { required: true })} />
          <Textarea rows={2} label="Description" {...register('description')} />

          <Select required label="Meter Type" {...register('meterType', { required: true })}>
            <option value="" selected disabled>
              Please select
            </option>
            <option value={'BMS'}>BMS</option>
            <option value={'IOT'}>IOT</option>
            {/* {building.zones.map((zone) => (
              <option value={zone.id}>{zone.name}</option>
            ))} */}
          </Select>
          <Textfield label="Data Type" {...register('dataType')} />

          {/* <Select label="Type" {...register('type')}>
            <option value="" selected disabled>
              Please select
            </option>
            <option value={'electricity'}>Electricity</option>
            <option value={'biomass'}>Biomass</option>

          </Select> */}
          <Textfield required label="Scan Channel ID" {...register('channel', { required: true })} />
          <Textfield
            required
            label="Scan Building for Channel ID"
            {...register('scanBuildingName', { required: true })}
            //defaultValue={data.scanBuildingName}
          />
          <Textfield required label="Data Unit" {...register('unit', { required: true })} />
        </Modal.Content>
        <Modal.Footer>
          <Box gap={6}>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Create
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MeterCreate;
