import React, { useEffect, useState } from 'react';
import { Link, NavLink, Route, Routes, useParams } from 'react-router-dom';
import {
  useGetBuildingByIdQuery,
  useGetFloorsByBuildingIdQuery,
  useGetSensorByBuildingIdQuery,
  useGetZonesByBuildingIdQuery,
} from '../../services/building';
import { Box, Text, Textfield } from '@ies-ds/react-ui';
import ScrollBox from '../ui/ScrollBox';
import styled from 'styled-components';
import { ArrowLeft, BoundingBox, Broadcast, MagnifyingGlass, StackSimple } from '@phosphor-icons/react';
import Tab from '../ui/Tab';
import { level2 } from '../ui/colors';
import FloorDetail from '../FloorDetail';
import SensorDetail from '../SensorDetail';
import ZoneDetail from '../ZoneDetail';
import BuildingOverview from './BuildingOverview';

const StyledLink = styled(NavLink)`
  padding: 6px 8px;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
  }
  &.active {
    background: #171e2c;
  }
`;

const StyledTextfield = styled(Textfield)`
  padding: 6px 12px;
  font-size: 14px;
  background: rgb(33 45 64);
`;

const BuildingDetail = () => {
  const { buildingId } = useParams();

  const { isLoading, data } = useGetBuildingByIdQuery(buildingId);
  const { isLoading: loadingFloors, data: floors } = useGetFloorsByBuildingIdQuery(buildingId);
  const { isLoading: loadingZones, data: zones } = useGetZonesByBuildingIdQuery(buildingId);
  const { isLoading: loadingSensors, data: sensors } = useGetSensorByBuildingIdQuery(buildingId);

  const [inputValueFloor, setInputFloor] = useState('');
  const [inputValueZone, setInputZone] = useState('');
  const [inputValueSensor, setInputSensor] = useState('');

  const [filteredDataFloor, setFilteredDataFloor] = useState([]);
  const [filteredDataZone, setFilteredDataZone] = useState([]);
  const [filteredDataSensor, setFilteredDataSensor] = useState([]);

  useEffect(() => {
    const filteredFloor = floors?.filter((data) => data.name.toLowerCase().includes(inputValueFloor.toLowerCase()));
    const filteredZone = zones?.filter((data) => data.name.toLowerCase().includes(inputValueZone.toLowerCase()));
    const filteredSensor = sensors?.filter((data) => data.name.toLowerCase().includes(inputValueSensor.toLowerCase()));

    setFilteredDataFloor(filteredFloor);
    setFilteredDataZone(filteredZone);
    setFilteredDataSensor(filteredSensor);
  }, [inputValueFloor, floors, inputValueZone, zones, inputValueSensor, sensors]);

  if (isLoading || loadingFloors || loadingZones || loadingSensors) return <div>Loading...</div>;

  return (
    <>
      <Box direction="row" alignItems="stretch" flex={1} style={{ overflow: 'hidden' }}>
        <Box style={{ width: '320px' }}>
          <Box
            direction="column"
            flex={1}
            style={{
              background: level2,
              width: '280px',
              padding: ' 0px 24px',
            }}
          >
            <Link to={'../'} style={{ color: '#3b82f6', fontSize: '14px', margin: '30px 0 16px 0' }}>
              <Box alignItems="center" gap={4}>
                <ArrowLeft color="#3b82f6" size="16" /> Back to Buildings
              </Box>
            </Link>
            <Box direction="column" style={{ margin: ' 0 8px' }}>
              <Text size="xlarge" color="light">
                {data.name}
              </Text>
              <Text size="small" color="lighter" style={{ lineHeight: '1.2' }}>
                {data.location}
              </Text>
            </Box>
            <Box gap={8} style={{ margin: '16px 0 16px 0' }}>
              <Tab to={'floor'}>Floors ({floors?.length})</Tab>
              <Tab to={'zone'}>Zones ({zones?.length})</Tab>
              <Tab to={'sensor'}>Sensors ({sensors?.length})</Tab>
            </Box>

            {loadingFloors || loadingSensors || loadingZones ? (
              'Loading...'
            ) : (
              <>
                <Routes>
                  <Route
                    path="/floor/*"
                    element={
                      <>
                        <StyledTextfield
                          placeholder="Filter..."
                          onChange={(e) => setInputFloor(e.target.value)}
                          value={inputValueFloor}
                        />
                        <Text size="xsmall" style={{ marginTop: '-16px', marginBottom: '12px' }}>
                          Count:{filteredDataFloor?.length}
                        </Text>
                        <ScrollBox>
                          {filteredDataFloor < 1 ? (
                            <Text color="light"> No Floor</Text>
                          ) : (
                            filteredDataFloor?.map((floor) => {
                              return (
                                <StyledLink to={`${floor.id}`}>
                                  <Box direction="column" alignItems="flex-start" gap={8}>
                                    <Box direction="row" gap={8}>
                                      <StackSimple size={20} color={'#3b82f6'} />
                                      <Box direction="column">
                                        <Text color="light" style={{ lineHeight: '1.2' }}>
                                          {' '}
                                          {floor.name}
                                        </Text>
                                        <Text color="lightest" size="xsmall">
                                          {floor.zones.length ? `${floor.zones.length} Zones | ` : null}{' '}
                                          {floor.sensors.length} Sensors
                                        </Text>
                                      </Box>
                                    </Box>
                                  </Box>
                                </StyledLink>
                              );
                            })
                          )}
                        </ScrollBox>
                      </>
                    }
                  />
                  {/* <Route
                          path="/floor/*"
                          element={
                            <>
                              {floors.length === 0 ? (
                                <Text color="light"> No Floor</Text>
                              ) : (
                                floors.map((floor) => {
                                  return (
                                    <StyledLink to={`floor/${floor.id}`}>
                                      <Box
                                        direction="column"
                                        alignItems="flex-start"
                                        gap={8}
                                        style={{ marginBottom: '8px' }}
                                      >
                                        <Box direction="row" gap={8}>
                                          <StackSimple size={20} color={'#3b82f6'} />
                                          <Box direction="column">
                                            <Text color="light" style={{ lineHeight: '1.2' }}>
                                              {' '}
                                              {floor.name}
                                            </Text>
                                            <Text color="lightest" size="xsmall">
                                              {floor.zones.length ? `${floor.zones.length} Zones - ` : null} Sensors:{' '}
                                              {floor.sensors.length}
                                            </Text>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </StyledLink>
                                  );
                                })
                              )}
                            </>
                          }
                        /> */}
                  <Route
                    path="/zone/*"
                    element={
                      <>
                        <StyledTextfield
                          placeholder="Filter..."
                          onChange={(e) => setInputZone(e.target.value)}
                          value={inputValueZone}
                        />
                        <Text size="xsmall" style={{ marginTop: '-16px', marginBottom: '12px' }}>
                          Count:{filteredDataZone?.length}
                        </Text>
                        <ScrollBox>
                          {filteredDataZone < 1 ? (
                            <Text color="light"> No Zones</Text>
                          ) : (
                            filteredDataZone?.map((zone) => {
                              return (
                                <StyledLink to={`${zone.id}`}>
                                  <Box direction="column" alignItems="flex-start" gap={8}>
                                    <Box direction="row" gap={8}>
                                      <BoundingBox size={20} color={'#f6803b'} />
                                      <Box direction="column">
                                        <Text color="light" style={{ lineHeight: '1.2' }}>
                                          {' '}
                                          {zone.name}
                                        </Text>
                                        <Text color="lightest" size="xsmall">
                                          {zone.floors?.name ? `${zone.floors?.name}: ` : null} {zone.sensors.length}{' '}
                                          Sensors
                                        </Text>
                                      </Box>
                                    </Box>
                                  </Box>
                                </StyledLink>
                              );
                            })
                          )}{' '}
                        </ScrollBox>
                      </>
                    }
                  />
                  <Route
                    path="/sensor/*"
                    element={
                      <>
                        {' '}
                        <StyledTextfield
                          placeholder="Filter..."
                          onChange={(e) => setInputSensor(e.target.value)}
                          value={inputValueSensor}
                        />
                        <Text size="xsmall" style={{ marginTop: '-16px', marginBottom: '12px' }}>
                          Count:{filteredDataSensor?.length}
                        </Text>
                        <ScrollBox>
                          {filteredDataSensor < 1 ? (
                            <Text color="light">No Sensors</Text>
                          ) : (
                            filteredDataSensor?.map((sensor) => {
                              return (
                                <StyledLink to={`${sensor.id}`}>
                                  <Box direction="column" alignItems="flex-start" gap={8}>
                                    <Box direction="row" gap={8}>
                                      <Broadcast size={20} color={'#18b951'} />
                                      <Box direction="column">
                                        <Text color="light" style={{ lineHeight: '1.2' }}>
                                          {' '}
                                          {sensor.name}
                                        </Text>
                                        {sensor.channel ? (
                                          <Text color="lightest" size="xsmall">
                                            Channel ID: {sensor.channel}
                                          </Text>
                                        ) : (
                                          <Text color="lightest" size="xsmall">
                                            Channel Not Set
                                          </Text>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </StyledLink>
                              );
                            })
                          )}{' '}
                        </ScrollBox>
                      </>
                    }
                  />
                </Routes>
              </>
            )}
            <br />
          </Box>
        </Box>
        <Routes>
          <Route
            path="/*"
            element={
              <ScrollBox style={{ padding: '32px', width: '100%', overflow: 'auto' }}>
                <BuildingOverview />
              </ScrollBox>
            }
          />
          <Route
            path="/floor/:floorId/*"
            element={
              <ScrollBox style={{ padding: '32px', width: '100%', overflow: 'auto' }}>
                <FloorDetail building={data} />
              </ScrollBox>
            }
          />
          <Route
            path="/zone/:zoneId/*"
            element={
              <ScrollBox style={{ padding: '32px', width: '100%', overflow: 'auto' }}>
                <ZoneDetail building={data} />
              </ScrollBox>
            }
          />

          <Route
            path="/sensor/:sensorId"
            element={
              <ScrollBox style={{ padding: '32px', width: '100%', overflow: 'auto' }}>
                <SensorDetail building={data} />
              </ScrollBox>
            }
          />
        </Routes>
      </Box>
    </>
  );
};

export default BuildingDetail;

const EmptyWindow = () => {
  return (
    <Box
      flex={1}
      // style={{ border: '1px solid yellow' }}
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={8}
    >
      <MagnifyingGlass size={36} color="#64748b" />
      <Text color="lightest">Browse the Data </Text>
    </Box>
  );
};
