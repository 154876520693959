import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useGetFloorByIdQuery } from '../services/building';
import { BoundingBox, StackSimple } from '@phosphor-icons/react';
import { Box, Grid, Loader, Table, Text } from '@ies-ds/react-ui';
import StatusKPI from './ui/StatusKpi';
import SensorDetail from './SensorDetail';
import BuildingFloorplan from './Buildings/BuildingFloorplan';
import StatusCell from './ui/StatusCell';
import FloorDetailTable from './FloorDetailTable';

const FloorDetail = ({ building }) => {
  const { floorId } = useParams();

  const { isLoading, isFetching, data: floor, error } = useGetFloorByIdQuery(floorId);

  if (isLoading || isFetching)
    return (
      <Box justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <Loader type="dots" />
      </Box>
    );

  return (
    <div style={{ width: '100%' }}>
      <Routes>
        <Route
          path=""
          element={
            <>
              <Box gap={12} alignItems="center">
                <StackSimple size={28} color={'#3b82f6'} />
                <Text size="2xl" color="dark">
                  {floor.name}
                </Text>
              </Box>
              <br />
              <BuildingFloorplan floor={floor} building={building} />
              <Box gap={16} style={{ marginTop: '-20px', marginBottom: '12px', marginLeft: '8px' }}>
                <Box alignItems="baseline" gap={4}>
                  <div style={{ width: '10px', height: '10px', background: '#00ff33', borderRadius: '3px' }}></div>
                  <Text size="xsmall" color="lighter">
                    Within Thresholds
                  </Text>
                </Box>
                <Box alignItems="baseline" gap={4}>
                  <div style={{ width: '10px', height: '10px', background: '#ef4443', borderRadius: '3px' }}></div>
                  <Text size="xsmall" color="lighter">
                    Out of Thresholds
                  </Text>
                </Box>
                <Box alignItems="baseline" gap={4}>
                  <div style={{ width: '10px', height: '10px', background: '#ffd314', borderRadius: '3px' }}></div>
                  <Text size="xsmall" color="lighter">
                    No Data Available
                  </Text>
                </Box>
                <Box alignItems="baseline" gap={4}>
                  <div style={{ width: '10px', height: '10px', background: '#1b72ff', borderRadius: '3px' }}></div>
                  <Text size="xsmall" color="lighter">
                    External Sensor
                  </Text>
                </Box>
              </Box>
              <Text style={{ margin: '18px 8px 12px' }}></Text>
              <FloorDetailTable floor={floor} building={building} />
              {/* <Box direction="column" gap={32}>
                {floor.zones?.map((zone) => (
                  <Box direction="column" gap={12} key={zone.id}>
                    <Box gap={8} alignItems="center">
                      {' '}
                      <BoundingBox size={20} color={'#f6803b'} />{' '}
                      <Text size="large" weight="medium" color="lighter">
                        {zone.name}
                      </Text>
                    </Box>

                    <Grid fullwidth>
                      {floor.sensors?.map(
                        (sensor) =>
                          sensor.zoneId === zone.id && (
                            <Grid.Col sm={12} md={12} lg={6} xl={2}>
                              <StatusKPI
                                to={'sensor/' + sensor.id}
                                label={sensor?.type.name}
                                channel={sensor?.channel}
                                unit={sensor?.type.unit}
                                min={sensor?.type.min}
                                max={sensor?.type.max}
                                building={building.scanBuildingName}
                              />
                            </Grid.Col>
                          )
                      )}
                    </Grid>
                  </Box>
                ))}
              </Box> */}
            </>
          }
        />
        <Route path="sensor/:sensorId" element={<SensorDetail building={building} />} />
      </Routes>
    </div>
  );
};

export default FloorDetail;
