import React from 'react';
import styled from 'styled-components';
import * as DT from '@ies-ds/design-tokens';
import { Text } from '@ies-ds/react-ui';
import { NavLink } from 'react-router-dom';

const StyledTab = styled(NavLink)`
  padding: 4px 8px;
  height: 32px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  color: ${DT.COLOR_GRAY_400};
  text-decoration: none;
  margin-bottom: -2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  a {
    text-decoration: none;
  }
  &.active {
    border-bottom: 2px solid ${DT.COLOR_BLUE_500};
    color: ${DT.COLOR_GRAY_300};
  }
  &:hover {
    color: ${DT.COLOR_GRAY_300};
    // border-bottom: 2px solid ${DT.COLOR_BLUE_500};
    text-decoration: none;
  }
`;

const Tab = ({ children, ...props }) => {
  return (
    <StyledTab {...props}>
      <Text weight="medium" size="small" style={{ color: 'unset' }}>
        {children}
      </Text>
    </StyledTab>
  );
};

export default Tab;
