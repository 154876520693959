import { Box, Grid, Loader, Table, Text } from '@ies-ds/react-ui';
import React from 'react';
import Map from '../Map/Map';
import { useGetAllSensorTypeQuery, useGetBuildingByIdQuery } from '../../services/building';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const BuildingInfo = () => {
  const { buildingId } = useParams();
  const { isLoading, data } = useGetBuildingByIdQuery(buildingId);
  const { isLoading: isLoadingSensorType, data: sensorTypes } = useGetAllSensorTypeQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const StyledTableContainer = styled.div`
    table {
      border: 1px solid rgb(69, 73, 88);
    }
    th {
      background-color: #212d40;
      padding: 10px 10px;
    }
    tr:hover {
      background-color: #212d40;
    }
    td {
      padding: 6px 10px;
    }
  `;

  return (
    <Box flex={1} direction="row" style={{ padding: '32px', overflow: 'hidden' }}>
      <Grid spacing={40}>
        <Grid.Col sm={12} md={6}>
          {data.description && (
            <>
              {' '}
              <Text as="h2" color="dark" size="large">
                Overview
              </Text>
              <Text as="p" color="lighter">
                {data.description}
              </Text>{' '}
            </>
          )}

          <Text as="h2" color="dark" size="large">
            Location
          </Text>
          <Text as="p" color="lighter">
            {data.location}
          </Text>

          <Text as="h2" color="dark" size="large">
            Technical Information
          </Text>
          <Text as="p" color="lighter">
            Floors: {data.floors.length}
            <br />
            Rooms: {data.zones.length}
          </Text>
          <br />
          <Text as="h2" color="dark" size="large">
            Threshold Table
          </Text>
          <StyledTableContainer>
            {isLoadingSensorType ? (
              <Loader type="dots" />
            ) : (
              <Table>
                <tr>
                  <th>Sensor</th>
                  <th>Type</th>
                  <th>Min value</th>
                  <th>Max value</th>
                </tr>
                {sensorTypes.map((sensorType) => (
                  <>
                    {!sensorType.disableMin && !sensorType.disableMax ? (
                      <tr>
                        {' '}
                        <td>{sensorType.description}</td>
                        <td>{sensorType.name}</td>
                        <td>
                          {sensorType.min} {sensorType.unit}
                        </td>
                        <td>
                          {sensorType.max} {sensorType.unit}
                        </td>
                      </tr>
                    ) : null}
                  </>
                ))}
              </Table>
            )}
          </StyledTableContainer>
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Box
            alignItems="center"
            justifyContent="center"
            style={{ width: '100%', height: '500px', position: 'relative', background: '#212d40' }}
          >
            {data.longitude == null || data.latitude == null ? (
              <Text color="lightest">No Map data</Text>
            ) : (
              <Map longitude={data.longitude} latitude={data.latitude} />
            )}
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default BuildingInfo;
