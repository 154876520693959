import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetZonesByBuildingIdQuery } from '../../services/building';
import { Box, Container, Loader, Table, Text } from '@ies-ds/react-ui';
import { useGetBuildingByIdQuery } from '../../services/building';
import ZoneCreate from './ZoneCreate';
// import ZoneDelete from './ZoneDelete';
import { CaretLeft } from '@phosphor-icons/react';
import ZoneDelete from './ZoneDelete';
import ZoneEdit from './ZoneEdit';
import ZoneSensorCreate from './ZoneSensorCreate';
import ZoneSensorEdit from './ZoneSensorEdit';
import ZoneSetLocation from './ZoneSetLocation';
// import ZoneEdit from './ZoneEdit';

const ZoneListByBuilding = () => {
  const { id } = useParams();

  const { isLoading, data: zones, error } = useGetZonesByBuildingIdQuery(id);
  const { isLoading: isLoadingBuilding, data: building } = useGetBuildingByIdQuery(id);

  if (isLoadingBuilding) return 'Loading';

  return (
    <div>
      <Box justifyContent="space-between" alignItems="center">
        <Text> Zone List: </Text>
        <br />
        <ZoneCreate id={id} building={building} />
      </Box>
      <br />
      <Container shadow="low">
        <Table>
          <tr>
            <th style={{ width: '20px' }}>ID</th>
            <th>Name</th>
            {/* <th>buildingId</th> */}
            <th>floorId</th>
            <th></th>
          </tr>
          {isLoading ? (
            'Loader'
          ) : zones.length === 0 ? (
            <tr>
              <td colSpan={3}>
                <Text>No zones</Text>
              </td>
            </tr>
          ) : (
            zones.map((zone) => (
              <>
                {zone.floorId && (
                  <tr style={{ margin: '8px 0' }}>
                    <td>{zone.id}</td>
                    <td>{zone.name}</td>
                    {/* <td>{zone.buildingId}</td> */}
                    <td>
                      {zone.floors && (
                        <>
                          {zone.floors?.name} [{zone.floorId}]
                        </>
                      )}
                    </td>
                    <td>
                      {zone?.sensors.map((sensor) => (
                        <Text size="xsmall" style={{ marginBottom: '5px' }}>
                          {sensor.type.name} - {sensor.name} - [{sensor.channel}] -{' '}
                          <ZoneSensorEdit
                            buildingId={building.id}
                            sensor={sensor}
                            floorId={zone.floorId}
                            zoneId={zone.id}
                          />
                        </Text>
                      ))}
                      <ZoneSensorCreate buildingId={building.id} floorId={zone.floorId} zoneId={zone.id} />
                    </td>
                    <td>
                      <Box justifyContent="flex-end" gap={4}>
                        <ZoneSetLocation floorId={zone.floorId} zone={zone} building={building} />
                        <ZoneEdit zone={zone} building={building} />
                        <ZoneDelete id={zone.id} />
                      </Box>
                    </td>
                  </tr>
                )}
              </>
            ))
          )}
        </Table>
      </Container>
    </div>
  );
};

export default ZoneListByBuilding;
