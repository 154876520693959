import React, { useEffect } from 'react';
import { useCreateBmsSensorMutation } from '../../services/building';
import { Button, Modal, Textfield, Text, Box, Select, Textarea } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const StyledDefatultDisabledOption = styled.option`
  [disabled] {
    color: #999;
  }
`;

const BmsSensorCreate = ({ id, building }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { handleSubmit, register, reset } = useForm();

  const [createBmsSensor, { isSuccess, reset: resetCreate }] = useCreateBmsSensorMutation();

  const onSubmit = (data) => {
    console.log(data);
    createBmsSensor({
      ...data,
      name: data.name,
      buildingId: parseInt(building.id),
      description: data.description,
      bmsSensorTypeId: parseInt(data.bmsSensorTypeId),
      channel: data.channel,
      scanBuildingName: data.scanBuildingName,
    });
    reset({ name: '', description: '', bmsSensorTypeId: '', channel: '' });
  };

  useEffect(() => {
    reset({ name: '', floorId: '', zoneId: '', sensorTypeId: '', channel: '' });
    resetCreate();
  }, [isOpen, reset]);

  return (
    <>
      <Button color="primary" onClick={() => setIsOpen(true)}>
        Add Sensor
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        <Modal.Header>
          <Text size="large" weight="bold">
            Add New Sensor
          </Text>
        </Modal.Header>
        <Modal.Content>
          {isSuccess && (
            <>
              <p style={{ color: 'green' }}> Sensor created successfully</p>
              <br />
            </>
          )}

          <Textfield
            label="Name"
            {...register('name', { required: true })}
            required
            placeholder="Choose a name for the sensor"
          />
          <Textarea label="Description" rows={5} {...register('description')}></Textarea>

          <Select label=" Sensor Type" required {...register('bmsSensorTypeId', { required: true })}>
            <option value="" selected disabled hidden>
              Please select
            </option>
            <option value={1}>BMS - Biomass</option>
            <option value={2}>BMS - CT Circuit</option>
            <option value={3}>BMS - DHW Calorifier</option>
            <option value={4}>BMS - Water</option>
            <option value={5}>IOT - Calorifier</option>
          </Select>
          <Textfield
            label="Scan Channel ID"
            required
            {...register('channel', { required: true })}
            placeholder="i.e. SC123456"
          />
          <Textfield
            label="Scan Building for Channel	"
            required
            {...register('scanBuildingName', { required: true })}
            placeholder="i.e. IotMeters"
          />
        </Modal.Content>
        <Modal.Footer>
          <Box gap={6}>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Create
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BmsSensorCreate;
