import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useGetZoneByIdQuery } from '../services/building';
import { BoundingBox, StackSimple } from '@phosphor-icons/react';
import { Box, Grid, Text } from '@ies-ds/react-ui';
import StatusKPI from './ui/StatusKpi';
import SensorDetail from './SensorDetail';

const ZoneDetail = ({ building }) => {
  const { zoneId } = useParams();

  const { isLoading, data: zone, error } = useGetZoneByIdQuery(zoneId);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div style={{ width: '100%' }}>
      <Routes>
        <Route
          path=""
          element={
            <>
              <Box gap={12} alignItems="center">
                <BoundingBox size={28} color={'#f6803b'} />
                <Text size="2xl" color="dark">
                  {zone.name}
                </Text>
              </Box>
              <br />
              {/* <Text color="dark">Zones</Text>
      <br /> */}
              <Box direction="column" gap={32}>
                <Grid fullwidth>
                  {zone.sensors?.map((sensor) => (
                    <Grid.Col sm={12} md={12} lg={6} xl={2}>
                      <StatusKPI
                        to={'sensor/' + sensor.id}
                        label={sensor?.type.name}
                        channel={sensor?.channel}
                        unit={sensor?.type.unit}
                        min={sensor?.type.min}
                        max={sensor?.type.max}
                        disableMax={sensor?.type.disableMax}
                        disableMin={sensor?.type.disableMin}
                        building={building.scanBuildingName}
                      />
                    </Grid.Col>
                  ))}
                </Grid>
                {zone.zones?.map((zone) => (
                  <Box direction="column" gap={12} key={zone.id}>
                    <Box gap={8} alignItems="center">
                      {' '}
                      <BoundingBox size={20} color={'#f6803b'} />{' '}
                      <Text size="large" weight="medium" color="lighter">
                        {zone.name}
                      </Text>
                    </Box>
                    <Grid fullwidth>
                      {zone.sensors?.map(
                        (sensor) =>
                          sensor.zoneId === zone.id && (
                            <Grid.Col sm={12} md={6} lg={12} xl={4}>
                              <StatusKPI
                                to={'sensor/' + sensor.id}
                                label={sensor?.type.name}
                                channel={sensor?.channel}
                                unit={sensor?.type.unit}
                                min={0}
                                max={1000}
                                disableMax={sensor?.type.disableMax}
                                disableMin={sensor?.type.disableMin}
                                building={building.scanBuildingName}
                              />
                            </Grid.Col>
                          )
                      )}
                    </Grid>
                    {/* {zone.sensors?.map((sensor) => (
            <Box direction="column" gap={8} key={sensor.id}>
              <Text size="small" color="lighter">
                -- {sensor.name}|{sensor.id}| {sensor.zoneId}
              </Text>
            </Box>
          ))} */}
                  </Box>
                ))}
              </Box>
            </>
          }
        />
        <Route path="sensor/:sensorId" element={<SensorDetail building={building} />} />
      </Routes>
    </div>
  );
};

export default ZoneDetail;
