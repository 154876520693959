import React, { useEffect } from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import {
  useGetAllFloorsQuery,
  useGetBuildingByIdQuery,
  useGetFloorByIdQuery,
  useGetFloorsByBuildingIdQuery,
  useGetSensorByBuildingIdQuery,
  useGetZonesByBuildingIdQuery,
} from '../services/building';
import { Box, Text } from '@ies-ds/react-ui';
import { BoundingBox, Broadcast, StackSimple } from '@phosphor-icons/react';
import styled from 'styled-components';
import { level1 } from './ui/colors';
import SensorDetail from './SensorDetail';
import FloorDetail from './FloorDetail';

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const BuildingDetail = () => {
  const { buildingId } = useParams();
  const { isLoading, data: building, error } = useGetBuildingByIdQuery(buildingId);

  const { isLoading: loadingFloors, data: floors } = useGetFloorsByBuildingIdQuery(buildingId);

  const { isLoading: loadingZones, data: zones } = useGetZonesByBuildingIdQuery(buildingId);

  const { isLoading: loadingSensors, data: sensors } = useGetSensorByBuildingIdQuery(buildingId);

  useEffect(() => {
    console.log(buildingId);
  }, [buildingId]);

  if (isLoading || loadingFloors || loadingZones) return <div>Loading...</div>;

  return (
    <div style={{ padding: '32px', width: '100%' }}>
      <Text color="dark" size="2xl">
        {building.name}
      </Text>
      <Text color="lighter" size="small">
        {building.location}
      </Text>
      <Box direction="row" gap={32} style={{ height: '80%' }}>
        <Box direction="column" style={{ height: '100%', width: '260px' }}>
          {loadingFloors || loadingSensors || loadingZones ? (
            'Loading...'
          ) : (
            <>
              <br />
              <Text color="dark" weight="bold" style={{ marginBottom: '8px' }}>
                Floors:
              </Text>{' '}
              {floors.length === 0 ? (
                <Text color="light"> No Floor</Text>
              ) : (
                floors.map((floor) => {
                  return (
                    <StyledLink to={`floor/${floor.id}`}>
                      <Box direction="column" alignItems="flex-start" gap={8} style={{ marginBottom: '8px' }}>
                        <Box direction="row" gap={8}>
                          <StackSimple size={20} color={'#3b82f6'} />
                          <Box direction="column">
                            <Text color="light" style={{ lineHeight: '1.2' }}>
                              {' '}
                              {floor.name}
                            </Text>
                            <Text color="lightest" size="xsmall">
                              {floor.zones.length ? `${floor.zones.length} Zones - ` : null} Sensors:{' '}
                              {floor.sensors.length}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </StyledLink>
                  );
                })
              )}
              <br />
              <Text color="dark" weight="bold" style={{ marginBottom: '8px' }}>
                Zones:
              </Text>{' '}
              {zones.length === 0 ? (
                <Text color="light"> No Zones</Text>
              ) : (
                zones.map((zone) => {
                  return (
                    <StyledLink to={`zone/${zone.id}`}>
                      <Box direction="column" alignItems="flex-start" gap={8} style={{ marginBottom: '8px' }}>
                        <Box direction="row" gap={8}>
                          <BoundingBox size={20} color={'#f6803b'} />
                          <Box direction="column">
                            <Text color="light" style={{ lineHeight: '1.2' }}>
                              {' '}
                              {zone.name}
                            </Text>
                            <Text color="lightest" size="xsmall">
                              {zone.floors?.name ? `${zone.floors?.name} - ` : null} Sensors: {zone.sensors.length}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </StyledLink>
                  );
                })
              )}
              <br />
              <Text color="dark" weight="bold" style={{ marginBottom: '8px' }}>
                Sensors:
              </Text>
              {sensors.length === 0 ? (
                <Text color="light">No Sensors</Text>
              ) : (
                sensors.map((sensor) => {
                  return (
                    <StyledLink to={`sensor/${sensor.id}`}>
                      <Box direction="column" alignItems="flex-start" gap={8} style={{ marginBottom: '8px' }}>
                        <Box direction="row" gap={8}>
                          <Broadcast size={20} color={'#18b951'} />
                          <Box direction="column">
                            <Text color="light" style={{ lineHeight: '1.2' }}>
                              {' '}
                              {sensor.name}
                            </Text>
                            {sensor.channel ? (
                              <Text color="lightest" size="xsmall">
                                Channel ID: {sensor.channel}
                              </Text>
                            ) : (
                              <Text color="lightest" size="xsmall">
                                Channel Not Set
                              </Text>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </StyledLink>
                  );
                })
              )}
            </>
          )}
        </Box>
        <Box
          flex={1}
          style={{
            height: '100%',
            marginBottom: '4px',
            borderLeft: '1px dotted #3f4d68',

            padding: '0 32px',
          }}
        >
          <Routes>
            <Route path="floor/:floorId" element={<FloorDetail />} />

            <Route path="zone/:zoneId" element={<Text color="light">ZoneDetail</Text>} />

            <Route path="sensor/:sensorId" element={<SensorDetail building={building} />} />
          </Routes>
        </Box>
      </Box>
    </div>
  );
};

export default BuildingDetail;
