import { Box, Container, Table, Text } from '@ies-ds/react-ui';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetBuildingByIdQuery, useGetSensorByBuildingIdQuery } from '../../services/building';
import SensorCreate from './SensorCreate';
import SensorDelete from './SensorDelete';
import SensorEdit from './SensorEdit';

const SensorListByBuilding = () => {
  const { id } = useParams();

  const { isLoading, data: sensors } = useGetSensorByBuildingIdQuery(id);
  const { isLoading: isLoadingBuilding, data: building } = useGetBuildingByIdQuery(id);

  if (isLoading || isLoadingBuilding) return 'Loading';

  return (
    <div>
      <Box justifyContent="space-between" alignItems="center">
        <Text> Sensor List: </Text>
        <br />
        <SensorCreate building={building} id={id} />
      </Box>
      <br />
      <Container shadow="low">
        <Table>
          <tr>
            <th style={{ width: '20px' }}>ID</th>
            <th>Sensor Name</th>
            <th>Building</th>
            <th>Floor </th>
            <th>Zone </th>
            <th>Channel </th>
            <th>Sensor Type </th>
            <th></th>
          </tr>
          {sensors.length === 0 ? (
            <tr>
              <td colSpan={3}>
                <Text>No sensors</Text>
              </td>
            </tr>
          ) : (
            sensors.map((sensor) => (
              <tr key={sensor.id} style={{ margin: '8px 0' }}>
                <td>{sensor.id}</td>

                <td>{sensor.name}</td>
                <td>
                  {sensor.buildings?.name} [{sensor?.buildingId}]
                </td>
                <td>
                  {sensor.floors?.name} [{sensor?.floorId}]
                </td>
                <td>
                  {sensor.zones?.name} [{sensor?.zoneId}]
                </td>
                <td>{sensor?.channel}</td>
                <td>
                  {sensor.type?.name} [{sensor?.sensorTypeId}]
                </td>
                <td>
                  <Box justifyContent="flex-end" gap={8}>
                    <SensorEdit sensor={sensor} building={building} />
                    <SensorDelete sensor={sensor} />
                  </Box>
                </td>
              </tr>
            ))
          )}
        </Table>
      </Container>
    </div>
  );
};

export default SensorListByBuilding;
