import React from 'react';
import '../App.css';
import { Link, Route, Routes } from 'react-router-dom';
import { Box, Text, Logo } from '@ies-ds/react-ui';

import Sidenav from '../components/sidenav';
import Page from '../components/ui/Page';
import PageAuthorisation from './pages/PageAuthorisation';
import ContentWindow from '../components/ui/ContentWindow';

import PageSettings from './pages/PageSettings';
import PageBuildings from './pages/PageBuildings';
import PageProject from './pages/PageProject';
import UserdropDown from '../components/ui/UserDropdown';
import useCheckRole from '../hooks/useCheckRole';
import PageLoading from '../dashboard/ui/PageLoading';
import PageNotAuthorised from '../dashboard/ui/PageNotAuthorised';

const LayoutAdmin = () => {
  const { notAuthorised, isLoadingRole } = useCheckRole('ADMIN');

  if (isLoadingRole) {
    return <PageLoading />;
  }

  if (notAuthorised) {
    return <PageNotAuthorised />;
  }

  return (
    <>
      <Box style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, background: '#f5f7f9' }}>
        <Box
          style={{ minWidth: '60px', background: '#0b1131', padding: '12px 12px' }}
          direction="column"
          alignItems="center"
        >
          <Box justifyContent="flex-start" style={{ width: '100%' }}>
            <Link to={'/'}>
              <Logo style={{ borderRadius: '2px' }} />
            </Link>
          </Box>
          <br />
          <Sidenav />
        </Box>
        <Routes>
          <Route
            path="/"
            element={
              <Box direction="column" flex={1}>
                <Box direction="column" style={{ background: '#fff' }}>
                  <Box
                    direction="row"
                    justifyContent="space-between"
                    gap={12}
                    style={{ minHeight: '60px', padding: '20px 32px', borderBottom: '1px solid #f3f2f7' }}
                  >
                    <Text size={'2xl'} weight="bold">
                      Home
                    </Text>
                    <UserdropDown />
                  </Box>
                </Box>{' '}
              </Box>
            }
          />
          <Route path="/project/*" element={<Page title="Project Settings" element={<PageProject />} />} />
          <Route path="/buildings/*" element={<Page title="" element={<PageBuildings />} />} />
          <Route path="/users" element={<Page title="Users" />} />
          <Route
            path="/permissions/*"
            element={<Page title="Permissions" element={<PageAuthorisation role={'SUPERADMIN'} />} />}
          />
          <Route
            path="/connections"
            element={
              <Page
                title="API Connections"
                element={
                  <ContentWindow>
                    <Text>Connections</Text>
                  </ContentWindow>
                }
              />
            }
          />
          <Route path="/settings" element={<PageSettings />} />
        </Routes>
      </Box>
    </>
  );
};

export default LayoutAdmin;
