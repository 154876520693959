import { Box, Container, Table, Text } from '@ies-ds/react-ui';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetBuildingByIdQuery, useGetBmsSensorsByBuildingIdQuery } from '../../services/building';
import BmsSensorCreate from './BmsSensorCreate';
import BmsSensorDelete from './BmsSensorDelete';
import BmsSensorEdit from './BmsSensorEdit';

const BmsSensorListByBuilding = () => {
  const { id } = useParams();

  const { isLoading, data: bms } = useGetBmsSensorsByBuildingIdQuery(id);
  const { isLoading: isLoadingBuilding, data: building } = useGetBuildingByIdQuery(id);

  if (isLoading || isLoadingBuilding) return 'Loading';

  return (
    <div>
      <Box justifyContent="space-between" alignItems="center">
        <Text> Sensor List: </Text>
        <br />
        <BmsSensorCreate building={building} id={id} />
      </Box>
      <br />
      <Container shadow="low">
        <Table>
          <tr>
            <th style={{ width: '20px' }}>ID</th>
            <th>Sensor Name</th>

            <th>Channel </th>
            <th>Scan Building for Channel </th>
            <th>Data Type </th>
            <th>Sensor Type </th>
            <th></th>
          </tr>
          {bms.length === 0 ? (
            <tr>
              <td colSpan={3}>
                <Text>No bms</Text>
              </td>
            </tr>
          ) : (
            bms.map((sensor) => (
              <tr key={sensor.id} style={{ margin: '8px 0' }}>
                <td>{sensor.id}</td>

                <td>{sensor.name}</td>

                <td>{sensor?.channel}</td>
                <td>{sensor?.scanBuildingName}</td>
                <td>{sensor.type?.name}</td>
                <td>{sensor.type?.type}</td>
                <td>
                  <Box justifyContent="flex-end" gap={8}>
                    <BmsSensorEdit id={sensor.id} sensor={sensor} building={building} />
                    <BmsSensorDelete id={sensor.id} />
                  </Box>
                </td>
              </tr>
            ))
          )}
        </Table>
      </Container>
    </div>
  );
};

export default BmsSensorListByBuilding;
