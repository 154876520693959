import React, { useEffect, useState } from 'react';
import { NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useGetBuildingByIdQuery } from '../../../services/building';
import { Box, Text, Textfield } from '@ies-ds/react-ui';
import ScrollBox from '../../ui/ScrollBox';
import styled from 'styled-components';
import { Broadcast } from '@phosphor-icons/react';
import SensorDetail from '../../SensorDetail';
import { SurfaceColour3 } from '../../config/app';
import IotCalorifierDetail from './IotCalorifierDetail';

const StyledLink = styled(NavLink)`
  padding: 6px 8px;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
  }
  &.active {
    background: #171e2c;
  }
`;

const StyledTextfield = styled(Textfield)`
  padding: 6px 12px;
  font-size: 14px;
  background: #2e3649;
`;

const BuildingIotCalorifier = ({ bmsSensors, type }) => {
  const { buildingId } = useParams();
  const navigate = useNavigate();

  const { isLoading, data } = useGetBuildingByIdQuery(buildingId);
  const [inputValueSensor, setInputSensor] = useState('');
  const [filteredDataSensor, setFilteredDataSensor] = useState(bmsSensors);

  useEffect(() => {
    const filteredSensor = bmsSensors?.filter(
      (data) =>
        data.name.toLowerCase().includes(inputValueSensor.toLowerCase()) ||
        data.type.name.toLowerCase().includes(inputValueSensor.toLowerCase()) ||
        data.type.description?.toLowerCase().includes(inputValueSensor.toLowerCase())
    );
    setFilteredDataSensor(filteredSensor);
    // bmsSensors.length > 0 && navigate(`${bmsSensors[0]?.id}`);
  }, [inputValueSensor, bmsSensors]);

  if (isLoading) return <div>Loading...</div>;
  return (
    <>
      <Box flex={1} direction="row" style={{ padding: '16px 16px 0 16px ', overflow: 'hidden' }}>
        <Box direction="column" style={{ width: '250px' }}>
          <StyledTextfield
            placeholder="Filter Sensors..."
            onChange={(e) => setInputSensor(e.target.value)}
            value={inputValueSensor}
          />
          <Text color="lightest" size="xsmall" style={{ marginTop: '-12px', marginBottom: '12px' }}>
            Count: {filteredDataSensor?.length}
          </Text>
          <ScrollBox>
            {filteredDataSensor < 1 ? (
              <Text color="light">No Sensors</Text>
            ) : (
              filteredDataSensor?.map((sensor) => {
                return (
                  <StyledLink to={`/buildings/${buildingId}/iot-calorifier/${sensor.id}`}>
                    <Box direction="column" alignItems="flex-start" gap={8}>
                      <Box direction="row" gap={8}>
                        <Broadcast size={20} color={'#18b951'} />
                        <Box direction="column">
                          <Text color="light" style={{ lineHeight: '1.4' }}>
                            {' '}
                            {sensor.name}
                          </Text>

                          {sensor.channel ? (
                            <Text color="lightest" size="xsmall">
                              {sensor.type.type} {sensor.type.name} | {sensor.type.description}
                            </Text>
                          ) : (
                            <Text color="lightest" size="xsmall">
                              Channel Not Set
                            </Text>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </StyledLink>
                );
              })
            )}{' '}
          </ScrollBox>
        </Box>
        <ScrollBox
          style={{ padding: '16px  24px', marginLeft: '16px', background: SurfaceColour3, borderRadius: '6px' }}
        >
          <Routes>
            <Route path="/:sensorId/*" element={<IotCalorifierDetail building={data} />} />
            <Route path="/*" element={<CheckIfRouteExistAndNaviteToFirstId id={bmsSensors[0]?.id} />} />
          </Routes>
        </ScrollBox>
      </Box>
    </>
  );
};

export default BuildingIotCalorifier;

const CheckIfRouteExistAndNaviteToFirstId = ({ id }) => {
  const navigate = useNavigate();
  return <>{navigate(`${id}`)}</>;
};
