import { Box } from '@ies-ds/react-ui';
import React from 'react';
import styled from 'styled-components';

const StyledScrollBox = styled(Box)`
  display: flex;
  position: relative;
  flex: 1;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #1e293b;
    background: #24334a;
  }

  ::-webkit-scrollbar-thumb {
    background: #2b3e5c;
    background: #1b4d9d;

    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3d74cd;
  }

  overflow-y: scroll;

  padding: 2px;
`;

const ScrollBox = ({ children, direction = 'column', ...props }) => {
  return (
    <StyledScrollBox direction={direction} {...props}>
      {children}
    </StyledScrollBox>
  );
};

export default ScrollBox;
