import React from 'react';
import ContentWindow from '../../components/ui/ContentWindow';
import { Box, Container, Text } from '@ies-ds/react-ui';
import { useGetAllBuildingsQuery } from '../../services/building';
import BuildingList from '../building/BuildingList';
import BuildingCreate from '../building/BuildingCreate';
import { Link, Route, Routes } from 'react-router-dom';
import FloorListByBuilding from '../floor/FloorListByBuilding';
import SensorListByBuilding from '../sensor/SensorListByBuilding';
import ZoneListByBuilding from '../zone/ZoneListByBuilding';
import PageBuildingDetail from './PageBuildingDetail';
import Tab from '../../components/ui/Tab';
import { CaretLeft } from '@phosphor-icons/react';
import SensorTypeList from '../sensor_type/SensorTypeList';

const PageProject = () => {
  return (
    <>
      <Box
        style={{ minHeight: '48px', padding: '0 32px', borderBottom: '1px solid #f3f2f7', background: '#fff' }}
        alignItems="flex-end"
      >
        <Tab to={'sensor-types'}>Sensor Types</Tab>
      </Box>
      <ContentWindow>
        <Routes>
          <Route path="/:id/*" element={<PageBuildingDetail />} />

          <Route path="*" element={<Text>Not found</Text>} />
          <Route path="/sensor-types" element={<SensorTypeList />} />
        </Routes>
      </ContentWindow>
    </>
  );
};

export default PageProject;
