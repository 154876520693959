import React, { useEffect } from 'react';
import { useCreateSensorMutation, useGetAllSensorTypeQuery } from '../../services/building';
import { Button, Modal, Textfield, Text, Box, Select } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Plus } from '@phosphor-icons/react';

const StyledDefatultDisabledOption = styled.option`
  [disabled] {
    color: #999;
  }
`;

const ZoneSensorCreate = ({ id, buildingId, floorId, zoneId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { handleSubmit, register, reset } = useForm();

  const [createSensor, { isSuccess, reset: resetCreate }] = useCreateSensorMutation();
  const { data: sensorTypes, isLoading: isLoadingSensorTypes } = useGetAllSensorTypeQuery();

  const [successMessage, setSuccessMessage] = React.useState('');

  const onSubmit = (data) => {
    console.log(data);
    createSensor({
      ...data,
      name: data.name,
      buildingId: parseInt(buildingId),
      zoneId: zoneId,
      floorId: floorId,
      sensorTypeId: parseInt(data.sensorTypeId),
      channel: data.channel,
    });
    reset({ name: '', floorId: '', zoneId: '', sensorTypeId: '', channel: '' });
    setSuccessMessage(`Added sensor ${data.name}(${data.channel}) to room  successful`);
  };

  useEffect(() => {
    reset({ name: '', floorId: '', zoneId: '', sensorTypeId: '', channel: '' });
    resetCreate();
  }, [isOpen, reset]);

  return (
    <>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        <Plus size={10} /> Add Sensor
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        <Modal.Header>
          <Text size="large" weight="bold">
            Add New Sensor
          </Text>
        </Modal.Header>
        <Modal.Content>
          {isSuccess && (
            <>
              <p style={{ color: 'green' }}>{successMessage}</p>
              <br />
            </>
          )}

          <Textfield
            label="Name"
            {...register('name', { required: true })}
            required
            placeholder="Choose a name for the sensor"
          />

          <Select label="Sensor Type" {...register('sensorTypeId', { required: true })} required>
            <option value="" selected disabled hidden>
              Please select
            </option>
            {sensorTypes?.map((sensorType) => (
              <option value={sensorType.id}>
                {sensorType.description} - {sensorType.name}
              </option>
            ))}
          </Select>

          <Textfield
            label="Channel ID"
            {...register('channel', { required: true })}
            required
            placeholder="i.e. SC000030"
          />
        </Modal.Content>
        <Modal.Footer>
          <Box gap={6}>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Create
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ZoneSensorCreate;
