import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import config from '../config';

let last24hours = moment(Date.now()).subtract(1, 'd').toISOString();
let today = moment(Date.now()).subtract(0, 'd').toISOString();

//const buildings_api = process.env.REACT_APP_BUILDING_API_URL;
const buildings_api = config.apiRoot;

const scan_token = process.env.SCAN_TOKEN || 'iwzFW9WAVwlNYg4m4KrDokhKM3fJWaUAstGm2ot69yAsJ63IaiqyPer5EXspjGtp';

export const scanApi = createApi({
  reducerPath: 'scan',
  baseQuery: fetchBaseQuery({
    baseUrl: `${buildings_api}/building-api`,
    prepareHeaders: (headers, { getState }) => {
      if (scan_token) {
        headers.set('authorization', `Bearer ${scan_token}`);
      }
      return headers;
    },
  }),
  keepUnusedDataFor: 1800000, // 0.5 hour
  tagTypes: ['Scan'],
  endpoints: (builder) => ({
    getChannelDataSummaryRangeMetered: builder.query({
      query: ({ project, building, channelId, period = '60', startDate = last24hours, endDate = today }) =>
        `/scan/channel/${project}/${building}/${period}/${startDate}/${endDate}/${channelId}`,
      providesTags: ['Scan'],
    }),
  }),
});

export const { useGetChannelDataSummaryRangeMeteredQuery } = scanApi;
