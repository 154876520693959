import React, { useEffect, useRef, useState } from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from 'mapbox-gl';

const token = process.env.REACT_APP_MAPBOX_KEY;

const styles = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  //height: "calc(100vh - 80px)",
  position: 'absolute',
};

const Map = ({
  accessToken = token,
  //center = [-5.116679078648048, 56.81426660169379] /* HELIX BUILDING */,
  latitude,
  longitude,
  zoom = '17',
  mapstyle = 'mapbox://styles/gavinmcnamee/clj2ophuu00fy01qq8y509za0',
}) => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = accessToken;

    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapstyle, // stylesheet location
        // style: "mapbox://styles/mapbox/satellite-streets-v11", // stylesheet location
        // style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
        /* style: {
          version: 8,
          sources: {
            osm: {
              type: "raster",
              tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
              tileSize: 256,
              attribution:
                'Map tiles by <a target="_top" rel="noopener" href="https://tile.openstreetmap.org/">OpenStreetMap tile servers</a>, under the <a target="_top" rel="noopener" href="https://operations.osmfoundation.org/policies/tiles/">tile usage policy</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>',
            },
          },
          layers: [
            {
              id: "osm",
              type: "raster",
              source: "osm",
            },
          ],
        },*/
        //center: [-74.0064, 40.7081], // NY
        center: [longitude, latitude], // HELIX
        pitch: 40, // pitch in degrees
        //bearing: -60, // bearing in degrees
        zoom: zoom,
      });

      map.addControl(new mapboxgl.FullscreenControl());

      map.on('load', () => {
        // Insert the layer beneath any symbol layer.
        const layers = map.getStyle().layers;
        const labelLayerId = layers.find((layer) => layer.type === 'symbol' && layer.layout['text-field']).id;

        new mapboxgl.Marker({
          //draggable: true,
          color: '#3b82f6',
        })
          .setLngLat([Number(longitude), Number(latitude)])
          .addTo(map);

        // add pin to map using the coordinates passed in
        // map.addLayer(
        //   {
        //     id: 'CharleKennedy',
        //     type: 'circle',
        //     source: {
        //       type: 'geojson',
        //       data: {
        //         type: 'Point',
        //         coordinates: [Number(longitude), Number(latitude)],
        //       },
        //     },
        //     paint: {
        //       'circle-radius': 8,
        //       'circle-color': '#3b82f6',
        //       'circle-stroke-width': 2,
        //       'circle-stroke-color': '#fff',
        //     },
        //   },
        //   labelLayerId
        // );

        // map.addSource('maine', {
        //   type: 'geojson',
        //   data: {
        //     type: 'Feature',
        //     geometry: {
        //       type: 'Polygon',
        //       // These coordinates outline Maine.
        //       coordinates: [
        //         [
        //           [-67.13734, 45.13745],
        //           [-66.96466, 44.8097],
        //           [-68.03252, 44.3252],
        //           [-69.06, 43.98],
        //           [-70.11617, 43.68405],
        //           [-70.64573, 43.09008],
        //           [-70.75102, 43.08003],
        //           [-70.79761, 43.21973],
        //           [-70.98176, 43.36789],
        //           [-70.94416, 43.46633],
        //           [-71.08482, 45.30524],
        //           [-70.66002, 45.46022],
        //           [-70.30495, 45.91479],
        //           [-70.00014, 46.69317],
        //           [-69.23708, 47.44777],
        //           [-68.90478, 47.18479],
        //           [-68.2343, 47.35462],
        //           [-67.79035, 47.06624],
        //           [-67.79141, 45.70258],
        //           [-67.13734, 45.13745],
        //         ],
        //       ],
        //     },
        //   },
        // });

        // Add a new layer to visualize the polygon.
        // map.addLayer({
        //   id: 'maine',
        //   type: 'fill',
        //   source: 'maine', // reference the data source
        //   layout: {},
        //   paint: {
        //     'fill-color': '#0080ff', // blue color fill
        //     'fill-opacity': 0.5,
        //   },
        // });
        // // Add a black outline around the polygon.
        // map.addLayer({
        //   id: 'outline',
        //   type: 'line',
        //   source: 'maine',
        //   layout: {},
        //   paint: {
        //     'line-color': '#000',
        //     'line-width': 3,
        //   },
        // });

        // The 'building' layer in the Mapbox Streets
        // vector tileset contains building height data
        // from OpenStreetMap.
        map.addLayer(
          {
            id: 'add-3d-buildings',
            source: 'composite',
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: 15,
            paint: {
              'fill-extrusion-color': '#bdd0db',

              // Use an 'interpolate' expression to
              // add a smooth transition effect to
              // the buildings as the user zooms in.
              'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
              'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
              'fill-extrusion-opacity': 0.8,
            },
          },
          labelLayerId
        );

        // map.on('click', function (e) {
        //   var coordinates = e.lngLat;
        //   new mapboxgl.Popup()
        //     .setLngLat(coordinates)
        //     .setHTML('you clicked here: <br/>' + coordinates)
        //     .addTo(map);
        // });

        setMap(map);
        map.resize();
      });
    };

    if (!map) initializeMap({ setMap, mapContainer });
  }, [map]);

  return <div ref={(el) => (mapContainer.current = el)} style={styles} />;
};

export default Map;
