import { Button, Modal } from '@ies-ds/react-ui';
import React from 'react';
import { useDeleteInvitationMutation } from '../../services/invitation';

const InvitationDelete = ({ id }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [deleteInvitation, { isError, isSuccess, isLoading }] = useDeleteInvitationMutation();

  return (
    <>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        Delete
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        <Modal.Header>Delete Invitation</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this invitation?</p>
          <br />
          <Button color="warning" onClick={() => deleteInvitation(id)}>
            Delete
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default InvitationDelete;
