import React, { useEffect, useState } from 'react';
import { NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import {
  useGetBuildingByIdQuery,
  useGetFloorsByBuildingIdQuery,
  useGetSensorByBuildingIdQuery,
  useGetZonesByBuildingIdQuery,
} from '../../services/building';
import { Box, Text, Textfield } from '@ies-ds/react-ui';
import ScrollBox from '../ui/ScrollBox';
import styled from 'styled-components';
import { BoundingBox } from '@phosphor-icons/react';
import ZoneDetail from '../ZoneDetail';
import { SurfaceColour3 } from '../config/app';

const StyledLink = styled(NavLink)`
  padding: 6px 8px;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
  }
  &.active {
    background: #171e2c;
  }
`;

const StyledTextfield = styled(Textfield)`
  padding: 6px 12px;
  font-size: 14px;
  background: #2e3649;
`;

const BuildingZones = ({ zones }) => {
  const { buildingId } = useParams();
  const navigate = useNavigate();

  const { isLoading, data: building } = useGetBuildingByIdQuery(buildingId);

  const [inputValueZone, setInputZone] = useState('');

  const [filteredDataZone, setFilteredDataZone] = useState([]);

  useEffect(() => {
    const filteredZone = zones?.filter((data) => data.name.toLowerCase().includes(inputValueZone.toLowerCase()));
    // zones.length > 0 && navigate(`${zones[0]?.id}`);
    setFilteredDataZone(filteredZone);
  }, [inputValueZone, zones]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <Box flex={1} direction="row" style={{ padding: '16px 16px 0 16px ', overflow: 'hidden' }}>
        <Box direction="column" style={{ width: '250px' }}>
          <StyledTextfield
            placeholder="Filter Rooms..."
            onChange={(e) => setInputZone(e.target.value)}
            value={inputValueZone}
          />
          <Text color="lightest" size="xsmall" style={{ marginTop: '-12px', marginBottom: '12px' }}>
            Count: {filteredDataZone?.length}
          </Text>
          <ScrollBox>
            {filteredDataZone < 1 ? (
              <Text color="light"> No Zones</Text>
            ) : (
              filteredDataZone?.map((zone) => {
                if (zone.floorId === null) return null;
                else
                  return (
                    <StyledLink to={`${zone.id}`}>
                      <Box direction="column" alignItems="flex-start" gap={8}>
                        <Box direction="row" gap={8}>
                          <BoundingBox size={20} color={'#f6803b'} />
                          <Box direction="column">
                            <Text color="light" style={{ lineHeight: '1.4' }}>
                              {' '}
                              {zone.name}
                            </Text>
                            <Text color="lightest" size="xsmall">
                              {zone.floors?.name ? `${zone.floors?.name}: ` : null} {zone.sensors.length} Sensors
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </StyledLink>
                  );
              })
            )}{' '}
          </ScrollBox>
        </Box>
        <ScrollBox
          style={{ padding: '16px  24px', marginLeft: '16px', background: SurfaceColour3, borderRadius: '6px' }}
        >
          <Routes>
            <Route path="/*" element={<Text color="lighter">Please Select a Zone</Text>} />
            <Route path="/:zoneId/*" element={<ZoneDetail building={building} />} />
          </Routes>
        </ScrollBox>
      </Box>
    </>
  );
};

export default BuildingZones;
