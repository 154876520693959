import React from 'react';
import { Box, Dropdown, Text, ThemeProvider, light } from '@ies-ds/react-ui';
// import { useGetAccountInfoQuery } from '../../services/oauth';
// import { useGetViewMyProfileQuery } from '../../services/ulp/user';
import AvatarColor from './AvatarColor';
import { useMsal } from '@azure/msal-react';
import { useGetUserByIdQuery } from '../../services/user';
import { Link } from 'react-router-dom';
import useCheckRole from '../../hooks/useCheckRole';
import { Gear } from '@phosphor-icons/react';

const UserdropDown = ({ token }) => {
  const { instance, accounts } = useMsal();
  // const { isLoadingAccount, dataAccount, errorAccount } = useGetAccountInfoQuery({ token: token });
  //const { isLoading, data, error } = useGetViewMyProfileQuery();

  const { name, email, given_name, family_name, sub } = accounts[0]?.idTokenClaims;

  const { hasRole, isLoadingRole } = useCheckRole('ADMIN');

  if (isLoadingRole) {
    return <p>Loading user...</p>;
  }

  return (
    <>
      {console.log('accounts', accounts)}
      {true && (
        <ThemeProvider theme={light}>
          <Dropdown
            // triggerAnchor="top-left"
            // dropdownAnchor="left-bottom"
            style={{ zIndex: '9999' }}
            width={248}
            //trigger={<AvatarColor name={`${Array.from(data.data.firstname)[0]}${Array.from(data.data.lastname)[0]}`} />}
            trigger={
              given_name ? (
                <AvatarColor
                  name={`${Array.from(accounts[0]?.idTokenClaims.given_name)[0]}${
                    Array.from(accounts[0]?.idTokenClaims.family_name)[0]
                  }`}
                />
              ) : (
                <AvatarColor name={`${Array.from(accounts[0]?.idTokenClaims.name)[0]}`} />
              )
            }
          >
            <Box style={{ padding: '8px' }} alignItems="center">
              <div style={{ width: '56px' }}>
                {given_name ? (
                  <AvatarColor size="large" name={`${Array.from(given_name)[0]}${Array.from(family_name)[0]}`} />
                ) : (
                  <AvatarColor size="large" name={`${Array.from(name)[0]}`} />
                )}
                {/* <AvatarColor size="large" name={`${Array.from(given_name)[0]}${Array.from(family_name)[0]}`} /> */}
              </div>
              <Box direction="column" gap={2}>
                {given_name ? (
                  <Text color="darker">
                    {given_name} {family_name}
                  </Text>
                ) : (
                  <Text color="darker">{name}</Text>
                )}
                {/* <Text color="darker">
                {given_name} {family_name}
              </Text> */}
                <Text color="lighter" size="xsmall">
                  {email}
                </Text>
              </Box>
            </Box>

            {/* <Dropdown.Divider /> */}
            {/* <Dropdown.Item icon="user" size="small">
            <Text>My Account</Text>
          </Dropdown.Item>
          <Dropdown.Item icon="settingsSolid" size="small">
            <Text>My Subscriptions</Text>
          </Dropdown.Item>
          <Dropdown.Divider /> */}
            {/* <Dropdown.Item size="small">
              <Text size="xsmall" weight="bold" color="light">
                SUB:
              </Text>
              <Text>{sub}</Text>
            </Dropdown.Item> */}

            {hasRole && (
              <>
                {' '}
                <Dropdown.Divider />
                <Link to="/dashboard-admin" style={{ textDecoration: 'none' }}>
                  <Dropdown.Item size="small">
                    <Box alignItems="center" gap={8}>
                      <Gear size={20} />
                      <Text>Site Admin</Text>
                    </Box>
                  </Dropdown.Item>
                </Link>
              </>
            )}
            <Dropdown.Divider />
            {/* <Dropdown.Item icon="user" size="small">
            <Text>My Account</Text>
          </Dropdown.Item>
          <Dropdown.Item icon="settingsSolid" size="small">
            <Text>My Subscriptions</Text>
          </Dropdown.Item>
          <Dropdown.Divider /> */}
            <Dropdown.Item
              size="small"
              icon="close"
              onClick={() =>
                instance.logoutRedirect({
                  postLogoutRedirectUri: '/',
                })
              }
            >
              <Text>Sign Out </Text>
            </Dropdown.Item>
            {/* <Dropdown.Item
            as={'a'}
            href="/auth/logout-ies"
            size="small"
            icon="close"
            onClick={() =>
              instance.logoutRedirect({
                postLogoutRedirectUri: '/',
              })
            }
          >
            <Text>Sign Out 1</Text>{' '}
          </Dropdown.Item>
          <AuthenticatedTemplate>
            <Dropdown.Item
              icon="close"
              size="small"
              onClick={() =>
                instance.logoutRedirect({
                  postLogoutRedirectUri: '/',
                })
              }
            >
              <Text>Sign Out 2</Text>
            </Dropdown.Item>
          </AuthenticatedTemplate> */}
          </Dropdown>
        </ThemeProvider>
      )}
    </>
  );

  // return (
  //   <>
  //     {!isLoading && (
  //       <Dropdown
  //         width={248}
  //         trigger={<AvatarColor name={`${Array.from(data.data.firstname)[0]}${Array.from(data.data.lastname)[0]}`} />}
  //       >
  //         <Box style={{ padding: '8px' }} alignItems="center">
  //           <div style={{ width: '56px' }}>
  //             <AvatarColor
  //               size="large"
  //               name={`${Array.from(data.data.firstname)[0]}${Array.from(data.data.lastname)[0]}`}
  //             />
  //           </div>
  //           <Box direction="column" gap={2}>
  //             <Text color="darker">{data.data.name}</Text>
  //             <Text color="lighter" size="xsmall">
  //               {data.data.email}
  //             </Text>
  //           </Box>
  //         </Box>
  //         <Dropdown.Divider />
  //         <Dropdown.Item icon="user" size="small">
  //           <Text>My Account</Text>
  //         </Dropdown.Item>
  //         <Dropdown.Item icon="settingsSolid" size="small">
  //           <Text>My Subscriptions</Text>
  //         </Dropdown.Item>
  //         <Dropdown.Divider />
  //         {/* <Dropdown.Item
  //           size="small"
  //           icon="close"
  //           onClick={() =>
  //             instance.logoutRedirect({
  //               postLogoutRedirectUri: '/',
  //             })
  //           }
  //         >
  //           <Text>Sign Out</Text>{' '}
  //         </Dropdown.Item> */}
  //         {/* <Dropdown.Item
  //           as={'a'}
  //           href="/auth/logout-ies"
  //           size="small"
  //           icon="close"
  //           onClick={() =>
  //             instance.logoutRedirect({
  //               postLogoutRedirectUri: '/',
  //             })
  //           }
  //         >
  //           <Text>Sign Out</Text>{' '}
  //         </Dropdown.Item> */}
  //       </Dropdown>
  //     )}
  //   </>
  // );
};

export default UserdropDown;
