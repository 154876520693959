import React from 'react';
import styled from 'styled-components';

const StyledContentWindow = styled.div`
  padding: 32px;
  flex: 1;
  overflow-y: auto;
  width: 100%;
`;

const ContentWindow = ({ children }) => {
  return <StyledContentWindow>{children}</StyledContentWindow>;
};

export default ContentWindow;
