import { Text, Box, Grid, Logo, Avatar, ThemeContainer, dark, ThemeProvider } from '@ies-ds/react-ui';
import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import UserdropDown from '../components/ui/UserDropdown';
import BuildingList from './BuildingList';
import Sidenav from './ui/sidenav';
import { level3 } from './ui/colors';
import Buildings from './Buildings/Buildings';

export const StyledDashboardContainer = styled.div`
  background-color: #1e293b;
  position: fixed;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: auto;
`;

export const PanelLv1 = styled.div`
  background-color: #1a2332;
  padding: 20px;
  border-radius: 4px;
`;

const WhiteText = styled(Text)`
  color: #fff;
`;

const LayoutDashboard = () => {
  return (
    <ThemeProvider theme={dark}>
      <StyledDashboardContainer>
        <Box
          style={{ minWidth: '60px', background: level3, padding: '12px 12px' }}
          direction="column"
          alignItems="center"
        >
          <Box justifyContent="flex-start" style={{ width: '100%' }}>
            <Link to={'/'}>
              <Logo style={{ borderRadius: '2px' }} />
            </Link>
          </Box>
          <br />
          <Sidenav />
        </Box>

        <Box direction="column" flex={1} style={{ position: 'relative' }}>
          <Routes>
            <Route path="/" element={'HOME'} />
            {/* <Route path="/buildings/*" element={<BuildingList />} /> */}
            <Route path="/buildings/*" element={<Buildings />} />
          </Routes>
        </Box>
      </StyledDashboardContainer>
    </ThemeProvider>
  );
};

export default LayoutDashboard;
