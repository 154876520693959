import { Table, Text } from '@ies-ds/react-ui';
import React from 'react';
import StatusCell from './ui/StatusCell';
import styled from 'styled-components';
import { SurfaceColour, SurfaceColour2, SurfaceColour3 } from './config/app';
import { level1, level2, level3 } from './ui/colors';
import { Link } from 'react-router-dom';

const StyledTableContainer = styled.div`
padding: 0;
padding-bottom:12px;
border-radius: 6px;
  background-color: ${level2};
  margin-bottom: 20px;
  table {
    border-bottom: 1px solid ${SurfaceColour3};
    padding: 0;
  }

  th {
    padding: 20px;
    //background-color: ${SurfaceColour};
    text-align: left;
    color: #fff;
    align-items: center;
    text-align: center;
    width: 20%;
    border-bottom: 1px solid ${SurfaceColour3};
    border-right: 1px solid #1e293b;
    &:first-child {
      border-radius: 6px 0 0 0;
      width: 15%;
      background-color: #171e2c;
      border-right: 1px solid #1e293b;
    }
  }
  tr {
    &:hover {
      background-color: unset;
    }
  td {
    padding: 1px;
    // background-color: ${SurfaceColour};
    border-bottom: 1px solid ${SurfaceColour3};
    border-right: 1px solid #1e293b;
    &:first-child {
      padding: 12px 16px;
      text-align: center;
      border-right: 1px solid #1e293b;
    }
    &:last-child {
      border-right: 1px solid ${SurfaceColour3};

    }
  }
`;

const StyledLink = styled(Link)`
  background: #18202c;
  display: block;
  margin: -px -20px;
  padding: 8px 20px;
  height: 38.5px;
  &:hover {
    text-decoration: none;
    background: rgb(20, 27, 40);
  }
`;

const FloorDetailTable = ({ floor, building }) => {
  return (
    <StyledTableContainer>
      <Table stripedColors={true}>
        <tr>
          <th>Sensor</th>
          <th>Temperature</th>
          <th>Humidity</th>
          <th>Light</th>
          <th>CO₂</th>
        </tr>
        {floor.zones?.map((zone) => (
          <tr>
            <td style={{ padding: '12px 0', background: ' #171e2c' }}>
              {/* <StyledLink to={'../../zone/' + zone.id}>
                <Text weight="medium" color={zone.name.toLowerCase() === 'external' ? 'blue' : 'light'}>
                  {zone.name}
                </Text>
              </StyledLink> */}

              <Text weight="medium" color={zone.name.toLowerCase() === 'external' ? 'blue' : 'light'}>
                {zone.name}
              </Text>
            </td>
            <td>
              {floor.sensors?.map(
                (sensor) =>
                  sensor.zoneId === zone.id &&
                  sensor.sensorTypeId === 1 && (
                    <StatusCell
                      to={'sensor/' + sensor.id}
                      label={sensor?.type.name}
                      channel={sensor?.channel.trim()}
                      unit={sensor?.type.unit}
                      min={sensor?.type.min}
                      max={sensor?.type.max}
                      disableMax={sensor?.type.disableMax}
                      disableMin={sensor?.type.disableMin}
                      building={building.scanBuildingName}
                    />
                  )
              )}{' '}
              {floor.sensors?.map(
                (sensor) =>
                  sensor.zoneId === zone.id &&
                  sensor.sensorTypeId === 9 && (
                    <StatusCell
                      to={'sensor/' + sensor.id}
                      label={sensor?.type.name}
                      channel={sensor?.channel.trim()}
                      unit={sensor?.type.unit}
                      min={sensor?.type.min}
                      max={sensor?.type.max}
                      disableMax={sensor?.type.disableMax}
                      disableMin={sensor?.type.disableMin}
                      building={building.scanBuildingName}
                      color={'blue'}
                    />
                  )
              )}
            </td>{' '}
            <td>
              {floor.sensors?.map(
                (sensor) =>
                  sensor.zoneId === zone.id &&
                  sensor.sensorTypeId === 3 && (
                    <StatusCell
                      to={'sensor/' + sensor.id}
                      label={sensor?.type.name}
                      channel={sensor?.channel}
                      unit={sensor?.type.unit}
                      min={sensor?.type.min}
                      max={sensor?.type.max}
                      building={building.scanBuildingName}
                    />
                  )
              )}
              {floor.sensors?.map(
                (sensor) =>
                  sensor.zoneId === zone.id &&
                  sensor.sensorTypeId === 10 && (
                    <StatusCell
                      to={'sensor/' + sensor.id}
                      label={sensor?.type.name}
                      channel={sensor?.channel.trim()}
                      unit={sensor?.type.unit}
                      min={sensor?.type.min}
                      max={sensor?.type.max}
                      disableMax={sensor?.type.disableMax}
                      disableMin={sensor?.type.disableMin}
                      building={building.scanBuildingName}
                      color={'blue'}
                    />
                  )
              )}
            </td>
            <td>
              {floor.sensors?.map(
                (sensor) =>
                  sensor.zoneId === zone.id &&
                  sensor.sensorTypeId === 4 && (
                    <>
                      <StatusCell
                        to={'sensor/' + sensor.id}
                        label={sensor?.type.name}
                        channel={sensor?.channel}
                        unit={sensor?.type.unit}
                        min={sensor?.type.min}
                        max={sensor?.type.max}
                        building={building.scanBuildingName}
                      />
                    </>
                  )
              )}
            </td>
            <td>
              {floor.sensors?.map(
                (sensor) =>
                  sensor.zoneId === zone.id &&
                  sensor.sensorTypeId === 2 && (
                    <StatusCell
                      to={'sensor/' + sensor.id}
                      label={sensor?.type.name}
                      channel={sensor?.channel}
                      unit={sensor?.type.unit}
                      min={sensor?.type.min}
                      max={sensor?.type.max}
                      building={building.scanBuildingName}
                    />
                  )
              )}
            </td>
          </tr>
        ))}
      </Table>
    </StyledTableContainer>
  );
};

export default FloorDetailTable;
