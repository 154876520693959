import { Box, Loader, Select, Text } from '@ies-ds/react-ui';
import { useGetChannelDataSummaryRangeMeteredQuery } from '../../services/scan';
import ApexLineScan from './ApexLineScan';

import { useParams } from 'react-router-dom';

import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  width: 200px;
  margin-bottom: 0 !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8390a3;
  background-color: #253144;
  background-clip: padding-box;
  border: 2px solid #3a415b;
  border-radius: 0.25rem;
  padding: 0.275rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #abb2bd;
    background-color: #253144;
    border-color: #3a415b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem 0946a7;
  }

  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
`;

let startDate = moment().subtract(1, 'years').subtract(0, 'days').toISOString().substring(0, 10);

let twoYearAgo = moment().subtract(2, 'years').subtract(0, 'months').toISOString().substring(0, 10);
let oneYear = moment().subtract(1, 'years').subtract(0, 'months').toISOString().substring(0, 10);
let sixMonth = moment().subtract(0, 'years').subtract(6, 'months').toISOString().substring(0, 10);
let threeMonth = moment().subtract(0, 'years').subtract(3, 'months').toISOString().substring(0, 10);
let oneMonth = moment().subtract(0, 'years').subtract(1, 'months').toISOString().substring(0, 10);
let oneWeek = moment().subtract(0, 'years').subtract(7, 'days').toISOString().substring(0, 10);
let oneDay = moment().subtract(0, 'years').subtract(2, 'days').toISOString().substring(0, 10);

let oneYearPeriod = 1440;
let sixMonthPeriod = 1440;
let threeMonthPeriod = 1440;
let oneMonthPeriod = 60;
let oneWeekPeriod = 60;
let oneDayPeriod = 30;

let oneYearPeriodMins = 525600;
let sixMonthPeriodMins = 262800;
let threeMonthPeriodMins = 131400;
let oneMonthPeriodMins = 10080;
let oneWeekPeriodMins = 60;
let oneDayPeriodMins = 60;

let startDatChart = moment().subtract(0, 'years').subtract(12, 'months').toISOString().substring(0, 10);
let today12monthsago = moment(Date.now()).subtract(12, 'm').toISOString().substring(0, 10);
let today = moment(Date.now()).subtract(0, 'd').toISOString().substring(0, 10);

const rangeChart = {
  two_years: { startDate: twoYearAgo, endDate: today, period: oneYearPeriod, label: 'Past 48 Months' },
  this_year: { startDate: oneYear, endDate: today, period: oneYearPeriod, label: 'Past 12 Months' },
  six_month: { startDate: sixMonth, endDate: today, period: sixMonthPeriod, label: 'Past 6 Months' },
  three_month: { startDate: threeMonth, endDate: today, period: threeMonthPeriod, label: 'Past 3 Months' },
  month: { startDate: oneMonth, endDate: today, period: oneMonthPeriod, label: 'Past 30 Days' },
  week: { startDate: oneWeek, endDate: today, period: oneWeekPeriod, label: 'Past Week' },
  day: { startDate: oneDay, endDate: today, period: oneDayPeriod, label: 'Past 48 hrs' },
};

const range = {
  two_years: { startDate: twoYearAgo, endDate: oneYear, period: oneYearPeriodMins, label: 'Previous Year' },
  this_year: { startDate: oneYear, endDate: today, period: oneYearPeriodMins, label: 'Past 12 Months' },
  six_month: { startDate: sixMonth, endDate: today, period: sixMonthPeriodMins, label: 'Past 6 Months' },
  three_month: { startDate: threeMonth, endDate: today, period: threeMonthPeriodMins, label: 'Past 3 Months' },
  month: { startDate: oneMonth, endDate: today, period: oneMonthPeriodMins, label: 'Past 30 Days' },
  week: { startDate: oneWeek, endDate: today, period: oneWeekPeriodMins, label: 'Past Week' },
  day: { startDate: oneDay, endDate: today, period: oneDayPeriodMins, label: 'Past 48 hrs' },
};

const DataVisualisation = ({
  id = 'chart1',
  channelName = '',
  channelSummary = '',
  period = '1440',
  units = 'kWh',
  channelData = [],
  colors,
  height = 280,
  date_range = 'three_month',
  meterType = '',
  building = 'CharlesKennedyBuilding',
}) => {
  const { zone } = useParams();

  const [dateRange, setDateRange] = useState(date_range);

  useEffect(() => {
    setDateRange(date_range);
  }, [date_range]);

  const {
    isLoading: isLoadingCh1,
    data: meteredDataCh1,
    error: errorMeteredCh1,
    isFetching: isFetchingCh1,
  } = useGetChannelDataSummaryRangeMeteredQuery({
    project: 'HighlandCouncil',
    building: building,
    channelId: channelData[0].id,
    period: rangeChart[dateRange].period,
    startDate: rangeChart[dateRange].startDate,
    endDate: rangeChart[dateRange].endDate,
    refetchOnMountOrArgChange: true,
  });

  const {
    isLoading: isLoadingCh2,
    data: meteredDataCh2,
    error: errorMeteredCh2,
  } = useGetChannelDataSummaryRangeMeteredQuery({
    project: 'HighlandCouncil',
    building: building,
    channelId: channelData[1]?.id,
    period: rangeChart[dateRange].period,
    startDate: rangeChart[dateRange].startDate,
    endDate: rangeChart[dateRange].endDate,
    refetchOnMountOrArgChange: true,
  });

  const {
    isLoading: isLoadingCh3,
    data: meteredDataCh3,
    error: errorMeteredCh3,
  } = useGetChannelDataSummaryRangeMeteredQuery({
    project: 'HighlandCouncil',
    building: building,
    channelId: channelData[2]?.id,
    period: rangeChart[dateRange].period,
    startDate: rangeChart[dateRange].startDate,
    endDate: rangeChart[dateRange].endDate,
    refetchOnMountOrArgChange: true,
  });

  const handleChange = (event) => {
    console.log(event.target.value);
    setDateRange(event.target.value);
  };

  return (
    <>
      <Box direction="column">
        <Box direction="row" justifyContent="space-between">
          <Box direction="column" gap={4}>
            <Box gap={12}>
              <Text color="dark" size="large" weight="bold">
                {channelName}
              </Text>
              <Text
                weight="bold"
                size="xsmall"
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  borderRadius: '50px',
                  lineHeight: '1',
                  color: '#fff',
                  background: '#557ff7',
                  padding: '1px 8px',
                }}
              >
                {meterType}
              </Text>
            </Box>
            <Text color="lighter" size="small" transform="capitalize">
              {channelSummary}
            </Text>
          </Box>
          <Box>
            <StyledSelect onChange={handleChange} defaultValue={'three_month'}>
              <option value="two_years">Last 2 Years (Daily)</option>
              <option value="this_year">Last 12 Months (Daily)</option>
              <option value="six_month">Last 6 Months (Daily)</option>
              <option value="three_month" selected="selected">
                Last 3 Months (Daily)
              </option>
              <option value="month">Past 30 days (Hourly)</option>
              <option value="week">Past 7 days (Hourly)</option>
              <option value="day">Past 48 hours (Half-Hourly)</option>
            </StyledSelect>
          </Box>
        </Box>
        <br />
        {isLoadingCh1 || isLoadingCh2 || isLoadingCh3 || isFetchingCh1 ? (
          <Box direction="row" style={{ height }} alignItems="center" justifyContent="center">
            <Loader />
          </Box>
        ) : (
          <>
            <ApexLineScan
              height={height}
              id={id}
              units={units}
              fixed={0}
              channel1={{ name: channelData[0].name, data: meteredDataCh1.Mean }}
              channel2={{ name: channelData[1]?.name, data: meteredDataCh2?.Mean }}
              channel3={{ name: channelData[2]?.name, data: meteredDataCh3?.Mean }}
              timestamps={meteredDataCh1.Timestamps}
              colors={colors}
            />
            {/* {meteredData.Total.map((d) => d)} */}
          </>
        )}
      </Box>
    </>
  );
};

export default DataVisualisation;
