import React from 'react';
import { useUpdateUtilitiesMutation } from '../../services/building';
import { Box, Button, Modal, Select, Text, Textarea, Textfield } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const UtilitiesEdit = ({ utility, building, id }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateUtilities, { isError, isLoading, isSuccess }] = useUpdateUtilitiesMutation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    updateUtilities({
      id: utility.id,
      description: data.description,
      channelCarbon: data.channelCarbon,
      channelCost: data.channelCost,
      channelEui: data.channelEui,
      channelTotal: data.channelTotal,
    });
  };

  return (
    <div>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {' '}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Text weight={'medium'} size="large">
              Edit {utility.type}
            </Text>
          </Modal.Header>
          <Modal.Content>
            <Text weight="medium">Do you want to edit this utility?</Text>
            <br />

            {/* {isLoading && <div>Loading...</div>}
            {isError && <div>Error updating utility</div>} */}

            <Textfield label="Name" {...register('name', { required: true })} defaultValue={utility.type} />
            <Textarea
              label="Supplier Information"
              rows={3}
              {...register('description')}
              defaultValue={utility.description}
              placeholder="i.e. Short description"
            />
            <Textfield
              label="Scan Channel for  Use"
              {...register('channelTotal')}
              defaultValue={utility.channelTotal}
              placeholder="i.e. SC000001"
            />
            <Textfield
              label="Scan Channel  for  EUI  or Per m2"
              {...register('channelEui')}
              defaultValue={utility.channelEui}
              placeholder="i.e. SC000001"
            />

            <Textfield
              label="Scan Channel  for  Carbon (water n/a)"
              {...register('channelCarbon')}
              defaultValue={utility.channelCarbon}
              placeholder="i.e. SC000001"
            />
            <Textfield
              label="Scan Channel  for  Cost"
              {...register('channelCost')}
              defaultValue={utility.channelCost}
              placeholder="i.e. SC000001"
            />

            {isSuccess && (
              <>
                {' '}
                <Text color="green">Utilities updated successfully</Text>
                <br />
              </>
            )}
          </Modal.Content>

          <Modal.Footer>
            <Box gap={8}>
              <Button color="primary" type="submit">
                Update
              </Button>
              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Box>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default UtilitiesEdit;
