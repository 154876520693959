import { useMsal } from '@azure/msal-react';
import { useState, useEffect } from 'react';
import { useGetUserByIdQuery } from '../services/user';

const useCheckRole = (requiredRole) => {
  const [hasRole, setHasRole] = useState(false);
  const { accounts } = useMsal();
  const { sub } = accounts[0]?.idTokenClaims;
  const { isLoading, data: user } = useGetUserByIdQuery(sub);

  useEffect(() => {
    const roles = ['SUPERADMIN', 'ADMIN', 'EDITOR', 'VIEWER']; //  ORDER OF AUTH LEVELS

    const userIndex = roles.indexOf(user?.role.type);
    const requiredIndex = roles.indexOf(requiredRole);

    if (userIndex <= requiredIndex && roles.includes(user?.role.type)) {
      setHasRole(true);
    } else {
      setHasRole(false);
    }
  }, [requiredRole, user?.role.type]);

  return {
    hasRole: hasRole,
    userRole: user?.role.type,
    isLoadingRole: isLoading,
    notAuthorised: !hasRole,
  };
};

export default useCheckRole;
