import React from 'react';
import { useGetAllRolesQuery } from '../../services/role';
import { Container, Table, Text } from '@ies-ds/react-ui';

const RoleList = () => {
  const { data: roles, error, isLoading } = useGetAllRolesQuery();

  if (isLoading) {
    return <p>Loading roles...</p>;
  }

  if (error) {
    return <p>There was an error loading the roles.</p>;
  }

  return (
    <div>
      <Text size="large" weight="medium">
        Roles
      </Text>
      <br />
      <Container>
        <Table>
          <thead>
            <tr>
              <th>id</th>
              <th>Name</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role) => (
              <tr key={role.id}>
                <td>{role.id}</td>
                <td>{role.name}</td>
                <td>{role.type}</td>
                <td>{role.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default RoleList;
