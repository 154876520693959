import { Box, Button, Modal, Select, Text, Textarea, Textfield } from '@ies-ds/react-ui';
import React from 'react';

import { useForm } from 'react-hook-form';
import { useCreateUtilitiesMutation } from '../../services/building';

const UtilitiesCreate = ({ id }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [createUtilities, { isError, isLoading, isSuccess }] = useCreateUtilitiesMutation();

  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    createUtilities({ ...data, buildingId: parseInt(id) });

    console.log(data);
  };

  return (
    <div>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Add Utilities
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        <Modal.Header>
          <Text size="large" weight="bold">
            Add new Utilities
          </Text>
        </Modal.Header>
        <Modal.Content>
          {isError && (
            <>
              <p style={{ color: 'red' }}>
                Error creating utilities, please ensure that the types doesn't exsist already
              </p>
              <br />
            </>
          )}
          {isSuccess && (
            <>
              <p style={{ color: 'green' }}>Utilities created successfully</p>
              <br />
            </>
          )}

          {/* <Textfield label="Type" {...register('type')} /> */}
          <Select label="Type" {...register('type')}>
            <option value="" selected disabled hidden>
              Please select
            </option>
            <option value={'energy'}>Energy</option>
            <option value={'electricity'}>Electricity</option>
            <option value={'water'}>Water</option>
            <option value={'biomass'}>Biomass</option>
            <option value={'oil'}>Oil</option>
            <option value={'gas'}>Gas</option>
            <option value={'propane'}>Propane</option>
            {/* {building.zones.map((zone) => (
              <option value={zone.id}>{zone.name}</option>
            ))} */}
          </Select>
          <Textarea
            label="Supplier Information"
            rows={3}
            {...register('description')}
            placeholder="i.e. Short description"
          />
          <Textfield label="Scan Channel for Use" {...register('channelTotal')} placeholder="i.e. SC000001" />
          <Textfield label="Scan Channel for  EUI or per m2" {...register('channelEui')} placeholder="i.e. SC000001" />
          <Textfield
            label="Scan Channel for  Carbon (water n/a)"
            {...register('channelCarbon')}
            placeholder="i.e. SC000001"
          />
          <Textfield label="Scan Channel for  Cost" {...register('channelCost')} placeholder="i.e. SC000001" />
        </Modal.Content>
        <Modal.Footer>
          <Box gap={6}>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Create
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UtilitiesCreate;
