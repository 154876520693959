import React from 'react';
import { useDeleteZoneMutation } from '../../services/building';
import { Box, Button, Modal, Text } from '@ies-ds/react-ui';

const ZoneDelete = ({ id }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [deleteZone, { isError, isLoading, isSuccess }] = useDeleteZoneMutation();

  return (
    <div>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        Delete
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Modal.Header>
          <Text weight={'medium'} size="large">
            Delete zone
          </Text>
        </Modal.Header>
        <Modal.Content>
          <Text weight="medium">Are you sure you want to delete this zone?</Text>
          <br />
          <p>All floor and sensor data associated with the zone will be permanently deleted as well!</p>
        </Modal.Content>

        <Modal.Footer>
          <Box gap={8}>
            <Button color="warning" onClick={() => deleteZone(id)}>
              Delete
            </Button>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ZoneDelete;
