import React from 'react';
import { useUpdateBmsSensorMutation } from '../../services/building';
import { Box, Button, Modal, Select, Text, Textarea, Textfield } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const BmsSensorEdit = ({ sensor, building, id }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateBmsSensor, { isError, isLoading, isSuccess }] = useUpdateBmsSensorMutation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    updateBmsSensor({
      id: sensor.id,
      buildingId: building.id,
      name: data.name,
      description: data.description,
      bmsSensorTypeId: data.bmsSensorTypeId,
      channel: data.channel,
      scanBuildingName: data.scanBuildingName,
    });
  };

  return (
    <div>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {' '}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Text weight={'medium'} size="large">
              Edit {sensor.name}
            </Text>
          </Modal.Header>
          <Modal.Content>
            <Text weight="medium">Do you want to edit this sensor?</Text>
            <br />

            {/* {isLoading && <div>Loading...</div>}
            {isError && <div>Error updating sensor</div>} */}

            <Textfield label="Name" {...register('name', { required: true })} defaultValue={sensor.name} />
            <Textarea label="Description" {...register('description')} rows={2}></Textarea>

            {console.log('sensor.bmsSensorTypeId', sensor.bmsSensorTypeId)}

            <Select
              required
              label="BMS Sensor Type"
              {...register('bmsSensorTypeId', { required: true })}
              defaultValue={sensor.bmsSensorTypeId}
            >
              <option value="" selected disabled hidden>
                Please select
              </option>

              <option value={1}>BMS - Biomass</option>
              <option value={2}>BMS - CT Circuit</option>
              <option value={3}>BMS - DHW Calorifier</option>
              <option value={4}>BMS - Water</option>
              <option value={5}>IOT - Calorifier</option>
            </Select>

            <Textfield
              required
              label="Scan Channel ID"
              {...register('channel', { required: true })}
              defaultValue={sensor.channel}
            />
            <Textfield
              required
              label="Scan Building for Channel"
              {...register('scanBuildingName', { required: true })}
              defaultValue={sensor.scanBuildingName}
            />
            {isSuccess && (
              <>
                {' '}
                <Text color="green">BMS Sensor updated successfully</Text>
                <br />
              </>
            )}
          </Modal.Content>

          <Modal.Footer>
            <Box gap={8}>
              <Button color="primary" type="submit">
                Update
              </Button>
              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Box>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default BmsSensorEdit;
