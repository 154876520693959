import React from 'react';
import { useUpdateSensorMutation } from '../../services/building';
import { Box, Button, Modal, Select, Text, Textfield } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const SensorEdit = ({ sensor, building }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateSensor, { isError, isLoading, isSuccess }] = useUpdateSensorMutation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    updateSensor({
      id: sensor.id,
      buildingId: building.id,
      name: data.name,
      floorId: data.floorId,
      zoneId: data.zoneId,
      sensorTypeId: data.sensorTypeId,
      channel: data.channel,
    });
  };

  return (
    <div>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {' '}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Text weight={'medium'} size="large">
              Edit {sensor.name}
            </Text>
          </Modal.Header>
          <Modal.Content>
            <Text weight="medium">Do you want to edit this sensor?</Text>
            <br />

            {/* {isLoading && <div>Loading...</div>}
            {isError && <div>Error updating sensor</div>} */}

            <Textfield label="Name" {...register('name', { required: true })} defaultValue={sensor.name} />
            <Select label="Choose Floor" {...register('floorId')}>
              <option value="" selected disabled hidden>
                Please select
              </option>

              {building.floors.map((floor) =>
                floor.id === sensor.floorId ? (
                  <Select.Option value={floor.id} selected>
                    {floor.name}
                  </Select.Option>
                ) : (
                  <Select.Option value={floor.id}>{floor.name}</Select.Option>
                )
              )}
            </Select>
            <Select label="Choose Zone" {...register('zoneId')}>
              <option value="" selected disabled hidden>
                Please select
              </option>

              {building.zones.map((zone) =>
                zone.id === sensor.zoneId ? (
                  <Select.Option value={zone.id} selected>
                    {zone.name}
                  </Select.Option>
                ) : (
                  <Select.Option value={zone.id}>{zone.name}</Select.Option>
                )
              )}
            </Select>

            <Select label="Sensor Type" {...register('sensorTypeId')}>
              <option value="" selected disabled hidden>
                Please select
              </option>

              <option value={1}>Temperature</option>
              <option value={2}>CO₂</option>
              <option value={3}>Relative Humidity</option>
              <option value={4}>Light</option>
            </Select>

            <Textfield label="Channel" {...register('channel', { required: true })} defaultValue={sensor.channel} />
            {isSuccess && (
              <>
                {' '}
                <Text color="green">Sensor updated successfully</Text>
                <br />
              </>
            )}
          </Modal.Content>

          <Modal.Footer>
            <Box gap={8}>
              <Button color="primary" type="submit">
                Update
              </Button>
              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Box>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default SensorEdit;
