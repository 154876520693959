import React, { useEffect } from 'react';
import { useCreateFloorMutation } from '../../services/building';
import { Button, Modal, Textfield, Text, Box } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';
import { Plus } from '@phosphor-icons/react';

const FloorCreate = ({ id }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [createFloor, { isSuccess, reset }] = useCreateFloorMutation();

  const onSubmit = (data) => {
    createFloor({ ...data, buildingId: parseInt(id) });
    reset();
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  const openModal = () => {
    reset();
    setIsOpen(true);
  };

  return (
    <>
      <Button size="small" color="primary" onClick={() => openModal()}>
        <Box alignItems="center" gap={6}>
          <Plus color="#fff" size={16} /> New Floor
        </Box>
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        <Modal.Header>
          <Text size="large" weight="bold">
            Add New Floor
          </Text>
        </Modal.Header>
        <Modal.Content>
          {errors.name && (
            <>
              <p style={{ color: 'red' }}>Name is required</p>
              <br />
            </>
          )}
          {isSuccess && (
            <>
              <p style={{ color: 'green' }}>Floor created successfully</p>
              <br />
            </>
          )}

          <Textfield label="Name" required {...register('name', { required: 'This is required.' })} />
        </Modal.Content>
        <Modal.Footer>
          <Box gap={6}>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Create
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FloorCreate;
