import moment from 'moment';

export const gridSpacing = 8;
export const SurfaceColour = '#1e293b';
export const SurfaceColour2 = 'rgba(0, 0, 0, 0.15)';
export const SurfaceColour3 = '#262f41';
export const colorCo2 = 'rgb(34, 204, 221)';
export const colorTemp = 'rgb(248, 113, 113)';
export const colorHumidity = 'rgb(17, 153, 238)';

export const minTemp = '65';
export const minCo2 = '0';
export const minHumidity = '30';
export const maxTemp = '74';
export const maxCo2 = '900';
export const maxHumidity = '50';

//let startDatChart = moment().subtract(0, 'years').subtract(1, 'days').toISOString().substring(0, 10);
export const dateToday = moment(Date.now()).subtract(0, 'd').toISOString().substring(0, 10);

// https://api.openweathermap.org/data/2.5/weather?q=cleveland&appid=424151e61e6db9f23c95d99ef10813fd&units=metric Cel
// https://api.openweathermap.org/data/2.5/weather?q=cleveland&appid=424151e61e6db9f23c95d99ef10813fd&units=imperial Far

export function energyUseIntensity(value) {
  if (isNaN(value)) {
    return '-';
  } else {
    return (((parseInt(value, 10) * 1000) / 55123) * 3.4128).toFixed(2);
  }
}

export function co2EmissionsElect(value) {
  if (isNaN(value)) {
    return '-';
  } else {
    return (parseInt(value, 10) * 0.203).toFixed(1);
  }
}

export function co2EmissionsGas(value) {
  if (isNaN(value)) {
    return '-';
  } else {
    return (parseInt(value, 10) * 0.0551).toFixed(1);
  }
}

export function cleanCalc(value) {
  if (isNaN(value)) {
    return '-';
  } else {
    return parseInt(value, 10)
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

export function costCalculationElect(value) {
  if (isNaN(value)) {
    return '-';
  } else {
    return (parseInt(value, 10) * 1000 * 0.1486).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

export function costCalculationGas(value) {
  if (isNaN(value)) {
    return '-';
  } else {
    return ((0.6619 * parseInt(value, 10) * 1000) / 29.3).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

export function getLastNotNullValue(value, type = 'Total') {
  const current = value[type];
  const invertedArry = [];
  const newArry = [];

  for (var i = current.length - 1; i >= 0; i--) {
    invertedArry.push(current[i]);
  }

  invertedArry.map((item) => {
    return item != null ? newArry.push(item) : null;
  });

  return newArry[0];
}

export function getLastNotNullorZeroValue(value, type = 'Total') {
  const current = value[type];
  const invertedArry = [];
  const newArry = [];

  for (var i = current.length - 1; i >= 0; i--) {
    invertedArry.push(current[i]);
  }

  invertedArry.map((item) => {
    return item != null ? (item !== 0 ? newArry.push(item) : null) : null;
  });

  return newArry[0];
}
