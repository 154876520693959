import React from 'react';
import { useUpdateSensorTypeMutation } from '../../services/building';
import { Box, Button, Checkbox, Grid, Modal, Select, Text, Textarea, Textfield } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const SensorTypeEdit = ({ sensorType }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateSensorType, { isError, isLoading, isSuccess }] = useUpdateSensorTypeMutation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    updateSensorType({
      id: sensorType.id,
      name: data.name,
      description: data.description,
      min: data.min,
      max: data.max,
      unit: data.unit,
      disableMin: data.disableMin,
      disableMax: data.disableMax,
      location: data.location,
    });
  };

  return (
    <div>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {' '}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Text weight={'medium'} size="large">
              Edit {sensorType.name}
            </Text>
          </Modal.Header>
          <Modal.Content>
            <Text weight="medium">Do you want to edit this sensor?</Text>
            <br />

            {/* {isLoading && <div>Loading...</div>}
            {isError && <div>Error updating sensor</div>} */}

            <Textfield label="Name" required {...register('name', { required: true })} defaultValue={sensorType.name} />
            <Textfield label="Type" rows={6} {...register('description')} defaultValue={sensorType.description} />
            <Select
              label="Location"
              required
              {...register('location', { required: true })}
              defaultValue={sensorType.location}
            >
              {' '}
              <option value="" disabled hidden>
                Please select
              </option>
              <option value={'internal'}>Internal</option>
              <option value={'external'}>External</option>
            </Select>
            <Textfield label="Unit" {...register('unit')} defaultValue={sensorType.unit} />
            <Grid>
              <Grid.Col sm={6}>
                <Textfield type="number" label="Min" {...register('min')} defaultValue={sensorType.min} />
                <Checkbox label="No Min Value" {...register('disableMin')} checked={sensorType.disableMin} />
              </Grid.Col>
              <Grid.Col sm={6}>
                <Textfield type="number" label="Max" {...register('max')} defaultValue={sensorType.max} />
                <Checkbox label="No Max Value" {...register('disableMax')} checked={sensorType.disableMax} />
              </Grid.Col>
            </Grid>
            <br />

            {isSuccess && (
              <>
                {' '}
                <Text color="green">SensorType updated successfully</Text>
                <br />
              </>
            )}
          </Modal.Content>

          <Modal.Footer>
            <Box gap={8}>
              <Button color="primary" type="submit">
                Update
              </Button>
              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Box>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default SensorTypeEdit;
