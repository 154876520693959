import React, { useEffect, useState } from 'react';
import { Link, NavLink, Route, Routes, useParams } from 'react-router-dom';
import {
  useGetBmsSensorsByBuildingIdQuery,
  useGetBuildingByIdQuery,
  useGetFloorsByBuildingIdQuery,
  useGetSensorByBuildingIdQuery,
  useGetZonesByBuildingIdQuery,
} from '../../services/building';
import { Box, Button, Text, Textfield } from '@ies-ds/react-ui';
import ScrollBox from '../ui/ScrollBox';
import styled from 'styled-components';
import { ArrowLeft, BoundingBox, Broadcast, MagnifyingGlass, StackSimple } from '@phosphor-icons/react';
import Tab from '../ui/Tab';
import { level2 } from '../ui/colors';
import FloorDetail from '../FloorDetail';
import SensorDetail from '../SensorDetail';
import ZoneDetail from '../ZoneDetail';
import BuildingOverview from './BuildingOverview';
import BuildingFloors from './BuildingFloors';
import BuildingZones from './BuildingZones';
import BuildingSensor from './BuildingSensors';
import BuildingInfo from './BuildingInfo';
import BuildingMeters from './BuildingMeters';
import BuildingBMS from './BuildingBMS';
import useCheckRole from '../../hooks/useCheckRole';
import BuildingIotCalorifier from './IotCalorifier/IotCalorifier';

const StyledLink = styled(NavLink)`
  padding: 6px 8px;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
  }
  &.active {
    background: #171e2c;
  }
`;

const StyledTextfield = styled(Textfield)`
  padding: 6px 12px;
  font-size: 14px;
  background: rgb(33 45 64);
`;

const BuildingDetail = () => {
  const { buildingId } = useParams();

  const { isLoading, data } = useGetBuildingByIdQuery(buildingId);
  const { isLoading: loadingFloors, data: floors } = useGetFloorsByBuildingIdQuery(buildingId);
  const { isLoading: loadingZones, data: zones } = useGetZonesByBuildingIdQuery(buildingId);
  const { isLoading: loadingSensors, data: sensors } = useGetSensorByBuildingIdQuery(buildingId);
  const { isLoading: loadingBmsSensors, data: bmsSensors } = useGetBmsSensorsByBuildingIdQuery(buildingId);

  const { hasRole, isLoadingRole } = useCheckRole('ADMIN');

  if (isLoading || loadingFloors || loadingZones || loadingSensors || loadingBmsSensors) return <div>Loading...</div>;

  const meterTypeFilter = (type) => {
    return data.meters?.filter((meter) => meter.meterType === type);
  };

  const sensorTypeFilter = (type) => {
    return bmsSensors?.filter((sensor) => sensor.type.type === type);
  };

  return (
    <>
      <Box direction="column" flex={1}>
        <Box direction="column" style={{ padding: ' 16px 24px 2px 24px', borderBottom: '2px solid #37425b' }}>
          <Box justifyContent="space-between">
            <Box direction="row" alignItems="center" gap={12}>
              <Box
                style={{ background: '#3b82f6', width: '40px', height: '40px', borderRadius: '6px' }}
                alignItems="center"
                justifyContent="center"
              >
                <Text color="dark" size="xlarge" weight="medium" transform="uppercase">
                  {data.name.slice(0, 2)}
                </Text>
              </Box>
              <Box direction="column">
                <Text size="xlarge" color="light">
                  {data.name}
                </Text>
                <Text size="small" color="lighter" style={{ lineHeight: '1.2' }}>
                  {data.location}
                </Text>
              </Box>
            </Box>
            {hasRole && (
              <Button as={Link} to={`/dashboard-admin/buildings/${data.id}`} size="xsmall" color="primary">
                Edit
              </Button>
            )}
          </Box>

          <Box gap={8} style={{ margin: '16px 0 -2px -8px', zIndex: '2' }}>
            <Tab to={`/buildings/${buildingId}`} end>
              Overview
            </Tab>
            <Tab to={`/buildings/${buildingId}/floor/`}>Floors with IoT Sensors ({zones?.length})</Tab>
            {/* <Tab to={'zone'}>Rooms ({zones?.length})</Tab>
            <Tab to={'sensor'}>IOT Room Sensors ({sensors?.length})</Tab> */}
            {sensorTypeFilter('BMS')?.length > 0 && (
              <Tab to={`/buildings/${buildingId}/bms`}>BMS ({sensorTypeFilter('BMS').length})</Tab>
            )}
            {sensorTypeFilter('IOT')?.length > 0 && (
              <Tab to={`/buildings/${buildingId}/iot-calorifier`}>
                IoT Calorifier ({sensorTypeFilter('IOT').length})
              </Tab>
            )}
            {meterTypeFilter('BMS').length > 0 && (
              <Tab to={`/buildings/${buildingId}/bms-submeters`}>BMS Submeters ({meterTypeFilter('BMS').length})</Tab>
            )}
            {meterTypeFilter('IOT').length > 0 && (
              <Tab to={`/buildings/${buildingId}/iot-submeters`}>IoT Submeters ({meterTypeFilter('IOT').length})</Tab>
            )}
            <Tab to={`/buildings/${buildingId}/details`}>Details</Tab>
          </Box>
        </Box>

        <Box direction="row" alignItems="stretch" flex={1} style={{ overflow: 'hidden' }}>
          <Box direction="column" flex={1}>
            {/* <Link to={'../'} style={{ color: '#3b82f6', fontSize: '14px', margin: '30px 0 16px 0' }}>
                <Box alignItems="center" gap={4}>
                  <ArrowLeft color="#3b82f6" size="16" /> Back to Buildings
                </Box>
              </Link> */}

            {loadingFloors || loadingSensors || loadingZones ? (
              'Loading...'
            ) : (
              <>
                <Routes>
                  <Route
                    path="/*"
                    element={
                      <ScrollBox style={{ padding: '16px 16px 0 16px', marginBottom: '-24px' }}>
                        <BuildingOverview />
                      </ScrollBox>
                    }
                  />
                  <Route path="/floor/*" element={<BuildingFloors floors={floors} />} />
                  <Route path="/zone/*" element={<BuildingZones zones={zones} />} />
                  <Route path="/sensor/*" element={<BuildingSensor sensors={sensors} />} />
                  <Route path="/bms/*" element={<BuildingBMS bmsSensors={bmsSensors} type="BMS" />} />
                  <Route path="/iot-calorifier-test/*" element={<BuildingBMS bmsSensors={bmsSensors} type="IOT" />} />
                  <Route
                    path="/iot-calorifier/*"
                    element={<BuildingIotCalorifier bmsSensors={sensorTypeFilter('IOT')} type="IOT" />}
                  />

                  <Route
                    path="/bms-submeters"
                    element={<BuildingMeters meters={data.meters} type="BMS" title={'BMS Submeters'} />}
                  />
                  <Route
                    path="/iot-submeters"
                    element={<BuildingMeters meters={data.meters} type="IOT" title={'IoT Submeters'} />}
                  />
                  <Route path="/details/*" element={<BuildingInfo />} />
                </Routes>
              </>
            )}
          </Box>

          {/* <Routes>
            <Route
              path="/floor/:floorId/*"
              element={
                <ScrollBox style={{ padding: '32px', width: '100%', overflow: 'auto' }}>
                  <FloorDetail />
                </ScrollBox>
              }
            />
            <Route
              path="/zone/:zoneId/*"
              element={
                <ScrollBox style={{ padding: '32px', width: '100%', overflow: 'auto' }}>
                  <ZoneDetail />
                </ScrollBox>
              }
            />

            <Route
              path="/sensor/:sensorId"
              element={
                <ScrollBox style={{ padding: '32px', width: '100%', overflow: 'auto' }}>
                  <SensorDetail />
                </ScrollBox>
              }
            />
          </Routes> */}
        </Box>
      </Box>
    </>
  );
};

export default BuildingDetail;

const EmptyWindow = () => {
  return (
    <Box
      flex={1}
      // style={{ border: '1px solid yellow' }}
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={8}
    >
      <MagnifyingGlass size={36} color="#64748b" />
      <Text color="lightest">Browse the Data </Text>
    </Box>
  );
};
