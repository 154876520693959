import React, { useEffect } from 'react';
import { useUpdateFloorMutation } from '../../services/building';
import { Box, Button, Modal, Text, Textfield } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const FloorEdit = ({ floor }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateFloor, { isError, isLoading, isSuccess, reset }] = useUpdateFloorMutation();
  const {
    register,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    updateFloor({ id: floor.id, name: data.name, sort_order: data.sort_order });
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  return (
    <div>
      <Button size="small" onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {' '}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Text weight={'medium'} size="large">
              Edit {floor.name}
            </Text>
          </Modal.Header>
          <Modal.Content>
            <Text weight="medium">Do you want to edit this floor?</Text>
            <br />

            {/* {isLoading && <div>Loading...</div>}
            {isError && <div>Error updating floor</div>} */}

            <Textfield label="Name" required {...register('name', { required: true })} defaultValue={floor.name} />
            <Textfield label="Sort Order" {...register('sort_order')} defaultValue={floor.sort_order} />

            {isSuccess && (
              <>
                {' '}
                <Text color="green">Floor updated successfully</Text>
                <br />
              </>
            )}
          </Modal.Content>

          <Modal.Footer>
            <Box gap={8}>
              <Button color="primary" type="submit">
                Update
              </Button>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            </Box>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default FloorEdit;
