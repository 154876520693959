import React from 'react';
import ScrollBox from '../ui/ScrollBox';
import { Box, Text } from '@ies-ds/react-ui';
import Surface from '../ui/Surface';
import { SurfaceColour2 } from '../config/app';
import DataVisualisation from '../charts/DataVisualisation';

const BuildingMeters = ({ meters, type = 'BMS', title }) => {
  /// filter data by meter type
  const filteredMeters = meters?.filter((meter) => meter.meterType === type);

  console.log('filteredMeters', filteredMeters);

  return (
    <Box flex={1} direction="row" style={{ padding: '16px', overflow: 'hidden', marginBottom: '-38px' }}>
      <ScrollBox>
        <Text size="large">{title}</Text>

        <br />
        {filteredMeters?.map((meter) => {
          return (
            <Surface key={meter.id} bgColor={SurfaceColour2} style={{ marginBottom: '16px' }}>
              <Box key={meter.id} style={{ padding: '8px  0' }} direction="column">
                {/* <Text color="dark">{meter.name}</Text>
                <Text color="lightest">{meter.type}</Text> */}
                <DataVisualisation
                  building={meter.scanBuildingName}
                  id={meter.name}
                  units={meter.unit}
                  channelName={`${meter.name} `}
                  channelSummary={`${meter.dataType} (${meter.unit}) `}
                  colors={['#3fe4ff', '#3bf6a1']}
                  height={280}
                  meterType={meter.meterType}
                  channelData={[
                    {
                      id: meter.channel,
                      name: 'green',
                      color: 'green',
                    },
                  ]}
                />
              </Box>
            </Surface>
          );
        })}
      </ScrollBox>
    </Box>
  );
};

export default BuildingMeters;
