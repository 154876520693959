import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import moment from 'moment';

let last24hours = moment(Date.now()).subtract(1, 'd').toISOString();
let today = moment(Date.now()).subtract(0, 'd').toISOString();

const scan_api_url = process.env.REACT_APP_SCAN_API_URL;

export const channelsApi = createApi({
  reducerPath: 'channels',
  baseQuery: fetchBaseQuery({
    baseUrl: `${scan_api_url}/api`,
  }),
  keepUnusedDataFor: 1800000, // 0.5 hour
  tagTypes: ['Channels'],
  endpoints: (builder) => ({
    getChannels: builder.query({
      query: ({ project, building }) => `/channel/${project}/${building}`,
    }),
    getChannel: builder.query({
      query: ({ project, building, channelId }) => `/channel/${project}/${building}/${channelId}`,
    }),
    getChannelDataSummaryRangeMetered: builder.query({
      query: ({ project, building, channelId, period = '60', startDate = last24hours, endDate = today }) =>
        `/channel-data-summary-range/metered/${project}/${building}/${period}/${startDate}/${endDate}/${channelId}`,
      providesTags: (result, error, startDate) => [{ type: 'Channels', startDate }],
    }),
    getChannelDataSummaryRangeTarget: builder.query({
      query: ({ project, building, channelId, year = '2022' }) =>
        `/channel-data-summary-range/target/${project}/${building}/${channelId}/${year}`,
    }),
  }),
});

export const {
  useGetChannelsQuery,
  useGetChannelQuery,
  useGetChannelDataSummaryRangeMeteredQuery,
  useGetChannelDataSummaryRangeTargetQuery,
} = channelsApi;
