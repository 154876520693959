import React from 'react';
import { useUpdateMeterMutation } from '../../services/building';
import { Box, Button, Modal, Select, Text, Textarea, Textfield } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const MeterEdit = ({ meter }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateMeter, { isError, isLoading, isSuccess }] = useUpdateMeterMutation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    updateMeter({
      id: meter.id,
      name: data.name,
      type: data.type,
      channel: data.channel,
      description: data.description,
      scanBuildingName: data.scanBuildingName,
      unit: data.unit,
      meterType: data.meterType,
      dataType: data.dataType,
    });
  };

  return (
    <div>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {' '}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Text weight={'medium'} size="large">
              Edit {meter.name}
            </Text>
          </Modal.Header>
          <Modal.Content>
            <Text weight="medium">Do you want to edit this meter?</Text>
            <br />
            {/* {isLoading && <div>Loading...</div>}
            {isError && <div>Error updating meter</div>} */}
            <Textfield label="Name" required {...register('name', { required: true })} defaultValue={meter.name} />
            <Textarea rows={2} label="Description" {...register('description')} defaultValue={meter.description} />

            <Select
              label="Meter Type"
              required
              {...register('meterType', { required: true })}
              defaultValue={meter.meterType}
            >
              <option value="" selected disabled>
                Please select
              </option>
              <option value={'BMS'}>BMS</option>
              <option value={'IOT'}>IOT</option>
              {/* {building.zones.map((zone) => (
              <option value={zone.id}>{zone.name}</option>
            ))} */}
            </Select>
            <Textfield label="Data Type" {...register('dataType')} defaultValue={meter.dataType} />

            {/* <Select label="Type" {...register('type')} defaultValue={meter.type}>
              <option value="" selected disabled>
                Please select
              </option>
              <option value={'electricity'}>Electricity</option>
              <option value={'biomass'}>Biomass</option>
           
            </Select> */}
            <Textfield
              required
              label="Scan Channel ID"
              {...register('channel', { required: true })}
              defaultValue={meter.channel}
            />
            <Textfield
              required
              label="Scan Building for Channel ID"
              {...register('scanBuildingName', { required: true })}
              defaultValue={meter.scanBuildingName}
            />
            <Textfield required label="Data Unit" {...register('unit', { required: true })} defaultValue={meter.unit} />
            {isSuccess && (
              <>
                {' '}
                <Text color="green">Meter updated successfully</Text>
                <br />
              </>
            )}
          </Modal.Content>

          <Modal.Footer>
            <Box gap={8}>
              <Button color="primary" type="submit">
                Update
              </Button>
              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Box>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default MeterEdit;
