import { IdentificationBadge } from '@phosphor-icons/react';
import React from 'react';
import { useDeleteFloorMutation } from '../../services/building';
import { Button, Modal, Text, Box } from '@ies-ds/react-ui';

const FloorDelete = ({ id }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [deleteFloor, { isError, isLoading, isSuccess }] = useDeleteFloorMutation();

  return (
    <>
      <Button size="small" onClick={() => setIsOpen(true)}>
        Delete Floor
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Modal.Header>
          <Text weight={'medium'} size="large">
            Delete Floor
          </Text>
        </Modal.Header>
        <Modal.Content>
          <Text weight="medium">Are you sure you want to delete this floor?</Text>
          <br />
          <p>All floor and sensor data associated with the zone will be permanently deleted as well!</p>
        </Modal.Content>

        <Modal.Footer>
          <Box gap={8}>
            <Button color="warning" onClick={() => deleteFloor(id)}>
              Delete
            </Button>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FloorDelete;
