import React from 'react';
import './App.css';

import { PanelLv1, StyledDashboardContainer } from './dashboard/LayoutDashboard';
import { Box, Button, Logo, Text, ThemeProvider, dark } from '@ies-ds/react-ui';

import { loginRequest } from './authConfig';
import HCLOGOCOLOR from './assets/img/hc-logo-white.png';
import ERDFLOGO from './assets/img/ERDF-logo-English-light.png';

const Login = ({ instance }) => {
  return (
    <ThemeProvider theme={dark}>
      <StyledDashboardContainer>
        <Box justifyContent="center" style={{ height: '100%', width: '100%' }}>
          <Box
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            gap={30}
            style={{ margin: '30px 0' }}
          >
            <div style={{ height: '60px' }}></div>
            <PanelLv1 style={{ width: '400px' }}>
              <Box justifyContent="center" direction="column" alignItems="center" gap={24}>
                {/* <Logo logo="ies-light" width={60} height={60} /> */}
                <img src={HCLOGOCOLOR} alt="Highland Council Logo" width={200} />
                <Box justifyContent="center" direction="column" alignItems="center" gap={8}>
                  <Text color="lighter" align="center">
                    Welcome to the Highland Council Dashboard
                  </Text>{' '}
                </Box>
              </Box>
              <br />
              <Button
                style={{ width: '100%' }}
                id="login"
                color="primary"
                onClick={() => instance.loginRedirect(loginRequest)}
              >
                Enter Dashboard
              </Button>
            </PanelLv1>
            <img src={ERDFLOGO} alt="ERDF Logo" width={240} />
          </Box>
        </Box>
      </StyledDashboardContainer>
    </ThemeProvider>
  );
};

export default Login;
