import moment from 'moment';
import { getLastNotNullValue, getLastNotNullorZeroValue } from '../config/app';
import { Warning, WarningCircle } from '@phosphor-icons/react';
import { useGetChannelDataSummaryRangeMeteredQuery } from '../../services/scan';
import { Loader } from '@ies-ds/react-ui';

let start_date = moment().subtract(0, 'years').subtract(3, 'days').toISOString().substring(0, 10);
let today = moment(Date.now()).subtract(0, 'd').toISOString().substring(0, 10);

export function useShowResultDataQuery(
  channelID = '0000000',
  period = '1440',
  fixed = 0,
  startDate = start_date,
  project = 'HighlandCouncil',
  building = 'CharlesKennedyBuilding',
  endDate = today,
  multiplyer = 1
) {
  const {
    isLoading: load,
    isFetching: fetch,
    data,
    error,
  } = useGetChannelDataSummaryRangeMeteredQuery(
    {
      project: project,
      building: building,
      channelId: channelID,
      period: period,
      startDate: startDate,
      endDate: endDate,
    },
    {
      //refetchOnMountOrArgChange: true,
      //pollingInterval: 3600
    }
  );

  if (load || fetch) {
    return <Loader type="dots" size="xsmall" />;
  }
  if (error) {
    return <Warning color="#fff" />;
  }
  if (data.Error) {
    return <WarningCircle size={20} color="#e52a58" weight="bold" />;
  }

  console.log('data', data);

  return fetch || load || null || typeof data.Mean.length === undefined ? (
    <Loader type="dots" size="xsmall" />
  ) : // : data.Mean[data.Mean.length - 1] == null
  // ? '--'
  isNaN(parseInt(getLastNotNullorZeroValue(data), 10)) ? (
    'N/A'
  ) : (
    //: parseInt(getLastNotNullValue(data), 10);
    (getLastNotNullorZeroValue(data) * multiplyer)
      .toFixed(fixed)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  );
}

export function useGetWellness(min, max, channelID, period = '1440', startDate = start_date) {
  let currentValue = '--';

  const {
    isLoading: loading,
    isFetching: fetching,
    data,
    error,
  } = useGetChannelDataSummaryRangeMeteredQuery(
    {
      project: 'HighlandCouncil',
      building: 'CharlesKennedyBuilding',
      channelId: channelID,
      period: period,
      startDate: startDate,
    },
    {
      //refetchOnMountOrArgChange: true,
      //pollingInterval: 3600
    }
  );

  const level =
    fetching || loading || typeof data.Mean.length == undefined ? (currentValue = '--') : getLastNotNullValue(data);

  if (level === '--') {
    currentValue = '--';
  } else if (level < min) {
    currentValue = 'Low';
  } else if (level > max) {
    currentValue = 'High';
  } else {
    currentValue = 'Good';
  }

  return currentValue;
}

export function toFixedArrayValues(array, decimals = 0) {
  if (array === null) {
    return null;
  } else {
    return array.map((value) => {
      return value === null ? null : Number(value.toFixed(decimals));
    });
  }
}
