import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { msalConfig } from '../authConfig';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';

import config from '../config';

//const buildings_api = process.env.REACT_APP_BUILDING_API_URL;
const buildings_api = config.apiRoot;

const msalInstance = new PublicClientApplication(msalConfig);
// const account = msalInstance.getAllAccounts()[0];

// get token from local storage
//const token = localStorage.getItem('accessTokenIESConnect');

// export const loginRequest = {
//   scopes: [`${process.env.REACT_APP_CLIENT_SCOPE}`], // API scopes
//   forceRefresh: false, // set to true to skip cache
//   account: account,
// };

export const buildingApi = createApi({
  reducerPath: 'buildingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${buildings_api}/building-api`,
    //credentials: 'include',
    prepareHeaders: async (headers) => {
      try {
        const account = msalInstance.getAllAccounts()[0];
        const token = await msalInstance.acquireTokenSilent({
          //scopes: [`${process.env.REACT_APP_CLIENT_SCOPE}`], // API scopes
          scopes: [`${config.apiScope}`], // API scopes
          forceRefresh: false, // set to true to skip cache
          account: account,
        });
        if (token) {
          headers.set('authorization', `Bearer ${token.accessToken}`);
          headers.set('x-requested-with', 'XMLHttpRequest');
          headers.set('Accept', 'application/json');
          let currentTime = new Date();
          localStorage.setItem('accessTokenIESConnectTime', currentTime);
          //localStorage.setItem('accessTokenIESConnect', token.accessToken);
        }
        return headers;
      } catch (e) {
        window.location.href = '/login';
        console.log(e);
      }
    },
    // prepareHeaders: async (headers) => {
    //   const token = await msalInstance.acquireTokenSilent(loginRequest);
    //   // .then((accessTokenResponse) => {
    //   //   // Acquire token silent success
    //   //   let accessToken = accessTokenResponse.accessToken;
    //   //   //alert(accessToken);
    //   //   /// set local storage
    //   //   localStorage.setItem('accessTokenIESConnect', accessToken);
    //   //   /// set local storage current time
    //   //   let currentTime = new Date();
    //   //   localStorage.setItem('accessTokenIESConnectTime', currentTime);
    //   //   // Call your API with token
    //   //   // callApi(accessToken).then((response) => {
    //   //   //   setApiData(response);
    //   //   // });
    //   //   //console.log('accessTokenResponse1', accessTokenResponse);
    //   //   //console.log('accessTokenResponse', accessToken);
    //   //   // axios
    //   //   //   .get('http://localhost:3003/building-api/authcheck?', {
    //   //   //     headers: {
    //   //   //       Authorization: `Bearer ${accessToken}`,
    //   //   //     },
    //   //   //   })
    //   //   //   .then((response) => {
    //   //   //     setData(response.data); // Assuming the API returns JSON data
    //   //   //   })
    //   //   //   .catch((error) => {
    //   //   //     console.error('Error fetching data:', error);
    //   //   //   });
    //   // })
    //   // .catch((error) => {
    //   //   alert('errror');
    //   //   // if (error instanceof InteractionRequiredAuthError) {
    //   //   //   msalInstance.acquireTokenRedirect(loginRequest);
    //   //   // }
    //   //   console.log(error);
    //   // });
    //   localStorage.setItem('accessTokenIESConnect', token.accessToken);
    //   localStorage.setItem('accessTokenIESConnectTime', new Date());
    //   headers.set('authorization', `Bearer ${token.accessToken}`);
    //   headers.set('x-requested-with', 'XMLHttpRequest');
    //   headers.set('Accept', 'application/json');
    //   return headers;
    // },
  }),
  //  keepUnusedDataFor: 1800000, // 0.5 hour
  tagTypes: ['Building', 'Floor', 'Zone', 'Sensor', 'BmsSensor', 'SensorType', 'Meter', 'Utility'],
  endpoints: (builder) => ({
    getAuthInfo: builder.query({
      query: () => '/authcheck',
    }),

    // Building
    getAllBuildings: builder.query({
      query: () => '/buildings',
      providesTags: ['Building'],
    }),
    getBuildingById: builder.query({
      query: (id) => `/buildings/${id}`,
      providesTags: ['Building'],
    }),
    postCreateBuilding: builder.mutation({
      query: (body) => ({
        url: '/buildings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Building'],
    }),
    updateBuilding: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/buildings/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['Building'],
    }),
    deleteBuilding: builder.mutation({
      query: (id) => ({
        url: `/buildings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Building'],
    }),

    // Floor
    getAllFloors: builder.query({
      query: () => ({
        url: `/floors`,
      }),
      providesTags: ['Floor'],
    }),
    createFloor: builder.mutation({
      query: (body) => ({
        url: `/floors`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Floor', 'Building'],
    }),
    getFloorById: builder.query({
      query: (id) => ({
        url: `/floors/${id}`,
      }),
      providesTags: ['Floor'],
    }),
    getFloorsByBuildingId: builder.query({
      query: (id) => ({
        url: `/floors/building/${id}`,
      }),
      providesTags: ['Floor'],
    }),

    deleteFloor: builder.mutation({
      query: (id) => ({
        url: `/floors/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Floor', 'Building'],
    }),
    updateFloor: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/floors/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['Floor'],
    }),

    // Zone
    getAllZones: builder.query({
      query: () => ({
        url: `/zones`,
      }),
      providesTags: ['Zone'],
    }),
    getZoneById: builder.query({
      query: (id) => ({
        url: `/zones/${id}`,
      }),
      providesTags: ['Zone'],
    }),
    getZonesByBuildingId: builder.query({
      query: (id) => ({
        url: `/zones/building/${id}`,
      }),
      providesTags: ['Zone'],
    }),
    createZone: builder.mutation({
      query: (body) => ({
        url: `/zones`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Zone', 'Floor', 'Sensor'],
    }),
    deleteZone: builder.mutation({
      query: (id) => ({
        url: `/zones/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Zone', 'Floor'],
    }),
    updateZone: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/zones/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['Zone', 'Floor'],
    }),

    // Sensor
    getAllSensor: builder.query({
      query: () => ({
        url: `/sensors`,
      }),
      providesTags: ['Sensor'],
    }),
    getSensorById: builder.query({
      query: (id) => ({
        url: `/sensors/${id}`,
      }),
      providesTags: ['Sensor'],
    }),
    getSensorByBuildingId: builder.query({
      query: (id) => ({
        url: `/sensors/building/${id}`,
      }),
      providesTags: ['Sensor'],
    }),
    createSensor: builder.mutation({
      query: (body) => ({
        url: `/sensors`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Sensor', 'Floor', 'Zone'],
    }),
    deleteSensor: builder.mutation({
      query: (id) => ({
        url: `/sensors/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Sensor', 'Floor'],
    }),
    updateSensor: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/sensors/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['Sensor', 'Floor', 'Zone'],
    }),

    // BMS
    getAllBmsSensors: builder.query({
      query: () => ({
        url: `/bmsSensor`,
      }),
      providesTags: ['BmsSensor'],
    }),
    getBmsSensorById: builder.query({
      query: (id) => ({
        url: `/bmsSensor/${id}`,
      }),
      providesTags: ['BmsSensor'],
    }),
    getBmsSensorsByBuildingId: builder.query({
      query: (id) => ({
        url: `/bmsSensor/building/${id}`,
      }),
      providesTags: ['BmsSensor'],
    }),
    createBmsSensor: builder.mutation({
      query: (body) => ({
        url: '/bmsSensor',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BmsSensor'],
    }),
    deleteBmsSensor: builder.mutation({
      query: (id) => ({
        url: `/bmsSensor/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BmsSensor'],
    }),
    updateBmsSensor: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/bmsSensor/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['BmsSensor'],
    }),

    // SensorType
    getAllSensorType: builder.query({
      query: () => ({
        url: `/sensorTypes`,
      }),
      providesTags: ['SensorType'],
    }),
    getSensorTypeById: builder.query({
      query: (id) => ({
        url: `/sensorTypes/${id}`,
      }),
      providesTags: ['SensorType'],
    }),
    createSensorType: builder.mutation({
      query: (body) => ({
        url: `/sensorTypes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SensorType'],
    }),
    deleteSensorType: builder.mutation({
      query: (id) => ({
        url: `/sensorTypes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SensorType'],
    }),
    updateSensorType: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/sensorTypes/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['SensorType'],
    }),
    // Utilites
    getUtilitiesByBuildingId: builder.query({
      query: (id) => ({
        url: `/utilities/building/${id}`,
        method: 'GET',
      }),
      providesTags: ['Utility'],
    }),
    createUtilities: builder.mutation({
      query: (body) => ({
        url: `/utilities`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Utility'],
    }),
    deleteUtilities: builder.mutation({
      query: (id) => ({
        url: `/utilities/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Utility'],
    }),
    updateUtilities: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/utilities/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['Utility'],
    }),
    // Meter
    getAllMeter: builder.query({
      query: () => ({
        url: `/meters`,
        method: 'GET',
      }),
      providesTags: ['Meter'],
    }),
    getMeterById: builder.query({
      query: (id) => ({
        url: `/meters/${id}`,
        method: 'GET',
      }),
      providesTags: ['Meter'],
    }),
    getMeterByBuildingId: builder.query({
      query: (id) => ({
        url: `/meters/building/${id}`,
        method: 'GET',
      }),
      providesTags: ['Meter'],
    }),
    createMeter: builder.mutation({
      query: (body) => ({
        url: `/meters`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Meter'],
    }),
    deleteMeter: builder.mutation({
      query: (id) => ({
        url: `/meters/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Meter'],
    }),
    updateMeter: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/meters/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['Meter'],
    }),

    // Favorites
    createFavorite: builder.mutation({
      query: (body) => ({
        url: `/favorites`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Favorite', 'Building'],
    }),
    deleteFavorite: builder.mutation({
      query: (id) => ({
        url: `/favorites/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Favorite', 'Building'],
    }),
    updateFavorite: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/favorites/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['Favorite', 'Building'],
    }),

    // Authtest
    getAuthtest: builder.query({
      query: () => ({
        url: `/authtest`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  // Auth
  useGetAuthInfoQuery,
  // Building
  useGetAllBuildingsQuery,
  useGetBuildingByIdQuery,
  usePostCreateBuildingMutation,
  useDeleteBuildingMutation,
  useUpdateBuildingMutation,
  // Floors
  useGetAllFloorsQuery,
  useGetFloorByIdQuery,
  useGetFloorsByBuildingIdQuery,
  useCreateFloorMutation,
  useDeleteFloorMutation,
  useUpdateFloorMutation,
  // Zones
  useGetAllZonesQuery,
  useGetZoneByIdQuery,
  useGetZonesByBuildingIdQuery,
  useCreateZoneMutation,
  useDeleteZoneMutation,
  useUpdateZoneMutation,
  // Sensor
  useGetAllSensorQuery,
  useGetSensorByBuildingIdQuery,
  useGetSensorByIdQuery,
  useCreateSensorMutation,
  useDeleteSensorMutation,
  useUpdateSensorMutation,

  // BMS
  useGetAllBmsSensorsQuery,
  useGetBmsSensorByIdQuery,
  useGetBmsSensorsByBuildingIdQuery,
  useCreateBmsSensorMutation,
  useDeleteBmsSensorMutation,
  useUpdateBmsSensorMutation,

  // SensorType

  useGetAllSensorTypeQuery,
  useGetSensorTypeByIdQuery,
  useCreateSensorTypeMutation,
  useDeleteSensorTypeMutation,
  useUpdateSensorTypeMutation,

  // Utilites
  useGetUtilitiesByBuildingIdQuery,
  useCreateUtilitiesMutation,
  useDeleteUtilitiesMutation,
  useUpdateUtilitiesMutation,

  // Meter
  useGetAllMeterQuery,
  useGetMeterByIdQuery,
  useGetMeterByBuildingIdQuery,
  useCreateMeterMutation,
  useDeleteMeterMutation,
  useUpdateMeterMutation,

  // Favorites
  useCreateFavoriteMutation,
  useDeleteFavoriteMutation,
  useUpdateFavoriteMutation,

  // Authtest
  useGetAuthtestQuery,
} = buildingApi;
