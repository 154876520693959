import React from 'react';
import { useUpdateBuildingMutation } from '../../services/building';
import { Box, Button, Grid, Modal, Switch, Text, Textarea, Textfield, Form } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const BuildingEdit = ({ building, id }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateBuilding, { isError, isLoading, isSuccess }] = useUpdateBuildingMutation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    updateBuilding({
      id: building.id,
      name: data.name,
      location: data.location,
      description: data.description,
      latitude: data.latitude,
      longitude: data.longitude,
      scanBuildingName: data.scanBuildingName,
      windowSensorCount: data.windowSensorCount,
      active: data.active,
    });
  };

  const handleClose = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  return (
    <div>
      <Button color="default-primary" size="xsmall" onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {' '}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Text weight={'medium'} size="large">
              Edit {building.name}
            </Text>
          </Modal.Header>
          <Modal.Content>
            <Text weight="medium">Do you want to edit this building?</Text>
            <br />

            {/* {isLoading && <div>Loading...</div>}
            {isError && <div>Error updating building</div>} */}

            <Textfield label="Name" {...register('name', { required: true })} defaultValue={building.name} />
            <Textfield
              label="Location"
              {...register('location', { required: true })}
              defaultValue={building.location}
            />
            <Textarea label="Description" rows={3} {...register('description')} defaultValue={building.description} />

            <Grid>
              <Grid.Col sm={6}>
                <Textfield label="Latitude" {...register('latitude')} defaultValue={building.latitude} />
              </Grid.Col>
              <Grid.Col sm={6}>
                <Textfield label="Longitude" {...register('longitude')} defaultValue={building.longitude} />
              </Grid.Col>
            </Grid>

            <Textfield
              label="Scan Building Name"
              {...register('scanBuildingName')}
              defaultValue={building.scanBuildingName}
            />

            <Textfield
              label="Window Sensor Count"
              {...register('windowSensorCount')}
              defaultValue={building.windowSensorCount}
              style={{ width: '30%' }}
            />
            <Form.Label>Active?</Form.Label>
            <Switch {...register('active')} defaultChecked={building.active} />
            <br />
            {isSuccess && (
              <>
                <Text color="green">Building updated successfully</Text>
                <br />
              </>
            )}
          </Modal.Content>

          <Modal.Footer>
            <Box gap={8}>
              <Button color="primary" type="submit">
                Update
              </Button>
              <Button onClick={handleClose}>Close</Button>
            </Box>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default BuildingEdit;
