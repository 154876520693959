import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetMeterByBuildingIdQuery } from '../../services/building';
import { Box, Container, Loader, Table, Text } from '@ies-ds/react-ui';
import { useGetBuildingByIdQuery } from '../../services/building';
import MeterCreate from './MeterCreate';
import MeterDelete from './MeterDelete';
import { CaretLeft } from '@phosphor-icons/react';
import MeterEdit from './MeterEdit';

const MeterListByBuilding = () => {
  const { id } = useParams();

  const { isLoading, data: meters, error } = useGetMeterByBuildingIdQuery(id);

  return (
    <div>
      <Box justifyContent="space-between" alignItems="center">
        <Text> Meter List: </Text>
        <br />
        <MeterCreate id={id} />
      </Box>
      <br />
      <Container shadow="low">
        <Table>
          <tr>
            <th style={{ width: '20px' }}>ID</th>
            <th>Name</th>
            <th>Meter Type</th>

            <th>Data Type</th>
            <th>Unit</th>
            {/* <th>Type *</th> */}
            <th>Scan Building Name</th>
            <th>Scan Channel</th>
            <th> Description</th>
            <th></th>
          </tr>
          {isLoading ? (
            'Loader'
          ) : meters.length === 0 ? (
            <tr>
              <td colSpan={3}>
                <Text>No meters</Text>
              </td>
            </tr>
          ) : (
            meters.map((meter) => (
              <>
                <tr style={{ margin: '8px 0' }}>
                  <td>{meter.id}</td>
                  <td>{meter.name}</td>
                  <td>{meter.meterType}</td>
                  <td>{meter.dataType}</td>
                  <td>{meter.unit}</td>
                  {/* <td>{meter.type}</td> */}
                  <td>{meter.scanBuildingName}</td>
                  <td>{meter.channel}</td>
                  <td>{meter.description}</td>
                  <td>
                    <Box justifyContent="flex-end" gap={6}>
                      <MeterEdit meter={meter} />
                      <MeterDelete id={meter.id} />
                    </Box>
                  </td>
                </tr>
              </>
            ))
          )}
        </Table>
      </Container>
    </div>
  );
};

export default MeterListByBuilding;
