import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import moment from 'moment';

import styled from 'styled-components';
import { Box } from '@ies-ds/react-ui';

const StyledButton = styled.div`
  display: inline-block;
  border: 1px solid rgba(200, 200, 200, 0.5);
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
    background: #fde500;
    color: #000;
  }
`;

export default function ApexChart({
  id = 'defaultID',
  height = 260,
  units = 'kWh',
  fixed = 0,
  channel1 = [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
  channel2 = [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
  channel3 = [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
  colors = ['#964B00', '#FFA500', '#FFFF00', '#E91E63', '#FF9800'],
  actualData = [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
  targetData = [35, 29, 35, 32, 35, 41, 40, 35, 36, 35, 35, 35],
  timestamps = [
    '01 Jan',
    '02 Jan',
    '03 Jan',
    '04 Jan',
    '05 Jan',
    '06 Jan',
    '07 Jan',
    '08 Jan',
    '09 Jan',
    '10 Jan',
    '11 Jan',
    '12 Jan',
  ],
}) {
  console.log('cccc', channel3);
  console.log('cccc', colors);

  const [chartData, setChartData] = useState({
    series: [
      {
        name: channel1.name,
        data: channel1.data,
      },
      ...(typeof channel2.name !== 'undefined'
        ? [
            {
              name: channel2.name,
              data: channel2.data,
            },
          ]
        : []),

      ...(typeof channel3.name !== 'undefined'
        ? [
            {
              name: channel3.name,
              data: channel3.data,
            },
          ]
        : []),
    ],
    options: {
      colors: colors,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      chart: {
        id: id,

        height: 100,
        type: 'line',
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2],
        curve: 'straight',
        dashArray: [0, 0, 0],
      },
      title: {
        text: '',
        align: 'left',
        style: {
          color: '#999',
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          cssClass: 'apexcharts-xaxis-title',
        },
      },
      legend: {
        show: true,
        // tooltipHoverFormatter: function (val, opts) {
        //   return (
        //     val +
        //     ' - ' +
        //     opts.w.globals.series[opts.seriesIndex][
        //       opts.dataPointIndex
        //     ] +
        //     ''
        //   );
        // },
        labels: {
          colors: '#aaa',
          useSeriesColors: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#bbb',
          },
          formatter: function (value) {
            return value == null ? '0' : value.toFixed(value === 0 ? 0 : value < 1 ? 2 : 0) + ' ' + units;
          },
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        // min: '2022-08-05T00:00:00Z',
        // max: '2022-09-15T00:00:00Z',
        zoomX: {},
        type: 'datetime',
        tickAmount: 5,
        labels: {
          datetimeUTC: false,
          style: {
            colors: '#aaa',
          },
        },
        axisBorder: {
          show: false,
          color: '#333',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#999',
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        categories: timestamps,
      },
      tooltip: {
        theme: 'dark',
        x: {
          format: 'dd MMM yyyy, h:mm',
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return moment(value).zone('+0100').format('ddd D MMM yyyy, H:mm');
          },
        },
        y: [
          {
            title: {
              formatter: function (val) {
                return val + ' (' + units + ')';
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + ' (' + units + ')';
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        show: true,
        borderColor: 'rgba(255,255,255,0.1)',
        strokeDashArray: [4, 5],
      },
    },
  });

  const zoomFunction = (timeline) => {
    const now = new Date(moment().add(45, 'm')).getTime();
    const today = new Date(moment().subtract(1, 'd')).getTime();
    const this_week = new Date(moment().subtract(8, 'd')).getTime();
    const two_week = new Date(moment().subtract(14, 'd')).getTime();
    const one_month = new Date(moment().subtract(1, 'M')).getTime();
    const three_month = new Date(moment().subtract(3, 'M')).getTime();
    const one_year = new Date(moment().subtract(1, 'y')).getTime();
    const ytd = new Date(moment().subtract(8, 'd')).getTime();
    const all = new Date(timestamps[0]).getTime();

    switch (timeline) {
      case 'today':
        ApexCharts.exec(id, 'zoomX', today, now);
        break;
      case 'this_week':
        ApexCharts.exec(id, 'zoomX', this_week, now);
        break;
      case 'two_week':
        ApexCharts.exec(id, 'zoomX', two_week, now);
        break;
      case 'one_month':
        ApexCharts.exec(id, 'zoomX', one_month, now);
        break;
      case 'three_month':
        ApexCharts.exec(id, 'zoomX', three_month, now);
        break;
      case 'one_year':
        ApexCharts.exec(id, 'zoomX', one_year, now);
        break;
      case 'ytd':
        ApexCharts.exec(id, 'zoomX', ytd, now);
        break;
      case 'all':
        ApexCharts.exec(id, 'zoomX', all, today);
        break;
      default:
    }
    // Chart.exec(
    //   id,
    //   'zoomX',
    //   new Date('2022-09-01').getTime(),
    //   new Date().getTime()
    // );
    // console.log(timestamps[0]);
  };

  React.useEffect(() => {
    zoomFunction('all');
  });

  return (
    <>
      {/* <Box gap={4} style={{ marginBottom: '8px' }} justifyContent="flex-end">
        <StyledButton id="zoom" onClick={() => zoomFunction('all')}>
          All
        </StyledButton>
        <StyledButton id="zoom" onClick={() => zoomFunction('one_year')}>
          1 Year
        </StyledButton>{' '}
        <StyledButton id="zoom" onClick={() => zoomFunction('three_month')}>
          3 Month
        </StyledButton>
        <StyledButton id="zoom" onClick={() => zoomFunction('one_month')}>
          1 Month
        </StyledButton>
        <StyledButton id="zoom" onClick={() => zoomFunction('this_week')}>
          7 Days
        </StyledButton>
        <StyledButton id="zoom" onClick={() => zoomFunction('today')}>
          Current
        </StyledButton>
      </Box> */}
      <Chart options={chartData.options} series={chartData.series} type="area" height={height} />
    </>
  );
}
