import React, { useEffect } from 'react';
import { useUpdateZoneMutation } from '../../services/building';
import { Box, Button, Modal, Select, Text, Textfield } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const ZoneEdit = ({ zone, building, id = null, floorId }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateZone, { isError, isLoading, isSuccess, reset }] = useUpdateZoneMutation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    updateZone({ id: zone.id, name: data.name, floorId: id || parseInt(data.floorId) });
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  return (
    <div>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {' '}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Text weight={'medium'} size="large">
              Edit {zone.name}
            </Text>
          </Modal.Header>
          <Modal.Content>
            <Text weight="medium">Do you want to edit this room?</Text>
            <br />

            {/* {isLoading && <div>Loading...</div>}
            {isError && <div>Error updating zone</div>} */}

            <Textfield label="Name" {...register('name', { required: true })} defaultValue={zone.name} />
            {/* <Textfield label="Location" {...register('location', { required: true })} defaultValue={zone.location} /> */}

            {!id && (
              <Select label="Choose Floor" {...register('floorId')}>
                <option value="" selected disabled hidden>
                  Please select
                </option>

                {building.floors.map((floor) =>
                  floor.id === zone.floorId ? (
                    <Select.Option value={floor.id} selected>
                      {floor.name}
                    </Select.Option>
                  ) : (
                    <Select.Option value={floor.id}>{floor.name}</Select.Option>
                  )
                )}
              </Select>
            )}

            {isSuccess && (
              <>
                {' '}
                <Text color="green">Zone updated successfully</Text>
                <br />
              </>
            )}
          </Modal.Content>

          <Modal.Footer>
            <Box gap={8}>
              <Button color="primary" type="submit">
                Update
              </Button>
              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Box>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ZoneEdit;
