import React from 'react';
import { useGetAuthtestQuery } from '../../services/building';
import { CheckCircle, WarningCircle } from '@phosphor-icons/react';
import { Box, Text } from '@ies-ds/react-ui';
import config from '../../config';

const Authtest = () => {
  const { isLoading, error, data, isError } = useGetAuthtestQuery();

  if (isLoading) return 'Loading...';

  return (
    <div style={{ padding: '16px' }}>
      <Text size="xlarge" weight="bold">
        Authtest: is SUPERADMIN?
      </Text>
      <br />
      {isError ? (
        <Box alignItems="center">
          <WarningCircle color="#eb2664" size={35} />{' '}
          <Text color="light" size="xlarge">
            {error.data.error}
          </Text>
        </Box>
      ) : (
        <>
          <Box alignItems="center">
            <CheckCircle color="#1ed357" size={35} />{' '}
            <Text color="light" size="xlarge">
              Is Authorized
            </Text>
          </Box>
          <br />
          <Text color="light">
            {data?.message}
            REACT_APP_CLIENT_ID: {config.clientId}
            <br />
            REACT_APP_CLIENT_SCOPE: {config.apiScope}
            <br />
            REACT_APP_BUILDING_API_URL: {config.apiRoot}
          </Text>
        </>
      )}
    </div>
  );
};

export default Authtest;
