import React, { useEffect, useState } from 'react';
import { useCreateFavoriteMutation, useDeleteFavoriteMutation, useGetAllBuildingsQuery } from '../../services/building';
import { Box, Button, Loader, Text, Textfield, Tooltip } from '@ies-ds/react-ui';
import { level1, level2, level3, primary } from '../ui/colors';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import BuildingDetail from './BuildingDetail';
import styled from 'styled-components';
import ScrollBox from '../ui/ScrollBox';
import {
  Buildings,
  Heart,
  MagnifyingGlass,
  PushPin,
  PushPinSimpleSlash,
  PushPinSlash,
  Star,
  X,
} from '@phosphor-icons/react';
import BuildingLocations from '../Map/BuildingLocations';
import Tab from '../ui/Tab';
import BuildingCards from './BuildingCards';

const StyledTooltip = styled(Tooltip)`

 
  div[class*='StyledTooltipLabel'] {
    background: #000;
    padding: 4px 8px;
    color: #fff;
    ::after {
      border-color: #000 transparent;
  }
`;

const StyledTextfield = styled(Textfield)`
  padding: 8px 12px;
  font-size: 14px;
  background: rgb(33 45 64);
`;

const StyledBuildingItem = styled(NavLink)`
  padding: 8px 24px;
  display: block;
  text-decoration: none;
  .favBtn {
    color: #485976;
    .btnAdd {
      display: none;
    }
  }
  &:hover {
    background: ${level3};
    text-decoration: none;
    .favBtn {
      display: block;
      &:hover {
        color: #3b82f6;
      }
    }
    .btnAdd {
      display: block;
      &:hover {
        color: #e15b91;
      }
    }
  }
  &.active {
    background: #262e3e;
    border-right: 2px solid ${primary};
  }
`;

const BuildingListToggle = () => {
  const { isLoading, data: buildings, error } = useGetAllBuildingsQuery();

  const [inputValue, setInputValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filtered = buildings
      ?.filter((building) => building.isFavorite === false)
      ?.filter((building) => building.active)
      .filter(
        (data) =>
          data.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          data.location.toLowerCase().includes(inputValue.toLowerCase())
      );
    setFilteredData(filtered);
  }, [inputValue, buildings]);

  return (
    <>
      <Box direction="row" alignItems="stretch" flex={1} style={{ overflow: 'hidden' }}>
        <Routes>
          {/* <Route
            path="/"
            element={
              <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                {isLoading ? <div>Loading...</div> : <BuildingLocations />}
              </div>
            }
          /> */}
          <Route
            path="/"
            element={
              <ScrollBox style={{ position: 'relative', width: '100%', height: '100%' }}>
                {isLoading ? (
                  <Box
                    style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Loader size="small" type="dots" />
                  </Box>
                ) : (
                  <BuildingCards buildings={buildings} />
                )}
              </ScrollBox>
            }
          />
          <Route
            path=":buildingId/*"
            element={
              <>
                <Box style={{ width: '320px' }}>
                  {isLoading ? (
                    <Box
                      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Loader size="small" type="dots" />
                    </Box>
                  ) : (
                    <Box
                      direction="column"
                      flex={1}
                      style={{
                        background: level2,
                        width: '280px',

                        padding: ' 0px',

                        // border: '1px solid red',
                      }}
                    >
                      <Box style={{ padding: '24px 24px 0 24px' }} gap={6}>
                        <Buildings weight="bold" size={22} color="#64748b" />
                        <Text size="xlarge" color="light">
                          Buildings
                          {/* ({filteredData?.length || buildings?.length}) */}
                        </Text>
                      </Box>
                      {buildings.filter((building) => building.isFavorite).length > 0 && (
                        <Text
                          size="small"
                          color="lightest"
                          style={{ padding: '0 24px', marginTop: '12px', marginBottom: '8px' }}
                        >
                          Favorites
                        </Text>
                      )}

                      {
                        /// Filter the building by isFavorite = true and return the StyledBuildingItem component
                        buildings

                          .filter((building) => building.isFavorite)
                          .filter((building) => building.active)
                          .sort((building) => building.favorite.id)
                          .map((building) => (
                            <BuildingItem key={building.id} building={building} />
                          ))
                      }

                      <br />

                      <div style={{ padding: ' 0 24px 0 24px' }}>
                        <StyledTextfield
                          placeholder={'Search Buildings...'}
                          onChange={(e) => setInputValue(e.target.value)}
                          value={inputValue}
                        />
                        {error && <div>Something went wrong...</div>}
                      </div>
                      <ScrollBox>
                        {filteredData < 1 ? (
                          <Text color="lightest" style={{ padding: ' 0 24px 0 24px' }}>
                            0 results
                          </Text>
                        ) : (
                          filteredData?.map((building) => <BuildingItem key={building.id} building={building} />)
                        )}
                      </ScrollBox>
                    </Box>
                  )}
                </Box>
                <BuildingDetail />
              </>
            }
          />
          <Route
            path="*"
            element={
              <Box flex={1} alignItems="center" justifyContent="center" direction="column" gap={8}>
                <MagnifyingGlass size={56} color="#64748b" />
                <Text size="large" color="lightest">
                  Select a building...
                </Text>
              </Box>
            }
          />
        </Routes>
      </Box>
    </>
  );
};

export default BuildingListToggle;

const BuildingItem = ({ building, ...props }) => {
  return (
    <StyledBuildingItem to={`/buildings/${building.id}`} {...props}>
      <Box direction="row" gap={8} alignItems="flex-start">
        <Box
          style={{ background: '#3b82f6', width: '24px', height: '24px', borderRadius: '3px' }}
          alignItems="center"
          justifyContent="center"
        >
          <Text color="dark" weight="medium" size="xsmall" transform="uppercase">
            {building.name.slice(0, 2)}
          </Text>
        </Box>
        <Box direction="column" gap={0} style={{ flex: '1' }}>
          <Box direction="row" gap={8} alignItems="center" justifyContent="space-between">
            {/* <Buildings size={18} color="#3b82f6" /> */}
            <Text style={{ color: '#cbd5e1' }}>{building.name}</Text>

            <ToggleFavorite className="favBtn" building={building} />
          </Box>
          <Text color="light"> </Text>
          <Text color="lighter" size="xsmall">
            {building.location}
            {/* | Floors: {building.floors.length} */}
          </Text>
        </Box>
      </Box>
    </StyledBuildingItem>
  );
};

const ToggleFavorite = ({ building, ...props }) => {
  const [isFavorite, setIsFavorite] = useState(building.isFavorite);

  const [createFavorite, { isLoading: isCreating }] = useCreateFavoriteMutation();
  const [deleteFavorite, { isLoading: isDeleting }] = useDeleteFavoriteMutation();

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const setFavorite = async () => {
    await createFavorite({
      buildingId: building.id,
      entity_type: 'building',
      entity_id: building.id,
    });
  };

  const unsetFavorite = async () => {
    await deleteFavorite(building.favorite.id);
  };

  if (isCreating || isDeleting) return <Loader type="dots" size="xsmall" />;

  return (
    <span {...props}>
      {isFavorite ? (
        // <PushPinSlash
        //   className="btnRemove"
        //   weight="bold"
        //   onClick={(e) => {
        //     e.preventDefault();
        //     unsetFavorite();
        //   }}
        // />
        <StyledTooltip content="Remove from Favorites" position="left">
          <X
            size={16}
            onClick={(e) => {
              e.preventDefault();
              unsetFavorite();
            }}
          />
        </StyledTooltip>
      ) : (
        <StyledTooltip content="Add to Favorites" position="left">
          <Heart
            size={18}
            weight="duotone"
            className="btnAdd"
            onClick={(e) => {
              e.preventDefault();
              setFavorite();
            }}
          />
        </StyledTooltip>
      )}
    </span>
  );
};
