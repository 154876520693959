import { Box, Grid, Tab, Table, Text, Textfield, Tooltip } from '@ies-ds/react-ui';
import React, { useEffect, useState } from 'react';
import Surface from '../ui/Surface';
import { SurfaceColour2 } from '../config/app';
import { Link } from 'react-router-dom';
import { ArrowRight, Heart, Info, ListBullets, MagnifyingGlass, MapTrifold, SquaresFour } from '@phosphor-icons/react';
import BuildingLocations from '../Map/BuildingLocations';
import ScrollBox from '../ui/ScrollBox';
import styled from 'styled-components';
import ToggleFavorite from './ToggleFavorite';
import { level1, level2, level3, primary } from '../ui/colors';

const StyledTable = styled(Table)`
  td {
    padding: 8px 12px;
  }
`;

const StyledTextfield = styled(Textfield)`
  padding: 12px 12px;
  font-size: 14px;
  background: rgb(33 45 64);
  margin-bottom: -10px;
`;

const StyledBuildingCard = styled.div`
  border-radius: 6px;
  height: 100%;
  display: block;
  text-decoration: none;
  .favBtn {
    color: #485976;
    .btnAdd {
      display: none;
    }
  }
  .actions {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover {
    background: ${level2};
    text-decoration: none;
    .favBtn {
      display: block;
      cursor: pointer;
      &:hover {
        color: #3b82f6;
      }
    }
    .btnAdd {
      cursor: pointer;
      display: block;
      &:hover {
        color: #e15b91;
      }
    }
    .actions {
      opacity: 1;
    }
  }
  &.active {
    background: #262e3e;
    border-right: 2px solid ${primary};
  }
`;

const StyledTooltip = styled(Tooltip)`
  div[class*='StyledTooltipLabel'] {
    background: #000;
    color: #fff;
    ::after {
      border-color: #000 transparent;
  }
`;

const BuildingCards = ({ buildings }) => {
  const [view, setView] = React.useState('grid');

  return (
    <div style={{ padding: '32px ' }}>
      <Grid centered>
        <Grid.Col sm={12}>
          <Box direction="row" justifyContent="space-between" gap={12} alignItems="center">
            <Text color="dark" size="xlarge">
              Building Portfolio
            </Text>{' '}
            <Box gap={8}>
              <SquaresFour
                size={24}
                color={view === 'grid' ? '#6896e1' : '#cbd5e1'}
                onClick={() => setView('grid')}
                style={{ cursor: 'pointer' }}
              />
              <ListBullets
                size={24}
                color={view === 'list' ? '#6896e1' : '#cbd5e1'}
                onClick={() => setView('list')}
                style={{ cursor: 'pointer' }}
              />

              <MapTrifold
                size={24}
                onClick={() => setView('map')}
                color={view === 'map' ? '#6896e1' : '#cbd5e1'}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </Grid.Col>{' '}
        {view === 'grid' && (
          <Grid.Col sm={12} style={{ marginTop: '12px' }}>
            <GridView buildings={buildings} />
          </Grid.Col>
        )}
        {view === 'list' && (
          <Grid.Col sm={12} style={{ marginTop: '12px' }}>
            {' '}
            <ScrollBox>
              <ListView buildings={buildings} />{' '}
            </ScrollBox>
          </Grid.Col>
        )}
      </Grid>{' '}
      {view === 'map' && (
        <div style={{ position: 'relative', height: '81vh', margin: '28px -32px 0 -32px' }}>
          <BuildingLocations />
        </div>
      )}
    </div>
  );
};

export default BuildingCards;

const GridView = ({ buildings }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filtered = buildings
      ?.filter((building) => building.isFavorite === false)
      ?.filter((building) => building.active === true)
      .filter(
        (data) =>
          data.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          data.location.toLowerCase().includes(inputValue.toLowerCase())
      );
    setFilteredData(filtered);
  }, [inputValue, buildings]);

  return (
    <>
      {buildings.filter((building) => building.isFavorite).length > 0 && (
        <Grid fullwidth style={{ marginBottom: '32px' }}>
          <Grid.Col xs={12} key={'f0'}>
            <Box style={{ marginBottom: '-8px' }} gap={6}>
              <Heart color="#94a3b8" />
              <Text color="lighter" size="small">
                Favorites
              </Text>
            </Box>
          </Grid.Col>
          {
            /// Filter the building by isFavorite = true and return the StyledBuildingItem component
            buildings
              .filter((building) => building.isFavorite)
              .filter((building) => building.active)
              .sort((building) => building.favorite.id)
              .map((building) => (
                <Grid.Col key={building.id} xs={12} sm={6} md={4} lg={3}>
                  <BuildingCard building={building} />
                </Grid.Col>
              ))
          }
        </Grid>
      )}
      <Grid fullwidth>
        {' '}
        <Grid.Col xs={12} key={0}>
          <Box style={{ margin: '0px 0 -8px 0' }} gap={6}>
            <MagnifyingGlass color="#94a3b8" />
            <Text color="lighter" size="small">
              Filter by name or location
            </Text>{' '}
          </Box>
        </Grid.Col>
        <Grid.Col xs={6} key={0}>
          <StyledTextfield
            placeholder={'Type Building name or location'}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
          />
          {/* {error && <div>Something went wrong...</div>} */}
        </Grid.Col>
        {filteredData < 1 ? (
          <Text color="lightest" style={{ padding: ' 0 24px 0 24px' }}>
            0 results
          </Text>
        ) : (
          filteredData?.map((building) => (
            <Grid.Col key={building.id} xs={12} sm={6} md={4} lg={3}>
              <BuildingCard building={building} />
            </Grid.Col>
          ))
        )}
      </Grid>
    </>
  );
};

const ListView = ({ buildings }) => {
  return (
    <Surface bgColor={SurfaceColour2} padding="12px 30px 30px 30px">
      <StyledTable style={{ width: '100%' }}>
        <tr>
          <th>
            {' '}
            <Text weight="bold" color="dark">
              Building{' '}
            </Text>
          </th>
          <th>Location</th>
          <th></th>
        </tr>
        {buildings
          .filter((building) => building.active)
          .map((building) => (
            <tr key={building.id}>
              <td>
                <Text color="light" weight="bold">
                  {building.name}
                </Text>
              </td>
              <td>
                <Text color="lighter">{building.location}</Text>
              </td>
              <td>
                <Text color="light" align="right">
                  <Link to={`/buildings/${building.id}`}>View </Link>{' '}
                </Text>
              </td>
            </tr>
          ))}
      </StyledTable>
    </Surface>
  );
};

const BuildingCard = ({ building }) => {
  return (
    <Link to={`/buildings/${building.id}`} style={{ color: 'unset', textDecoration: 'none' }}>
      <StyledBuildingCard>
        <Surface bgColor={SurfaceColour2} height={'100%'}>
          <Box direction="row" alignItems="flex-start" gap={12} style={{ minHeight: '70px', marginBottom: '8px' }}>
            <Box
              style={{ background: '#3b82f6', width: '40px', height: '40px', borderRadius: '6px' }}
              alignItems="center"
              justifyContent="center"
            >
              <Text color="dark" size="xlarge" weight="medium" transform="uppercase">
                {building.name.slice(0, 2)}
              </Text>
            </Box>
            <Box direction="row" justifyContent="space-between" flex={1}>
              <Box direction="column">
                <Text size="xlarge" color="light">
                  {building.name}
                </Text>
                <Text size="small" color="lighter" style={{ lineHeight: '1.2' }}>
                  {building.location}
                </Text>
              </Box>{' '}
              <ToggleFavorite building={building} />
            </Box>
          </Box>
          {/* <Text color="lighter">{building.description}</Text> */}{' '}
          <Box direction="row" justifyContent="space-between" alignItems="center">
            {building?.windowSensorCount ? (
              <Box gap={3} alignItems="center">
                <Text size="small" weight="bold">
                  {building.windowSensorCount}
                </Text>
                <Text size="small" color="lighter">
                  Window Sensors{' '}
                </Text>
                <StyledTooltip
                  content="No further information available within the Command Centre"
                  onClick={(e) => e.preventDefault()}
                  style={{ cursor: 'default' }}
                >
                  <Info size={16} color="#437cd9" />
                </StyledTooltip>
              </Box>
            ) : (
              <div> </div>
            )}
            <Box
              className="actions"
              style={{ margin: '0px 0 0 0' }}
              justifyContent="flex-end"
              gap={4}
              alignItems="center"
            >
              <Text size="small">View </Text>
              <ArrowRight size={16} color="#fff" />{' '}
            </Box>
          </Box>
        </Surface>
      </StyledBuildingCard>{' '}
    </Link>
  );
};
