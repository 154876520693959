import React, { useState, useEffect } from 'react';
import Surface from '../ui/Surface';
import { SurfaceColour2 } from '../config/app';
import MiniKpi from './MiniKpi';
import { Box, Grid, Loader, Text } from '@ies-ds/react-ui';
import { useGetChannelDataSummaryRangeMeteredQuery } from '../../services/scan';
import moment from 'moment';
import ApexLineScan from '../ui/ey/ApexAreaScan';
import { useShowResultDataQuery } from '../utilities';

let twoYearAgo = moment().subtract(2, 'years').subtract(0, 'months').toISOString().substring(0, 10);
let oneYear = moment().subtract(1, 'years').subtract(0, 'months').toISOString().substring(0, 10);
let sixMonth = moment().subtract(0, 'years').subtract(6, 'months').toISOString().substring(0, 10);
let threeMonth = moment().subtract(0, 'years').subtract(4, 'months').toISOString().substring(0, 10);
let oneMonth = moment().subtract(0, 'years').subtract(1, 'months').toISOString().substring(0, 10);
let oneWeek = moment().subtract(0, 'years').subtract(7, 'days').toISOString().substring(0, 10);
let oneDay = moment().subtract(0, 'years').subtract(2, 'days').toISOString().substring(0, 10);

let oneYearPeriod = 43800;
let sixMonthPeriod = 43800;
let threeMonthPeriod = 43800;
let oneMonthPeriod = 10080;
let oneWeekPeriod = 1440;
let oneDayPeriod = 60;

let oneYearPeriodMins = 525600;
let sixMonthPeriodMins = 262800;
let threeMonthPeriodMins = 131400;
let oneMonthPeriodMins = 10080;
let oneWeekPeriodMins = 1440;
let oneDayPeriodMins = 60;

let startDatChart = moment().subtract(0, 'years').subtract(12, 'months').toISOString().substring(0, 10);
let today = moment(Date.now()).subtract(0, 'd').toISOString().substring(0, 10);

const rangeChart = {
  previous_year: { startDate: twoYearAgo, endDate: oneYear, period: oneYearPeriod, label: 'Previous Year' },
  this_year: { startDate: oneYear, endDate: today, period: oneYearPeriod, label: 'Past 12 Months' },
  six_month: { startDate: sixMonth, endDate: today, period: sixMonthPeriod, label: 'Past 6 Months' },
  three_month: { startDate: threeMonth, endDate: today, period: threeMonthPeriod, label: 'Past 3 Months' },
  month: { startDate: oneMonth, endDate: today, period: oneMonthPeriod, label: 'Past 30 Days' },
  week: { startDate: oneWeek, endDate: today, period: oneWeekPeriod, label: 'Past Week' },
  day: { startDate: oneDay, endDate: today, period: oneDayPeriod, label: 'Past 48 hrs' },
};

const range = {
  previous_year: { startDate: twoYearAgo, endDate: oneYear, period: oneYearPeriodMins, label: 'Previous Year' },
  this_year: { startDate: oneYear, endDate: today, period: oneYearPeriodMins, label: 'Past 12 Months' },
  six_month: { startDate: sixMonth, endDate: today, period: sixMonthPeriodMins, label: 'Past 6 Months' },
  three_month: { startDate: threeMonth, endDate: today, period: threeMonthPeriodMins, label: 'Past 3 Months' },
  month: { startDate: oneMonth, endDate: today, period: oneMonthPeriodMins, label: 'Past 30 Days' },
  week: { startDate: oneWeek, endDate: today, period: oneWeekPeriodMins, label: 'Past Week' },
  day: { startDate: oneDay, endDate: today, period: oneDayPeriodMins, label: 'Past 48 hrs' },
};
const EnergyDataWater = ({
  utility,
  title = '',
  channelId = '',
  chartUnit = 'm³',
  color = '#b6ff56',
  date_range = 'this_year',
  project = 'HighlandCouncil',
  building = 'SiteEnergyBills',
}) => {
  const [dateRange, setDateRange] = useState(date_range);

  useEffect(() => {
    setDateRange(date_range);
  }, [date_range]);

  const {
    isLoading: isLoadingChart,
    isFetching: isFetchingChart,
    data: dataChart,
    error: errorChart,
  } = useGetChannelDataSummaryRangeMeteredQuery({
    project,
    building,
    channelId: utility.channelTotal,
    period: rangeChart[dateRange].period,
    startDate: rangeChart[dateRange].startDate,
    endDate: rangeChart[dateRange].endDate,
  });

  return (
    <Surface bgColor={SurfaceColour2}>
      <Box alignItems="center" gap={6} style={{ marginBottom: '8px' }} justifyContent="space-between">
        <Text color="dark" size="xlarge" transform="capitalize">
          {title}
        </Text>
        {utility.description && (
          <Text color="lighter" size="small">
            {utility.description}
          </Text>
        )}
      </Box>
      <Grid fullwidth>
        <Grid.Col sm={12} lg={4}>
          <Grid fullwidth>
            <Grid.Col sm={12} md={6}>
              <MiniKpi
                title="Water use"
                unit="m³"
                color={color}
                height={'100px'}
                value={useShowResultDataQuery(
                  utility.channelTotal,
                  range[dateRange].period,
                  2,
                  range[dateRange].startDate,
                  project,
                  building
                )}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <MiniKpi
                title="Water Use per m²"
                unit="m³/m²"
                color={color}
                height={'100px'}
                value={useShowResultDataQuery(
                  utility.channelEui,
                  range[dateRange].period,
                  2,
                  range[dateRange].startDate,
                  project,
                  building
                )}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <MiniKpi
                title="Water Cost"
                prependUnit="£"
                color={color}
                height={'100px'}
                value={useShowResultDataQuery(
                  utility.channelCost,
                  range[dateRange].period,
                  0,
                  range[dateRange].startDate,
                  project,
                  building
                )}
              />
            </Grid.Col>

            <Grid.Col sm={12} md={6}></Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col sm={12} lg={8}>
          {isLoadingChart || isFetchingChart ? (
            <Box justifyContent="center" alignItems="center" style={{ height: '285px' }}>
              <Loader type="dots" />
            </Box>
          ) : (
            <>
              {!dataChart ? (
                'Not Set'
              ) : dataChart.Error || errorChart ? (
                'Scan Connection Error'
              ) : (
                <ApexLineScan
                  type="bar"
                  title={`${range[dateRange].label}`}
                  id={channelId}
                  dataActual={dataChart.Total}
                  color={color}
                  height="250"
                  units={chartUnit}
                  yTicks={4}
                  strokeShow={false}
                  timestamps={dataChart.Timestamps}
                />
              )}
              {/* {meteredData.Total.map((d) => d)} */}
            </>
          )}
        </Grid.Col>
      </Grid>
    </Surface>
  );
};

export default EnergyDataWater;
