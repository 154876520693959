import React, { useState } from 'react';
import moment from 'moment';
import EnergyData from '../kpi/EnergyData';
import { useGetUtilitiesByBuildingIdQuery } from '../../services/building';
import { useParams } from 'react-router-dom';
import EnergyDataWater from '../kpi/EnergyDataWater';
import { Box, Loader, Select, Text } from '@ies-ds/react-ui';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  width: 200px;
  margin-bottom: 0 !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8390a3;
  background-color: #253144;
  background-clip: padding-box;
  border: 2px solid #3a415b;
  border-radius: 0.25rem;
  padding: 0.275rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #abb2bd;
    background-color: #253144;
    border-color: #3a415b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem 0946a7;
  }

  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
`;

let start_date = moment().subtract(0, 'years').subtract(6, 'days').toISOString().substring(0, 10);

const BuildingOverview = () => {
  const { buildingId } = useParams();

  const { isLoading, data: utilities, isFetching } = useGetUtilitiesByBuildingIdQuery(buildingId);

  const [dateRange, setDateRange] = useState('this_year');

  if (isLoading || isFetching)
    return (
      <Box
        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        alignItems="center"
        justifyContent="center"
      >
        <Loader size="small" type="dots" />
      </Box>
    );

  const utilityArray = {
    energy: {
      color: '#b6ff56',
      unit: 'kWh',
      euiUnit: 'kWh/m²',
    },
    electricity: {
      color: '#fde502',
      unit: 'kWh',
      euiUnit: 'kWh/m²',
    },

    lpg: {
      color: '#cf56ff',
      unit: 'l',
      euiUnit: 'kWh/m²',
    },

    water: {
      color: '#02b5fd',
      unit: 'l',
      euiUnit: 'l/m²',
    },
    biomass: {
      color: '#d17cf3',
      unit: 'kWh',
      euiUnit: 'kWh/m²',
    },
    oil: {
      color: '#cf56ff',
      unit: 'l',
      euiUnit: 'l/m²',
    },
    gas: {
      color: '#cf56ff',
      unit: 'kWh',
      euiUnit: 'kWh/m²',
    },
    propane: {
      color: '#cf56ff',
      unit: 'l',
      euiUnit: 'l/m²',
    },
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    setDateRange(event.target.value);
  };

  return (
    <>
      {utilities.length === 0 ? (
        <Text color="lighter">No Overview Data</Text>
      ) : (
        <>
          {' '}
          <div>
            <Box style={{ width: '100%' }} flex={1} justifyContent="flex-end" alignItems="center" gap={8}>
              <Text color="lightest" style={{ marginBottom: '12px' }}>
                Date Range:{' '}
              </Text>
              <StyledSelect onChange={handleChange} defaultValue={'this_year'}>
                <option value="previous_year">Previous 12 Months</option>
                <option value="this_year">Last 12 Months</option>
                <option value="six_month">Last 6 Months</option>
                <option value="three_month">Last 3 Months</option>
                {/* <option value="month">Past 30 days</option>
            <option value="week">Past 7 days</option>
            <option value="day">Past 48 hours</option> */}
              </StyledSelect>
            </Box>
          </div>
          {utilities.map((utility) => {
            return (
              <div key={utility.id}>
                {utility.type === 'water' ? (
                  <EnergyDataWater
                    title={utility.type}
                    utility={utility}
                    color={utilityArray[utility.type].color}
                    date_range={dateRange}
                  />
                ) : (
                  <EnergyData
                    key={utility.id}
                    title={utility.type}
                    utility={utility}
                    color={utilityArray[utility.type].color}
                    unit={utilityArray[utility.type].unit}
                    date_range={dateRange}
                  />
                )}
                <br />
              </div>
            );
          })}
          <br />
        </>
      )}
    </>
  );
};

export default BuildingOverview;
