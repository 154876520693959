import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { Button, Text } from '@ies-ds/react-ui';
import { useGetAllBuildingsQuery } from '../../services/building';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const token = process.env.REACT_APP_MAPBOX_KEY;

const styles = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  //height: "calc(100vh - 80px)",
  position: 'absolute',
};

function buildGeoJson(data) {
  return data
    .filter((building) => building.active)
    .map((building) => {
      return {
        type: 'Feature',
        properties: {
          id: building.id,
          name: building.name,
          description: building.description,
        },
        geometry: {
          type: 'Point',
          coordinates: [Number(building.longitude), Number(building.latitude)],
        },
      };
    });
}

const BuildingLocations = ({
  accessToken = token,
  zoom = '7',
  mapstyle = 'mapbox://styles/gavinmcnamee/clj2ophuu00fy01qq8y509za0',
}) => {
  const { isLoading, data: buildings, error } = useGetAllBuildingsQuery();

  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  const popUpRef = useRef(new mapboxgl.Popup({ offset: 15 }));

  useEffect(() => {
    mapboxgl.accessToken = accessToken;

    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapstyle, // stylesheet location
        // style: "mapbox://styles/mapbox/satellite-streets-v11", // stylesheet location
        // style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
        center: [-4.304919340220238, 56.90991820710724],
        pitch: 40, // pitch in degrees
        //bearing: -60, // bearing in degrees
        zoom: zoom,
      });

      map.addControl(new mapboxgl.FullscreenControl());

      map.on('load', () => {
        // Insert the layer beneath any symbol layer.
        const layers = map.getStyle().layers;
        const labelLayerId = layers.find((layer) => layer.type === 'symbol' && layer.layout['text-field']).id;

        map.addSource('buildingLocations', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: buildGeoJson(buildings),
          },

          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'buildingLocations',
          filter: ['has', 'point_count'],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            'circle-color': ['step', ['get', 'point_count'], '#3b82f6', 100, '#f1f075', 750, '#f28cb1'],
            'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
          },
        });

        map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'buildingLocations',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 14,
          },
        });

        map.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'buildingLocations',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#3b82f6',
            'circle-radius': 8,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#fff',
          },
        });

        // inspect a cluster on click
        map.on('click', 'clusters', (e) => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
          });
          const clusterId = features[0].properties.cluster_id;
          map.getSource('buildingLocations').getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
        });

        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        map.on('click', 'unclustered-point', (e) => {
          const { id, name, description } = e.features[0].properties;
          const coordinates = e.features[0].geometry.coordinates.slice();
          const tsunami = e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          const popupNode = document.createElement('div');
          ReactDOM.render(
            <>
              <Text size="large" weight="medium">
                {name}
              </Text>
              <Text as="p" size="xsmall">
                {description}
              </Text>
              <Button as="a" href={`/buildings/${id}`} size="small" color="primary">
                View Building
              </Button>
            </>,
            popupNode
          );

          popUpRef.current.setLngLat(e.lngLat).setDOMContent(popupNode).addTo(map);

          //new mapboxgl.Popup().setLngLat(coordinates).setHTML(` ${description}`).addTo(map);
        });

        map.on('mouseenter', 'clusters', () => {
          map.getCanvas().style.cursor = 'pointer';
        });
        map.on('mouseleave', 'clusters', () => {
          map.getCanvas().style.cursor = '';
        });

        // buildings?.forEach((location) => {
        //   // add a point to the map for each location

        //   new mapboxgl.Marker({
        //     //draggable: true,
        //     color: '#3b82f6',
        //   })
        //     .setLngLat([Number(location.longitude), Number(location.latitude)])
        //     .addTo(map);

        //   // map.addLayer(
        //   //   {
        //   //     id: location.name,
        //   //     type: 'circle',
        //   //     source: {
        //   //       type: 'geojson',
        //   //       data: {
        //   //         type: 'Point',
        //   //         coordinates: [Number(location.longitude), Number(location.latitude)],
        //   //       },
        //   //     },
        //   //     paint: {
        //   //       'circle-radius': 8,
        //   //       'circle-color': '#3b82f6',
        //   //       'circle-stroke-width': 2,
        //   //       'circle-stroke-color': '#fff',
        //   //     },
        //   //   },
        //   //   labelLayerId
        //   // );
        // });

        // add pin to map using the coordinates passed in
        // map.addLayer(
        //   {
        //     id: 'CharleKennedy',
        //     type: 'circle',
        //     source: {
        //       type: 'geojson',
        //       data: {
        //         type: 'Point',
        //         coordinates: [Number(longitude), Number(latitude)],
        //       },
        //     },
        //     paint: {
        //       'circle-radius': 8,
        //       'circle-color': '#3b82f6',
        //       'circle-stroke-width': 2,
        //       'circle-stroke-color': '#fff',
        //     },
        //   },
        //   labelLayerId
        // );

        // The 'building' layer in the Mapbox Streets
        // vector tileset contains building height data
        // from OpenStreetMap.

        map.addLayer(
          {
            id: 'add-3d-buildings',
            source: 'composite',
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: 15,
            paint: {
              'fill-extrusion-color': '#bdd0db',

              // Use an 'interpolate' expression to
              // add a smooth transition effect to
              // the buildings as the user zooms in.
              'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
              'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
              'fill-extrusion-opacity': 0.8,
            },
          },
          labelLayerId
        );

        // map.on('click', function (e) {
        //   var coordinates = e.lngLat;
        //   new mapboxgl.Popup()
        //     .setLngLat(coordinates)
        //     .setHTML('you clicked here: <br/>' + coordinates)
        //     .addTo(map);
        // });

        setMap(map);
        map.resize();
      });
    };

    if (!map) initializeMap({ setMap, mapContainer });
  }, [map]);

  isLoading && <isLoading />;

  return <div ref={(el) => (mapContainer.current = el)} style={styles} />;
};

export default BuildingLocations;
