import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import LayoutAdmin from './admin/LayoutAdmin';
import LayoutDashboard, { PanelLv1, StyledDashboardContainer } from './dashboard/LayoutDashboard';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Box, Button, Logo, Text, ThemeProvider, dark } from '@ies-ds/react-ui';

import { loginRequest } from './authConfig';
import Login from './Login';
import LayoutDashboardV2 from './dashboard/LayoutDashboardv2';
import InvitationAccept from './dashboard/Invitations/InvitationAccept';
import Authtest from './dashboard/authtest/Authtest';

function App({ instance }) {
  return (
    <>
      <MsalProvider instance={instance}>
        <AuthenticatedTemplate>
          <Routes>
            <Route path="/v2/*" element={<LayoutDashboardV2 />} />
            <Route path="/dashboard/*" element={<LayoutDashboard />} />
            <Route path="/dashboard-admin/*" element={<LayoutAdmin />} />
            <Route path="/*" element={<LayoutDashboardV2 />} />
            <Route path="/accept-invitation" element={<InvitationAccept instance={instance} />} />
            <Route path="/login" element={<Login instance={instance} />} />
            <Route path="/authtest" element={<Authtest />} />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="*" element={<Login instance={instance} />} />
            <Route path="/accept-invitation" element={<InvitationAccept instance={instance} />} />
            <Route path="/login" element={<Login instance={instance} />} />
          </Routes>
        </UnauthenticatedTemplate>
      </MsalProvider>
    </>
  );
}

export default App;
