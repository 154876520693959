import React from 'react';
import { useDeleteBmsSensorMutation } from '../../services/building';
import { Box, Button, Modal, Text } from '@ies-ds/react-ui';

const BmsSensorDelete = ({ id }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [deleteBmsSensor, { isError, isLoading, isSuccess }] = useDeleteBmsSensorMutation();

  return (
    <div>
      <Button size="xsmall" onClick={() => setIsOpen(true)}>
        Delete
      </Button>
      <Modal
        closeButton
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Modal.Header>
          <Text weight={'medium'} size="large">
            Delete BMS Sensor
          </Text>
        </Modal.Header>
        <Modal.Content>
          <Text weight="medium">Are you sure you want to delete this BMS Sensor?</Text>
          <br />
          {/* <p>All floor and BMS Sensor data associated with the BMS Sensor will be permanently deleted as well!</p> */}
        </Modal.Content>

        <Modal.Footer>
          <Box gap={8}>
            <Button color="warning" onClick={() => deleteBmsSensor(id)}>
              Delete
            </Button>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BmsSensorDelete;
