import React from 'react';
import { useCreateSensorMutation, useCreateSensorTypeMutation } from '../../services/building';
import { Button, Modal, Textfield, Text, Box, Textarea, Grid, Checkbox, Select } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const SensorTypeCreate = ({ id }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { handleSubmit, register } = useForm();

  const [createSensorType, { isSuccess }] = useCreateSensorTypeMutation();

  const onSubmit = (data) => {
    createSensorType({ ...data });
  };

  return (
    <>
      <Button size="small" color="primary" onClick={() => setIsOpen(true)}>
        Add Sensor Type
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        <Modal.Header>
          <Text size="large" weight="bold">
            Add New Sensor Type
          </Text>
        </Modal.Header>
        <Modal.Content>
          {isSuccess && (
            <>
              <p style={{ color: 'green' }}>Sensor Type created successfully</p>
              <br />
            </>
          )}

          <Textfield required label="Name" {...register('name', { required: true })} />
          <Textfield required label="Type" rows={6} {...register('description', { required: true })} />
          <Select label="Location" required {...register('location', { required: true })}>
            <option value="" selected disabled hidden>
              Please select
            </option>
            <option value={'internal'}>Internal</option>
            <option value={'external'}>External</option>
          </Select>
          <Textfield required label="Unit" {...register('unit', { required: true })} />
          <Grid>
            <Grid.Col sm={6}>
              <Textfield type="number" label="Min" {...register('min')} />
              <Checkbox label="No Min Value" {...register('disableMin')} />
            </Grid.Col>
            <Grid.Col sm={6}>
              <Textfield type="number" label="Max" {...register('max')} />
              <Checkbox label="No Max Value" {...register('disableMax')} />
            </Grid.Col>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Box gap={6}>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Create
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SensorTypeCreate;
