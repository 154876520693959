import React from 'react';
import { useDeleteSensorMutation } from '../../services/building';
import { Box, Button, Modal, Text } from '@ies-ds/react-ui';

const SensorDelete = ({ trigger, sensor }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [deleteSensor, { isError, isLoading, isSuccess }] = useDeleteSensorMutation();

  return (
    <div>
      {trigger ? (
        <span onClick={() => setIsOpen(true)}>{trigger}</span>
      ) : (
        <Button size="xsmall" onClick={() => setIsOpen(true)}>
          Delete
        </Button>
      )}

      <Modal
        closeButton
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Modal.Header>
          <Text weight={'medium'} size="large">
            Delete {sensor.name}
          </Text>
        </Modal.Header>
        <Modal.Content>
          <Text weight="medium">Are you sure you want to delete this sensor?</Text>
          <br />
          {/* <p>All floor and sensor data associated with the sensor will be permanently deleted as well!</p> */}
        </Modal.Content>

        <Modal.Footer>
          <Box gap={8}>
            <Button color="warning" onClick={() => deleteSensor(sensor.id)}>
              Delete
            </Button>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SensorDelete;
