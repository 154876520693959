import React, { useEffect } from 'react';
import { useGetChannelDataSummaryRangeMeteredQuery } from '../../services/scan';
import ApexChart from './ApexBar';
import moment from 'moment';
import { Box, Loader } from '@ies-ds/react-ui';

import ApexLineScan from '../ui/ey/ApexAreaScan';

let start_date = moment().subtract(0, 'years').subtract(30, 'days').toISOString().substring(0, 10);

const ChartWrapper = ({ channelId, unit, min, max, building, maxDisabled = false, minDisabled = false }) => {
  const {
    isFetching,
    isLoading,
    data: chartData,
    isError,
  } = useGetChannelDataSummaryRangeMeteredQuery({
    project: 'HighlandCouncil',
    building: building,
    channelId: channelId,
    startDate: start_date,
    endDate: moment(Date.now()).subtract(0, 'd').toISOString().substring(0, 10),
    period: '60',
  });

  useEffect(() => {
    console.log('chartData', chartData);
  }, [chartData, channelId]);

  if (isError) return <div>Something went wrong</div>;
  if (isLoading || isFetching)
    return (
      <Box
        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        alignItems="center"
        justifyContent="center"
      >
        <Loader size="xsmall" type="dots" />
      </Box>
    );

  return (
    <div>
      <ApexChart
        type={'bar'}
        title={' '}
        id={channelId}
        channel1={chartData.Mean}
        //channel2={chartData.Mean}
        color={'rgba(34, 197, 94, 0.85)'}
        height="300"
        units={unit}
        yTicks={5}
        toolbar={true}
        // orange={meteredDataOrange.Mean}
        // yellow={meteredDataYellow.Mean}
        timestamps={chartData.Timestamps}
        minRange={min}
        maxRange={max}
        minDisabled={minDisabled}
        maxDisabled={maxDisabled}
        startDate={moment(Date.now()).subtract(200, 'd')}
        endDate={moment(Date.now()).subtract(0, 'd')}
      />
      {/* <ApexLineScan
        type="bar"
        //title={`${range[dateRange].label}`}
        id={'sensor?.channel'}
        dataActual={chartData.Mean}
        color={'rgba(34, 197, 94, 0.85)'}
        height="250"
        units={unit}
        yTicks={4}
        strokeShow={false}
        timestamps={chartData.Timestamps}
        minRange={min}
        maxRange={max}
      /> */}
    </div>
  );
};

export default ChartWrapper;
