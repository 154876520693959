import { Avatar, Text } from '@ies-ds/react-ui';
import React from 'react';

function setColor(name) {
  const first = Array.from(name)[0];

  switch (first) {
    case 'A':
      return '#ff00e5';
    case 'D':
      return '#a606ff';
    case 'F':
      return '#82af09';
    case 'G':
      return '#09c58d';
    case 'H':
      return '#09a6c5';
    case 'J':
      return '#067aff';
    case 'M':
      return '#5506ff';
    case 'P':
      return '#2ddd3f';
    case 'S':
      return '#bb00ff';

    default:
      return '#92c509';
  }
}

const AvatarColor = ({ name, ...props }) => {
  return (
    <Avatar size="small" bgColor={setColor(name)} {...props}>
      <Text weight="medium" transform="uppercase" color="#fff">
        {name}
      </Text>
    </Avatar>
  );
};

export default AvatarColor;
