import { Box, Text, Tooltip } from '@ies-ds/react-ui';
import { Database, Gear, House, ShieldPlus, Users } from '@phosphor-icons/react';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import styled from 'styled-components';
import * as DT from '@ies-ds/design-tokens';
import * as ICONS from '@phosphor-icons/react';

const StyledLink = styled(NavLink)`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background 0.2s ease-in-out;
  color: rgba(255, 255, 255, 0.8) !important;
  gap: 12px;
  justify-content: flex-start;
  text-decoration: none;
  align-items: center;
  svg {
    path {
      fill: rgba(255, 255, 255, 0.6);
      fill: ${DT.COLOR_BLUE_50};
      opacity: 0.75;
    }
  }
  &:hover {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 1) !important;
    svg {
      path {
        fill: rgba(255, 255, 255, 1);
        opacity: 1;
      }
    }
  }
  &.active {
    background: ${DT.COLOR_BLUE_500};
    color: rgba(255, 255, 255, 1) !important;

    svg {
      path {
        fill: rgba(255, 255, 255, 1);
        opacity: 1;
      }
    }
  }
`;

const StyledToggleOpenButton = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background 0.2s ease-in-out;
  color: rgba(255, 255, 255, 0.8) !important;
  gap: 12px;
  justify-content: flex-start;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  svg {
    path {
      fill: rgba(255, 255, 255, 0.6);
      fill: ${DT.COLOR_BLUE_50};
      opacity: 0.75;
    }
  }
  &:hover {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 1) !important;

    svg {
      path {
        fill: rgba(255, 255, 255, 1);
        opacity: 1;
      }
    }
  }
`;

const Sidenav = () => {
  const [open, setOpen] = React.useState(true);

  return (
    <Box direction="column" style={{ marginTop: '12px', height: '100%' }} justifyContent="space-between">
      <Box direction="column" gap={4}>
        <SideNavItem to={''} icon={'House'} label={'Home'} open={open} end />
        <SideNavItem to={'buildings'} icon={'Buildings'} label={'Buildings'} open={open} />
        <SideNavItem to={'energy'} icon={'ChartLine'} label={'Energy Consumption'} open={open} />
        <SideNavItem to={'actions'} icon={'Lightbulb'} label={'Actionable Intelligance'} open={open} />
        {/* <SideNavItem to={'users'} icon={'Users'} label={'Users'} open={open} />
        <SideNavItem to={'permissions'} icon={'ShieldPlus'} label={'Permissions'} open={open} />
        <SideNavItem to={'connections'} icon={'Database'} label={'Connections'} open={open} />

        <br />

        <SideNavItem to={'settings'} icon={'Gear'} label={'Settings'} open={open} /> */}
      </Box>
      <ToggleOpenButton open={open} setOpen={setOpen} />
    </Box>
  );
};

export default Sidenav;

const SideNavItem = ({ to, icon, label, open, end = false }) => {
  const Component = ICONS[icon];

  return (
    <StyledLink to={to} end={end}>
      <Component size={22} color="#f5f5f5" weight="bold" />
      {open ? <Text style={{ width: '150px' }}>{label}</Text> : null}
    </StyledLink>
  );
};

const ToggleOpenButton = ({ open, setOpen }) => {
  return (
    <StyledToggleOpenButton onClick={() => setOpen(!open)}>
      {' '}
      <Box gap={8} alignItems="center">
        {open ? (
          <>
            <ICONS.CaretDoubleLeft size={22} color="#f5f5f5" weight="bold" />
            <Text style={{ color: '#fff' }}>Close</Text>
          </>
        ) : (
          <Tooltip position="right" content="Open Navigation">
            <ICONS.CaretDoubleRight size={22} color="#f5f5f5" weight="bold" />
          </Tooltip>
        )}
      </Box>
    </StyledToggleOpenButton>
  );
};
