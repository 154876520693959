import { Box, Button, Text, ThemeProvider, dark } from '@ies-ds/react-ui';
import React from 'react';
import { PanelLv1, StyledDashboardContainer } from '../LayoutDashboardv2';
import { WarningCircle } from '@phosphor-icons/react';
import HCLOGOCOLOR from '../../assets/img/hc-logo-white.png';
import { useMsal } from '@azure/msal-react';

const PageNotAuthorised = ({}) => {
  const { instance } = useMsal();

  return (
    <ThemeProvider theme={dark}>
      <StyledDashboardContainer>
        <Box justifyContent="center" style={{ height: '100%', width: '100%' }}>
          <Box direction="column" alignItems="center" justifyContent="center">
            <PanelLv1 style={{ width: '400px' }}>
              <Box justifyContent="center" direction="column" alignItems="center" gap={24}>
                {/* <Logo logo="ies-light" width={60} height={60} /> */}
                <img src={HCLOGOCOLOR} alt="Highland Council Logo" width={150} />
                <Box justifyContent="center" direction="row" alignItems="center" gap={8}>
                  <>
                    <WarningCircle color="#eb2664" size={35} />{' '}
                    <Text color="light" align="center" size="xlarge">
                      Not Authorised
                    </Text>
                  </>
                </Box>{' '}
                <Text color="lighter" align="center">
                  Please contact your administrator
                </Text>
              </Box>
              <br />
              <Button
                style={{ width: '100%' }}
                color="primary"
                onClick={() =>
                  instance.logoutRedirect({
                    postLogoutRedirectUri: '/',
                  })
                }
              >
                Back to Login
              </Button>
            </PanelLv1>
          </Box>
        </Box>
      </StyledDashboardContainer>
    </ThemeProvider>
  );
};

export default PageNotAuthorised;
