import { Box, Button, Grid, Modal, Text, Textarea, Textfield } from '@ies-ds/react-ui';
import React from 'react';

import { useForm } from 'react-hook-form';
import { usePostCreateBuildingMutation } from '../../services/building';
import { Plus } from '@phosphor-icons/react';

const BuildingCreate = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [createBuilding, { isError, isLoading, isSuccess }] = usePostCreateBuildingMutation();

  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    createBuilding(data);
    console.log(data);
  };

  return (
    <div>
      <Button
        size="small"
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
        icon={<Plus color="#ffffe5" />}
      >
        Add Building
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        <Modal.Header>
          <Text size="large" weight="bold">
            Add new building
          </Text>
        </Modal.Header>
        <Modal.Content>
          {isSuccess && (
            <>
              <p style={{ color: 'green' }}>Building created successfully</p>
              <br />
            </>
          )}

          <Textfield label="Name" {...register('name')} />
          <Textfield label="Location" {...register('location')} />
          <Textarea label="Description" rows={3} {...register('description')} />
          <Grid>
            <Grid.Col sm={6}>
              <Textfield label="Latitude" {...register('latitude')} />
            </Grid.Col>
            <Grid.Col sm={6}>
              <Textfield label="Longitude" {...register('longitude')} />
            </Grid.Col>
          </Grid>

          <Textfield label="Scan Building Name" {...register('scanBuildingName')} />
          <Textfield
            label="Window Sensor Count"
            {...register('windowSensorCount')}
            type="number"
            style={{ width: '30%' }}
          />
        </Modal.Content>
        <Modal.Footer>
          <Box gap={6}>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Create
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BuildingCreate;
