import React, { useEffect, useState } from 'react';
import { useGetAllBuildingsQuery } from '../services/building';
import { Box, Text, Textfield } from '@ies-ds/react-ui';
import { level1, level2, level3, primary } from './ui/colors';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import BuildingDetail from './BuildingDetail';
import styled from 'styled-components';

const StyledTextfield = styled(Textfield)`
  padding: 8px 12px;
  font-size: 14px;
  background: rgb(33 45 64);
`;

const StyledBuildingItem = styled(NavLink)`
  padding: 8px 24px;
  display: block;
  text-decoration: none;
  &:hover {
    background: ${level3};
    text-decoration: none;
  }
  &.active {
    background: #262e3e;
    border-right: 2px solid ${primary};
  }
`;

const BuildingList = () => {
  const { isLoading, data: buildings, error } = useGetAllBuildingsQuery();

  const [inputValue, setInputValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filtered = buildings?.filter(
      (data) =>
        data.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        data.location.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredData(filtered);
  }, [inputValue, buildings]);

  return (
    <Box direction="row" style={{ height: '100%' }}>
      <div style={{ background: level2, width: '280px', padding: '32px 0px', height: '100%' }}>
        <div style={{ padding: '0 24px' }}>
          <Text size="large" color="light">
            Buildings
          </Text>
          <br />
          <StyledTextfield placeholder="Filter..." onChange={(e) => setInputValue(e.target.value)} value={inputValue} />
          {error && <div>Something went wrong...</div>}
        </div>

        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div>
            {filteredData < 1 ? 'No results' : filteredData?.map((building) => <BuildingItem building={building} />)}

            {/* {buildings?.map((building) => (
              <BuildingItem building={building} />
            ))} */}
          </div>
        )}
      </div>
      <Box direction="row" flex={1}>
        <Routes>
          <Route path="/" element={'HOME'} />
          <Route path="/:buildingId/*" element={<BuildingDetail />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default BuildingList;

const BuildingItem = ({ building }) => {
  return (
    <StyledBuildingItem to={`/buildings/${building.id}`}>
      <Box direction="column" gap={0}>
        <Text color="light">{building.name}</Text>
        <Text color="lighter" size="xsmall">
          {building.location}
          {/* | Floors: {building.floors.length} */}
        </Text>
      </Box>
    </StyledBuildingItem>
  );
};
