import React from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import FloorListByBuilding from '../floor/FloorListByBuilding';
import ZoneListByBuilding from '../zone/ZoneListByBuilding';
import SensorListByBuilding from '../sensor/SensorListByBuilding';
import ContentWindow from '../../components/ui/ContentWindow';
import { Box, Button, Grid, Loader, Text } from '@ies-ds/react-ui';
import Tab from '../../components/ui/Tab';
import { CaretLeft } from '@phosphor-icons/react';
import { useGetBuildingByIdQuery } from '../../services/building';
import SensorTypeList from '../sensor_type/SensorTypeList';
import UtilitiesListByBuilding from '../utilities/UtilitiesListByBuilding';
import MeterListByBuilding from '../meter/MeterListByBuilding';
import BmsSensorListByBuilding from '../bms/BmsSensorListByBuilding';
import StatusBadge from '../../components/ui/StatusBadge';
import BuildingEdit from '../building/BuildingEdit';

const PageBuildingDetail = () => {
  const { id } = useParams();
  const { isLoading: isLoadingBuilding, data: building } = useGetBuildingByIdQuery(id);

  return (
    <>
      <Box direction="column" style={{ minHeight: '48px', padding: '0 32px', background: '#fff' }}>
        <Link to="../">
          <Box alignItems="center" gap={4}>
            <CaretLeft size={14} weight="bold" />
            <Text size="small" weight="medium" lineHeight={1}>
              Back to Buildings
            </Text>
          </Box>
        </Link>
        <br />
        <Box justifyContent="space-between" alignItems="center">
          {isLoadingBuilding ? (
            <Loader type="dots" size="small" />
          ) : (
            <>
              <Box direction="row" alignItems="center" gap={12}>
                <Text size="2xl" weight="medium">
                  {building.name}{' '}
                </Text>{' '}
                {building.active ? (
                  <StatusBadge status={'good'}>Active </StatusBadge>
                ) : (
                  <StatusBadge status={'bad'}>Not Active</StatusBadge>
                )}
                <BuildingEdit building={building} />
              </Box>
              {building.active && (
                <Button as={Link} to={`/buildings/${building.id}/`} size="small" color="primary">
                  View Live Dashboard
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box
        style={{ minHeight: '48px', padding: '0 32px', borderBottom: '1px solid #f3f2f7', background: '#fff' }}
        alignItems="flex-end"
      >
        <Tab to={''} end>
          Floors
        </Tab>
        {/* <Tab to={'zones'}>Zones</Tab>
        <Tab to={'sensors'}>Sensors</Tab>
        <Tab to={'sensor-types'}>Sensor Types</Tab> */}
        <Tab to={'utilities'}>Utility Data</Tab>
        <Tab to={'bms'}>BMS/IOT </Tab>
        <Tab to={'meters'}>Submeters</Tab>
      </Box>
      <ContentWindow>
        <Grid fullwidth>
          <Grid.Col span={12}>
            <Routes>
              <Route path="/*" element={<FloorListByBuilding />} />
              <Route path="/zones" element={<ZoneListByBuilding />} />
              <Route path="/sensors" element={<SensorListByBuilding />} />
              <Route path="/bms" element={<BmsSensorListByBuilding />} />
              <Route path="/sensor-types" element={<SensorTypeList />} />
              <Route path="/utilities" element={<UtilitiesListByBuilding />} />
              <Route path="/meters" element={<MeterListByBuilding />} />
            </Routes>
          </Grid.Col>
        </Grid>
      </ContentWindow>
    </>
  );
};

export default PageBuildingDetail;
