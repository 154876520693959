import { Box, Loader, Text } from '@ies-ds/react-ui';
import { CheckCircle, CircleDashed, WarningCircle } from '@phosphor-icons/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Surface from './Surface';
import { level2, level3 } from './colors';
import { useShowResultDataQuery } from '../../hooks';
import moment from 'moment';
import styled from 'styled-components';

let startDatChart = moment().subtract(0, 'years').subtract(2, 'days').toISOString().substring(0, 10);

const StyledContainer = styled.div`
  border-radius: 4px;
  padding: 8px 0;
  height: 100%;
  :hover {
    background-color: ${level3};
  }
`;

export const StatusCell = ({
  to = 'linkTest',
  value,
  label = 'Label',
  unit = '',
  status = true,
  min = 50,
  max = 70,
  disableMax = false,
  disableMin = false,
  channel,
  project,
  building = 'CharlesKennedyBuilding',
  color,
}) => {
  const datavalue = useShowResultDataQuery(channel, '60', 0, startDatChart, project, building);

  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <StyledContainer>
        <Box direction="column" justifyContent="space-between" gap={12}>
          <Box justifyContent="center" alignItems="center" gap={8}>
            <Box justifyContent="center">
              {/* <Dot status={status} /> */}

              {typeof datavalue === 'undefined' ? (
                <Loader type="dots" size="xsmall" />
              ) : isNaN(datavalue) ? (
                ''
              ) : datavalue >= (disableMin ? -Infinity : min) && datavalue <= (disableMax ? Infinity : max) ? (
                // <CheckCircle weight="fill" size={16} color="rgb(74, 222, 128)" />
                ''
              ) : (
                <WarningCircle weight="fill" size={16} color="rgb(239, 68, 67)" />
              )}
            </Box>
            <Text
              align="center"
              size="large"
              weight="bold"
              color={color ? color : isNaN(datavalue) ? 'yellow' : 'dark'}
            >
              {isNaN(datavalue) ? '--' : datavalue}
            </Text>
            <Text size="small" color="lighter">
              {isNaN(datavalue) ? '' : unit}
            </Text>
          </Box>
        </Box>
      </StyledContainer>
    </Link>
  );
};

export default StatusCell;
