import React, { useEffect } from 'react';
import { useGetAllSensorTypeQuery, useUpdateSensorMutation } from '../../services/building';
import { Box, Button, Modal, Select, Text, Textfield } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

const ZoneSensorEdit = ({ sensor, buildingId, floorId, zoneId, trigger }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateSensor, { isError, isLoading, isSuccess, reset }] = useUpdateSensorMutation();
  const { data: sensorTypes, isLoading: isLoadingSensorTypes } = useGetAllSensorTypeQuery();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    updateSensor({
      id: sensor.id,
      buildingId: parseInt(buildingId),
      name: data.name,
      floorId: floorId,
      zoneId: zoneId,
      sensorTypeId: parseInt(data.sensorTypeId),
      channel: data.channel,
    });
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  return (
    <>
      {trigger ? (
        <span onClick={() => setIsOpen(true)}>{trigger}</span>
      ) : (
        <a href="#" size="xsmall" onClick={() => setIsOpen(true)}>
          <Text size="xsmall"> Edit </Text>
        </a>
      )}

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {' '}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Text weight={'medium'} size="large">
              Edit Sensor
            </Text>
          </Modal.Header>
          <Modal.Content>
            <Text weight="medium">Do you want to edit this sensor?</Text>
            <br />

            {/* {isLoading && <div>Loading...</div>}
            {isError && <div>Error updating sensor</div>} */}

            <Textfield label="Name" required {...register('name', { required: true })} defaultValue={sensor.name} />

            <Select label="Sensor Type" {...register('sensorTypeId')}>
              <option value="" disabled hidden>
                Please select
              </option>

              {sensorTypes?.map((sensorType) =>
                sensorType.id === sensor.sensorTypeId ? (
                  <option value={sensorType.id} selected>
                    {sensorType.description} - {sensorType.name} - ({sensorType.location})
                  </option>
                ) : (
                  <option value={sensorType.id}>
                    {sensorType.description} - {sensorType.name} ({sensorType.location})
                  </option>
                )
              )}
            </Select>

            <Textfield
              label="Channel"
              required
              {...register('channel', { required: true })}
              defaultValue={sensor.channel}
            />
            {isSuccess && (
              <>
                {' '}
                <Text color="green">Sensor updated successfully</Text>
                <br />
              </>
            )}
          </Modal.Content>

          <Modal.Footer>
            <Box gap={8}>
              <Button color="primary" type="submit">
                Update
              </Button>
              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Box>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ZoneSensorEdit;
