import React, { useEffect, useState } from 'react';
import { useUpdateZoneMutation } from '../../services/building';
import { Box, Button, Grid, Modal, Select, Text, Textfield } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';
import Surface from '../../dashboard/ui/Surface';
import { level2 } from '../../dashboard/ui/colors';
import FloorplanA from '../../assets/img/floorplanA.png';
import FloorplanC from '../../assets/img/floorplanC.png';
import FloorplanE from '../../assets/img/floorplanE.png';

import { CheckCircle, Minus, NavigationArrow, Plus, XCircle } from '@phosphor-icons/react';

import { TransformWrapper, TransformComponent, KeepScale, MiniMap } from 'react-zoom-pan-pinch';

const ZoneSetLocation = ({ floorId, zone, building, id }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateZone, { isError, isLoading, isSuccess, isFetching }] = useUpdateZoneMutation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // updateZone({ id: zone.id, xPoint: Number(data.xPoint), yPoint: Number(data.yPoint) });
  };

  const getXandY = (event) => {
    const img = document.querySelector('img');
    const imgWidth = img.clientWidth;
    const imgHeight = img.clientHeight;
    const imgX = img.getBoundingClientRect().x;
    const imgY = img.getBoundingClientRect().y;
    const mouseX = event.clientX;
    const mouseY = event.clientY;
    const xPoint = Math.round(((mouseX - imgX) / imgWidth) * 100);
    const yPoint = Math.round(((mouseY - imgY) / imgHeight) * 100);
    console.log('xPoint: ' + xPoint);
    console.log('yPoint: ' + yPoint);

    updateZone({ id: zone.id, xPoint: Number(xPoint), yPoint: Number(yPoint), floorId: floorId });
    // document.getElementById('xPoint').value = xPoint;
    // document.getElementById('yPoint').value = yPoint;
  };

  const [imageExists, setImageExists] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(true);
  const [floorplan, setFloorplan] = useState('');

  useEffect(() => {
    const img = new Image();
    img.src = `/assets/floorplans/${floorId}/floorplan.png`;

    img.onload = function () {
      setImageExists(true);
      setIsLoadingImg(false);
      setFloorplan(img.src);
    };

    img.onerror = function () {
      setImageExists(false);
      setIsLoadingImg(false);
      setFloorplan('');
    };
  }, [floorId, isLoadingImg]);

  return (
    <>
      <div>
        <Button size="xsmall" onClick={() => setIsOpen(true)}>
          Location
        </Button>
        <Modal
          size="xlarge"
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          {' '}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Text weight={'medium'} size="large">
                {zone.name} Location
              </Text>
            </Modal.Header>
            <Modal.Content>
              <Text weight="medium">Click to position the room?</Text>
              <br />
              {/* {isLoading && <div>Loading...</div>}
            {isError && <div>Error updating zone</div>} */}
              <Surface bgColor={level2} style={{ padding: '48px', marginBottom: '32px' }}>
                <Box alignItems="center" justifyContent="center" style={{ position: 'relative', width: '100%' }}>
                  <TransformWrapper initialScale={1} disabled>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <TransformComponent>
                          <div onClick={(e) => getXandY(e)}>
                            {!isLoading && <Pin x={zone.xPoint} y={zone.yPoint} />}
                            <img src={floorplan} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                          </div>
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>{' '}
                </Box>
              </Surface>
              {/* {zone.id}
              <Grid>
                <Grid.Col sm={6}>
                  <Textfield
                    label="xPoint (% from left)"
                    {...register('xPoint', { required: true })}
                    defaultValue={zone.xPoint}
                  />
                </Grid.Col>
                <Grid.Col sm={6}>
                  <Textfield
                    label="yPoint (% from top)"
                    {...register('yPoint', { required: true })}
                    defaultValue={zone.yPoint}
                  />
                </Grid.Col>
              </Grid> */}
              {isLoading || (isFetching && <div>Loading...</div>)}
              {isSuccess ? (
                <>
                  {' '}
                  <Text color="green">Zone updated successfully</Text>
                  <br />
                </>
              ) : (
                <>
                  <Text>Click on Map</Text> <br />
                </>
              )}
              {isError ? (
                <>
                  {' '}
                  <Text color="red">Error updating zone</Text>
                  <br />
                </>
              ) : (
                <></>
              )}
            </Modal.Content>

            <Modal.Footer>
              <Box gap={8}>
                {/* <Button color="primary" type="submit">
                  Update
                </Button> */}
                <Button onClick={() => setIsOpen(false)}>Close</Button>
              </Box>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default ZoneSetLocation;

const Pin = ({ x, y }) => {
  return (
    <div style={{ position: 'absolute', top: `calc(${y}% - 12px)`, left: `calc(${x}% - 12px)` }}>
      {/* <StyledPin
      alignItems="center"
      justifyContent="center"
      background="rgb(74, 222, 128)"
      onClick={() => onClickHandler()}
    > </StyledPin> */}
      <XCircle size={24} color={'#4ade80'} weight="fill" />
    </div>
  );
};
