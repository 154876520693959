import React, { useEffect, useRef, useState } from 'react';
import FloorplanA from '../../assets/img/floorplanA.png';
import FloorplanB from '../../assets/img/floorplanB.png';
import FloorplanC from '../../assets/img/floorplanC.png';
import FloorplanD from '../../assets/img/floorplanD.png';
import FloorplanE from '../../assets/img/floorplanE.png';

import { Box, Button, Loader, Text } from '@ies-ds/react-ui';
import { SurfaceColour, SurfaceColour2, SurfaceColour3 } from '../config/app';
import Surface from '../ui/Surface';
import { level2 } from '../ui/colors';
import styled from 'styled-components';
import {
  ArrowsIn,
  BoundingBox,
  Broadcast,
  Check,
  CheckCircle,
  CircleDashed,
  Minus,
  NavigationArrow,
  Plus,
  Spinner,
  SpinnerGap,
  Warning,
  WarningCircle,
  X,
} from '@phosphor-icons/react';
import moment from 'moment';
import { useShowResultDataQuery } from '../../hooks';
import { Link } from 'react-router-dom';

import { TransformWrapper, TransformComponent, KeepScale, MiniMap } from 'react-zoom-pan-pinch';
import FloorStatusPin from '../../components/ui/FloorStatusPin';

const StyledOverviewLink = styled(Link)`
  display: block;
  width: 100%;
  padding: 2px 2px;
  &:hover {
    background-color: ${level2};
    text-decoration: none;
  }
`;

const LoadingPin = styled(Box)`
  .image {
    background-color: #4a5868;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    margin: 0;
    padding: 1px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const BuildingFloorplan = ({ floor, building }) => {
  const [imageExists, setImageExists] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [floorplan, setFloorplan] = useState('');

  useEffect(() => {
    const img = new Image();
    img.src = `/assets/floorplans/${floor.id}/floorplan.png`;

    img.onload = function () {
      setImageExists(true);
      setIsLoading(false);
      setFloorplan(img.src);
    };

    img.onerror = function () {
      setImageExists(false);
      setIsLoading(false);
    };
  }, [floor.id]);

  if (!imageExists) {
    return null;
  }

  if (isLoading) {
    return <Loader type="dots" size="xsmall" />;
  }

  return (
    <>
      <Surface
        bgColor={level2}
        style={{ padding: '24px 12px', marginBottom: '32px', minHeight: '500px', width: '100%' }}
      >
        <Box alignItems="center" justifyContent="center" style={{ position: 'relative', width: '100%' }}>
          {' '}
          <TransformWrapper initialScale={1} minScale={0.6} centerOnInit style={{ overflow: 'unset' }}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                {' '}
                {/* <MiniMap width={200}>
                  <img src={FloorplanA} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </MiniMap> */}
                <TransformComponent
                  wrapperStyle={{
                    width: '100%',
                    height: '500px',
                  }}
                >
                  {floor.zones?.map((zone) => (
                    <BuildingSensorPin
                      key={zone.id}
                      floor={floor}
                      zone={zone}
                      x={zone.xPoint}
                      y={zone.yPoint}
                      buildingName={building.scanBuildingName}
                    />
                  ))}{' '}
                  <img src={floorplan} style={{ maxWidth: '100%', maxHeight: '100%', height: '500px' }} />
                </TransformComponent>{' '}
                <Box direction="column" gap={6} className="tools" style={{ position: 'absolute', top: 0, right: 50 }}>
                  <Button color="primary" size="small" onClick={() => zoomIn()} style={{ padding: '5px' }}>
                    <Plus size={20} weight="bold" />
                  </Button>
                  <Button color="primary" size="small" onClick={() => zoomOut()} style={{ padding: '5px' }}>
                    <Minus size={20} weight="bold" />
                  </Button>
                  <Button color="primary" size="small" onClick={() => resetTransform()} style={{ padding: '5px' }}>
                    <NavigationArrow size={20} weight="bold" />
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </TransformWrapper>
        </Box>
      </Surface>
    </>
  );
};

export default BuildingFloorplan;

let startDatChart = moment().subtract(0, 'years').subtract(2, 'days').toISOString().substring(0, 10);

const SensorStatus = ({ sensor, buildingName }) => {
  const datavalue = useShowResultDataQuery(
    sensor.channel.trim(),
    '60',
    0,
    startDatChart,
    'HighlandCouncil',
    buildingName
  );
  return (
    <Box gap={0} alignItems="center">
      {isNaN(datavalue) || null ? (
        <Box alignItems="center" gap={6} style={{ padding: '0 3px' }}>
          <WarningCircle size={12} color={'#ffd314'} weight="fill" />
          <Box direction="row" gap={6}>
            <Text weight="medium" style={{ whiteSpace: 'nowrap' }}>
              {datavalue}
            </Text>
            <Text color="lighter" style={{ whiteSpace: 'nowrap' }}>
              {sensor.type.unit}
            </Text>
          </Box>
        </Box>
      ) : (
        <StyledOverviewLink to={'sensor/' + sensor.id}>
          <Box alignItems="center" gap={6}>
            {null ||
            (datavalue >= (sensor.type.disableMin ? -Infinity : sensor.type.min) &&
              datavalue <= (sensor.type.disableMax ? Infinity : sensor.type.max)) ? (
              <CheckCircle size={12} color="#8ae15b" weight="fill" />
            ) : (
              <WarningCircle size={12} color={'rgb(239, 68, 67)'} weight="fill" />
            )}
            <Box direction="row" gap={6}>
              <Text weight="medium" style={{ whiteSpace: 'nowrap' }}>
                {datavalue}
              </Text>
              <Text color="lighter" style={{ whiteSpace: 'nowrap' }}>
                {sensor.type.unit}
              </Text>
            </Box>
          </Box>
        </StyledOverviewLink>
      )}
    </Box>
  );
};

const SensorStatusPin = ({ sensor, buildingName, position }) => {
  const datavalue = useShowResultDataQuery(sensor.channel, '60', 0, startDatChart, 'HighlandCouncil', buildingName);

  return (
    <div style={{ position: 'absolute' }}>
      {position === 'external' ? (
        <FloorStatusPin position={position} status="external" />
      ) : (
        <>
          {typeof datavalue == 'undefined' ? (
            <FloorStatusPin position={position} status="unknown" />
          ) : datavalue === null || isNaN(datavalue) ? (
            <FloorStatusPin position={position} status="warning" />
          ) : datavalue >= (sensor.type.disableMin ? -Infinity : sensor.type.min) &&
            datavalue <= (sensor.type.disableMax ? Infinity : sensor.type.max) ? (
            <FloorStatusPin position={position} status="good" />
          ) : (
            <FloorStatusPin position={position} status="bad" />
          )}
        </>
      )}
    </div>
  );
};

const BuildingSensorPin = ({ floor, zone, x = '22', y = '20', warning = false, buildingName }) => {
  const [showPanel, setShowPanel] = useState(false);

  function onClickHandler() {
    // alert(zone ? zone.name : 'Not Set');
    setShowPanel(!showPanel);
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //alert('You clicked outside of me!');
          setShowPanel(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef} style={{ position: 'absolute', top: `calc(${y}% - 12px)`, left: `calc(${x}% - 12px)` }}>
      {/* <KeepScale> */}
      <Box
        gap={2}
        direction="column"
        style={{ position: 'relative', cursor: 'pointer' }}
        onClick={() => onClickHandler()}
      >
        <FloorStatusPin position={'all'} status={'unknown'} />
        {floor?.sensors?.map(
          (sensor) =>
            sensor.zoneId === zone.id &&
            sensor.sensorTypeId === 1 && <SensorStatusPin sensor={sensor} buildingName={buildingName} position="tl" />
        )}{' '}
        {floor?.sensors?.map(
          (sensor) =>
            sensor.zoneId === zone.id &&
            sensor.sensorTypeId === 9 && (
              <SensorStatusPin sensor={sensor} buildingName={buildingName} position="external" />
            )
        )}
        {floor?.sensors?.map(
          (sensor) =>
            sensor.zoneId === zone.id &&
            sensor.sensorTypeId === 3 && <SensorStatusPin sensor={sensor} buildingName={buildingName} position="tr" />
        )}
        {floor?.sensors?.map(
          (sensor) =>
            sensor.zoneId === zone.id &&
            sensor.sensorTypeId === 10 && (
              <SensorStatusPin sensor={sensor} buildingName={buildingName} position="external" />
            )
        )}
        {floor?.sensors?.map(
          (sensor) =>
            sensor.zoneId === zone.id &&
            sensor.sensorTypeId === 4 && <SensorStatusPin sensor={sensor} buildingName={buildingName} position="bl" />
        )}
        {floor?.sensors?.map(
          (sensor) =>
            sensor.zoneId === zone.id &&
            sensor.sensorTypeId === 2 && <SensorStatusPin sensor={sensor} buildingName={buildingName} position="br" />
        )}
        {showPanel && (
          <div
            style={{
              position: 'absolute',
              top: '7px',
              left: '7px',
              borderRadius: '8px',
              width: '11px',
              height: '11px',
              background: '#a0b1c1',
              border: '3px solid #223046',
              zIndex: '21',
            }}
          ></div>
        )}
      </Box>
      {showPanel && (
        <div
          onClick={() => onClickHandler()}
          style={{
            position: 'absolute',
            bottom: y >= 50 ? '14px' : undefined,
            right: x >= 50 ? '14px' : undefined,
            zIndex: '20',
            background: '#223046',
            marginTop: '-15px',
            padding: '6px',
            marginLeft: '10px',
            // border: '1px solid #3b4862',
            borderRadius: '4px',
            boxShadow: '0 0 8px 0 rgba(0,0,0,.7)',
          }}
        >
          <Box gap={4}>
            <Text
              weight="medium"
              style={{ padding: '0 4px 6px 4px', borderBottom: '1px solid #3b4862', marginBottom: '8px' }}
            >
              {' '}
              {zone.name}
            </Text>
          </Box>
          <Box gap={4} direction="column">
            {floor?.sensors?.map(
              (sensor) =>
                sensor.zoneId === zone.id &&
                sensor.sensorTypeId === 1 && <SensorStatus sensor={sensor} buildingName={buildingName} />
            )}
            {floor?.sensors?.map(
              (sensor) =>
                sensor.zoneId === zone.id &&
                sensor.sensorTypeId === 9 && <SensorStatus sensor={sensor} buildingName={buildingName} />
            )}
            {floor?.sensors?.map(
              (sensor) =>
                sensor.zoneId === zone.id &&
                sensor.sensorTypeId === 3 && <SensorStatus sensor={sensor} buildingName={buildingName} />
            )}
            {floor?.sensors?.map(
              (sensor) =>
                sensor.zoneId === zone.id &&
                sensor.sensorTypeId === 10 && <SensorStatus sensor={sensor} buildingName={buildingName} />
            )}
            {floor?.sensors?.map(
              (sensor) =>
                sensor.zoneId === zone.id &&
                sensor.sensorTypeId === 4 && <SensorStatus sensor={sensor} buildingName={buildingName} />
            )}
            {floor?.sensors?.map(
              (sensor) =>
                sensor.zoneId === zone.id &&
                sensor.sensorTypeId === 2 && <SensorStatus sensor={sensor} buildingName={buildingName} />
            )}
          </Box>

          {/* <table>{floor?.sensors?.map((sensor) => sensor.zoneId === zone.id && <SensorStatusTr sensor={sensor} />)}</table> */}
        </div>
      )}
      {/* </KeepScale> */}
    </div>
  );
};
