import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const floorApi = createApi({
  reducerPath: 'floorApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:3003/building-api' }),
  tagTypes: ['Floor'],
  endpoints: (build) => ({
    getAllFloors: build.query({
      query: () => ({
        url: `/floors`,
      }),
      providesTags: ['Floor'],
    }),
    getFloorById: build.query({
      query: (id) => ({
        url: `/floors/${id}`,
      }),
      providesTags: ['Floor'],
    }),
    getFloorsByBuildingId: build.query({
      query: (id) => ({
        url: `/floors/building/${id}`,
      }),
      providesTags: ['Floor'],
    }),
    createFloor: build.mutation({
      query: (body) => ({
        url: `/floors`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Floor', 'Building'],
    }),
    deleteFloor: build.mutation({
      query: (id) => ({
        url: `/floors/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Floor', 'Building'],
    }),
    updateFloor: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/floors/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['Floor'],
    }),
  }),
});

export const {} = floorApi;
