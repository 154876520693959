import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetSensorByIdQuery } from '../services/building';
import { Box, Grid, Loader, Text } from '@ies-ds/react-ui';
import { ArrowLeft, Broadcast } from '@phosphor-icons/react';
import ApexChart from './ui/ApexBar';
import ChartWrapper from './ui/ChartWrapper';
import StatusKPI from './ui/StatusKpi';
import Surface from './ui/Surface';
import { level2 } from './ui/colors';
import BasicKPI from './kpi/BasicKpi';

const SensorDetail = ({ building }) => {
  const { sensorId } = useParams();

  const { isLoading, isFetching, data: sensor, error } = useGetSensorByIdQuery(sensorId);

  if (isLoading || isFetching)
    return (
      <Box
        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        alignItems="center"
        justifyContent="center"
      >
        <Loader size="small" type="dots" />
      </Box>
    );

  return (
    <div>
      <Link to={'../'} style={{ color: '#3b82f6', fontSize: '14px', margin: '0px 0 8px 0', display: 'block' }}>
        <Box alignItems="center" gap={4}>
          <ArrowLeft color="#3b82f6" size="16" /> Back
        </Box>
      </Link>
      <Box gap={12} alignItems="center">
        <Broadcast size={28} color={'#18b951'} />
        <Text size="2xl" color="dark">
          {sensor.name}
        </Text>
      </Box>
      <Text size="small" color="lighter">
        <Box direction="row" gap={8}>
          {sensor.floors?.name && <>{sensor.floors?.name}</>}
          {sensor.zones?.name && (
            <Box direction="row" gap={8}>
              <span> |</span>
              {sensor.zones?.name}
            </Box>
          )}
          {sensor?.channel && (
            <Box direction="row" gap={8}>
              <span> |</span>
              {sensor?.channel}
            </Box>
          )}
          {sensor?.type?.name && (
            <Box direction="row" gap={8}>
              <span> |</span>
              {sensor?.type?.name}
            </Box>
          )}
          {sensor?.type?.description && (
            <Box direction="row" gap={8}>
              <span> |</span>
              {sensor?.type?.description}
            </Box>
          )}
        </Box>
      </Text>
      {/* <ApexChart /> */}
      <br />
      <Grid fullwidth>
        <Grid.Col sm={12} md={2}>
          <StatusKPI
            to={'pm2-5'}
            label={sensor?.type.name}
            channel={sensor?.channel}
            unit={sensor?.type.unit}
            min={sensor?.type.min}
            max={sensor?.type.max}
            disableMax={sensor?.type.disableMax}
            disableMin={sensor?.type.disableMin}
            building={building.scanBuildingName}
          />
        </Grid.Col>
        <>
          {sensor.type.disableMin ? null : (
            <Grid.Col sm={12} md={2}>
              <BasicKPI
                to={'pm2-5'}
                label={'Lower Limit'}
                value={sensor?.type.min}
                unit={sensor?.type.unit}
                min={sensor?.type.min}
                max={sensor?.type.max}
              />
            </Grid.Col>
          )}
          {sensor.type.disableMax ? null : (
            <Grid.Col sm={12} md={2}>
              <BasicKPI
                to={'pm2-5'}
                label={'Upper Limit'}
                value={sensor?.type.max}
                unit={sensor?.type.unit}
                min={sensor?.type.min}
                max={sensor?.type.max}
              />
            </Grid.Col>
          )}
        </>

        {/* <Grid.Col sm={12} md={8}>
          <Surface bgColor={level2} style={{ padding: '20px' }}>
            <Text size="large" color="dark" weight="medium" style={{ marginBottom: '8px' }}>
              {sensor?.type.name}
            </Text>
            <Text color="lighter">{sensor?.type.description}</Text>
          </Surface>
        </Grid.Col> */}
        <Grid.Col sm={12} md={12}>
          <Surface height="300px" bgColor={level2}>
            <ChartWrapper
              channelId={sensor?.channel}
              unit={sensor?.type.unit}
              min={sensor?.type.min}
              max={sensor?.type.max}
              maxDisabled={sensor?.type.disableMax}
              minDisabled={sensor?.type.disableMin}
              building={building.scanBuildingName}
            />
          </Surface>{' '}
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default SensorDetail;
