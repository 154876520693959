import React from 'react';
import Page from '../../components/ui/Page';
import { Box, Text, Textfield } from '@ies-ds/react-ui';
import ContentWindow from '../../components/ui/ContentWindow';
import Breadcrumbs from '../../components/ui/Breadcrumbs';

const PageSettings = () => {
  return (
    <>
      <div style={{ width: '260px', background: '#fff', borderRight: '1px solid #e7e5ed', padding: '32px' }}>
        <Text weight="medium" size="large">
          {' '}
          Settings
        </Text>{' '}
        <br />
        <Box direction="column" gap={12}>
          <Text color="blue"> My Details</Text>
          <Text color="light"> Profile</Text>
          <Text color="light"> Password</Text>
          <Text color="light"> Notifications</Text>
          <Text color="light"> Team</Text>
          <Text color="light"> Billing</Text>
          <Text color="light"> Integrations</Text>
          <Text color="light"> Security</Text>
          <Text color="light"> Help</Text>
        </Box>
      </div>
      <ContentWindow>
        <Breadcrumbs />
        <br />
        <Text size={'2xl'} weight="bold">
          My Details
        </Text>
        <br />
        <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sodales semper diam ac fringilla </Text>
        <br />
        <div style={{ maxWidth: '440px' }}>
          <Textfield label="Name" size={'small'} />

          <Textfield label="Email" size={'small'} />

          <Textfield label="Phone" size={'small'} />

          <Textfield label="Address" size={'small'} />

          <Textfield label="City" size={'small'} />
        </div>
      </ContentWindow>
      {/* <Page title="Settings" element={<ContentWindow>Content</ContentWindow>} /> */}
    </>
  );
};

export default PageSettings;
