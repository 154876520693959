import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { msalConfig } from '../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import config from '../config';

//const buildings_api = process.env.REACT_APP_BUILDING_API_URL;
const buildings_api = config.apiRoot;

const msalInstance = new PublicClientApplication(msalConfig);

export const invitationApi = createApi({
  reducerPath: 'invitationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${buildings_api}/building-api`,

    prepareHeaders: async (headers) => {
      try {
        const account = msalInstance.getAllAccounts()[0];
        const token = await msalInstance.acquireTokenSilent({
          //scopes: [`${process.env.REACT_APP_CLIENT_SCOPE}`], // API scopes
          scopes: [`${config.apiScope}`], // API scopes
          forceRefresh: false, // set to true to skip cache
          account: account,
        });
        if (token) {
          headers.set('authorization', `Bearer ${token.accessToken}`);
          headers.set('x-requested-with', 'XMLHttpRequest');
          headers.set('Accept', 'application/json');
          let currentTime = new Date();
          localStorage.setItem('accessTokenIESConnectTime', currentTime);
          //localStorage.setItem('accessTokenIESConnect', token.accessToken);
        }
        return headers;
      } catch (e) {
        window.location.href = '/login';
        console.log(e);
      }
    },
  }),
  tagTypes: ['Invitation'],
  endpoints: (builder) => ({
    getAllInvitations: builder.query({
      query: () => '/invitations',
      providesTags: ['Invitation'],
    }),
    deleteInvitation: builder.mutation({
      query: (id) => ({
        url: `/invitations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Invitation'],
    }),
    postSendInvitation: builder.mutation({
      query: (body) => ({
        url: '/invitations/send-invitation',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Invitation'],
    }),
    getAcceptInvitation: builder.query({
      query: (token) => ({
        url: `/invitations/accept-invitation/${token}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAllInvitationsQuery,
  usePostSendInvitationMutation,
  useGetAcceptInvitationQuery,
  useDeleteInvitationMutation,
} = invitationApi;
