import React from 'react';
import { useGetAllBuildingsQuery } from '../../services/building';
import { Box, Table, Text, DataTable, Button } from '@ies-ds/react-ui';
import BuildingDelete from './BuildingDelete';
import FloorCreate from '../floor/FloorCreate';
import { Link } from 'react-router-dom';
import BuildingEdit from './BuildingEdit';
import StatusBadge from '../../components/ui/StatusBadge';
import styled from 'styled-components';

const StyledDataTableContainer = styled.div`
  table {
    thead {
      th {
        font-weight: 600;
        padding: 4px 12px !important;
      }
    }
    tbody {
      td {
        padding: 0 12px !important;
      }
    }
  }
`;
const StyledLink = styled(Link)`
  color: #376bd3;
  border-radius: 4px;
  display: block;
  padding: 12px 0;
  &:hover {
    text-decoration: none;
    color: #154b85;
  }
  &.active {
    background: #000;
  }
`;

export const columns = [
  // {
  //   name: 'First Name',
  //   accessor: 'firstName',
  //   width: '100px',
  //   Cell: ({ value }) => (
  //     <Box gap={4} alignItems="center">
  //       55
  //       {/* <Avatar
  //         size={'small'}
  //         src={`https://i.pravatar.cc/150?u=${value}`}
  //       />
  //       <Text style={{ minWidth: '100px' }}>{value}</Text> */}
  //     </Box>
  //   ),
  // },
  {
    name: 'Name',
    accessor: 'name',
    width: '240px',
    Cell: ({ row }) => (
      <Text weight="medium">
        <StyledLink to={`${row.original.id}`}>{row.original.name}</StyledLink>
      </Text>
    ),
  },
  {
    name: 'Status',
    accessor: 'active',
    width: '100px',
    disableFilters: true,
    Cell: ({ value }) => (
      <Box gap={4} alignItems="center" justifyContent="center" style={{ width: '50px' }}>
        {/* <Avatar
              size={'small'}
              src={`https://i.pravatar.cc/150?u=${value}`}
            />
            <Text style={{ minWidth: '100px' }}>{value}</Text> */}
        {value ? <StatusBadge status={'good'}>Active</StatusBadge> : <StatusBadge status={'bad'}>Inactive</StatusBadge>}
      </Box>
    ),
  },
  {
    name: 'Location',
    accessor: 'location',
    width: '290px',
    Cell: ({ value }) => <Text color="lighter"> {value}</Text>,
    // Filter: DataTable.NumberRangeColumnFilter,
    // filter: 'between',
  },
  {
    name: 'Scan Building Name',
    accessor: 'scanBuildingName',
    disableFilters: true,
    width: '190px',
  },
  {
    name: 'Floors',
    accessor: 'floors',
    disableFilters: true,
    width: '40px',
    Cell: ({ value }) => (
      <Box gap={4} alignItems="center" justifyContent="center" style={{ width: '40px' }}>
        <Text align="center"> {value?.length}</Text>
      </Box>
    ),
  },
  {
    name: 'Rooms',
    accessor: 'zones',
    disableFilters: true,
    width: '40px',
    Cell: ({ value }) => (
      <Box gap={4} alignItems="center" justifyContent="center" style={{ width: '40px' }}>
        {value?.length}
      </Box>
    ),
  },
  {
    name: 'Sensors',
    accessor: 'sensors',
    disableFilters: true,
    width: '40px',
    Cell: ({ value }) => (
      <Box gap={4} alignItems="center" justifyContent="center" style={{ width: '40px' }}>
        {value?.length}
      </Box>
    ),
  },
  {
    name: 'Window Sensors',
    accessor: 'windowSensorCount',
    disableFilters: true,
    width: '40px',
    Cell: ({ value }) => (
      <Box gap={4} alignItems="center" justifyContent="center" style={{ width: '40px' }}>
        {value}
      </Box>
    ),
  },

  {
    name: '',
    accessor: 'id',
    disableFilters: true,

    Cell: ({ row }) => (
      <Box justifyContent="flex-end" gap={6}>
        {/* <FloorCreate id={building.id} /> */}
        <Button color="default-primary" as={Link} to={`/dashboard-admin/buildings/${row.original.id}`} size="xsmall">
          View
        </Button>
        <BuildingEdit building={row?.original} />
        <BuildingDelete id={row?.original.id} />
      </Box>
    ),
  },
];

const BuildingList = () => {
  const { isLoading, data: buildings, error } = useGetAllBuildingsQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <StyledDataTableContainer>
      <DataTable
        id="Schools"
        columns={columns}
        data={buildings}
        enableFiltering
        enableGlobalSearch
        //enablePagination
        //enableRecordCount
        //enableRowSelection
        //enableSelectionDevJson
      />
    </StyledDataTableContainer>
  );
};

export default BuildingList;
