import { Box, Text } from '@ies-ds/react-ui';
import { CheckCircle, CircleDashed, WarningCircle } from '@phosphor-icons/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { level2 } from '../ui/colors';
import moment from 'moment';
import Surface from '../ui/Surface';

let startDatChart = moment().subtract(0, 'years').subtract(10, 'days').toISOString().substring(0, 10);

export const BasicKPI = ({ to = 'linkTest', value, label = 'Label', unit = '', status = true, min = 50, max = 70 }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Surface bgColor={level2} padding="20px 4px" height={'100%'}>
        <Box direction="column" justifyContent="space-between" gap={12}>
          <Text align="center" size="regular" weight="medium" color="lighter" style={{ height: '35px' }}>
            {label}
          </Text>

          <Box justifyContent="center" alignItems="center" gap={8}>
            <Box justifyContent="center">
              {/* <Dot status={status} /> */}
              {/* {isNaN(datavalue) ? (
                <CircleDashed size={20} color="#666" opacity={0.5} />
              ) : datavalue >= min && datavalue <= max ? (
                <CheckCircle weight="fill" size={20} color="rgb(74, 222, 128)" />
              ) : (
                <WarningCircle weight="fill" size={20} color="rgb(239, 68, 67)" />
              )} */}
            </Box>
            <Text align="center" size="2xl" weight="bold" color="dark">
              {value}
            </Text>
            <Text size="large" color="lighter">
              {unit}
            </Text>
          </Box>
        </Box>
      </Surface>
    </Link>
  );
};

export default BasicKPI;
