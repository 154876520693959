import React from 'react';
import ContentWindow from '../../components/ui/ContentWindow';
import { Box, Container, Text } from '@ies-ds/react-ui';
import { useGetAllBuildingsQuery } from '../../services/building';
import BuildingList from '../building/BuildingList';
import BuildingCreate from '../building/BuildingCreate';
import { Route, Routes } from 'react-router-dom';
import FloorListByBuilding from '../floor/FloorListByBuilding';
import SensorListByBuilding from '../sensor/SensorListByBuilding';
import ZoneListByBuilding from '../zone/ZoneListByBuilding';
import PageBuildingDetail from './PageBuildingDetail';

const PageBuildings = () => {
  return (
    <Routes>
      <Route path="/:id/*" element={<PageBuildingDetail />} />
      <Route
        path="/*"
        element={
          <>
            {' '}
            <ContentWindow>
              <Box justifyContent="space-between" alignItems="center">
                <Text weight="medium" size="large">
                  Highland Council Building Portfolio
                </Text>
                <BuildingCreate />
              </Box>
              <br />
              <Container>
                <BuildingList />
              </Container>{' '}
            </ContentWindow>
          </>
        }
      />

      <Route path="*" element={<Text>Not found</Text>} />
    </Routes>
  );
};

export default PageBuildings;
