import { Box, Loader, Text } from '@ies-ds/react-ui';
import { CheckCircle, CircleDashed, WarningCircle } from '@phosphor-icons/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Surface from './Surface';
import { level2 } from './colors';
import { useShowResultDataQuery } from '../../hooks';
import moment from 'moment';

let startDatChart = moment().subtract(0, 'years').subtract(2, 'days').toISOString().substring(0, 10);

export const StatusKPI = ({
  to = 'linkTest',
  value,
  label = 'Label',
  unit = '',
  status = true,
  min = 50,
  max = 70,
  channel = 'SC000064',
  project = 'HighlandCouncil',
  building,
  disableMax = false,
  disableMin = false,
}) => {
  const datavalue = useShowResultDataQuery(channel, '60', 0, startDatChart, project, building);

  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Surface bgColor={level2} padding="20px 4px" height={'100%'}>
        <Box direction="column" justifyContent="space-between" gap={12}>
          <Text align="center" size="regular" weight="medium" color="lighter" style={{ height: '35px' }}>
            {label}
          </Text>

          <Box justifyContent="center" alignItems="center" gap={8}>
            <Box justifyContent="center">
              {/* <Dot status={status} /> */}

              {typeof datavalue === 'undefined' ? (
                <Loader type="dots" size="xsmall" />
              ) : isNaN(datavalue) ? (
                ''
              ) : datavalue >= (disableMin ? -Infinity : min) && datavalue <= (disableMax ? Infinity : max) ? (
                <CheckCircle weight="fill" size={20} color="rgb(74, 222, 128)" />
              ) : (
                <WarningCircle weight="fill" size={20} color="rgb(239, 68, 67)" />
              )}
            </Box>
            <Text align="center" size="2xl" weight="bold" color="dark">
              {datavalue}
            </Text>
            <Text size="large" color="lighter">
              {unit}
            </Text>
          </Box>
        </Box>
      </Surface>
    </Link>
  );
};

export default StatusKPI;
