import { Box, Grid, Loader } from '@ies-ds/react-ui';
import React from 'react';
import Tab from '../../components/ui/Tab';
import ContentWindow from '../../components/ui/ContentWindow';
import { Route, Routes } from 'react-router-dom';
import RoleList from '../../components/role/RoleList';
import UserList from '../../components/user/UserList';
import InvitationList from '../invitation/InvitationList';
import useCheckRole from '../../hooks/useCheckRole';

const PageAuthorisation = ({ role = 'SUPERADMIN' }) => {
  const { hasRole, isLoadingRole } = useCheckRole(role);

  if (isLoadingRole) {
    return <Loader type="dots" />;
  }

  if (!hasRole) {
    return (
      <Box style={{ minHeight: '48px', padding: '0 32px' }} alignItems="flex-end">
        Not Authorised!
      </Box>
    );
  }

  return (
    <>
      <Box
        style={{ minHeight: '48px', padding: '0 32px', borderBottom: '1px solid #f3f2f7', background: '#fff' }}
        alignItems="flex-end"
      >
        <Tab to={''} end>
          Users
        </Tab>
        <Tab to={'invitations'}>Invitations</Tab>
        <Tab to={'roles'}>Roles</Tab>
        <Tab to={'role-settings'}>Role Settings</Tab>
      </Box>
      <ContentWindow>
        <Grid fullwidth>
          <Grid.Col span={12}>
            <Routes>
              <Route path="/" element={<UserList />} />
              <Route path="/roles" element={<RoleList />} />
              <Route path="/invitations" element={<InvitationList />} />
              <Route path="/role-settings" element={'role-settings'} />
            </Routes>
          </Grid.Col>
        </Grid>
      </ContentWindow>
    </>
  );
};

export default PageAuthorisation;
