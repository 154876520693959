// src/msalMiddleware.js
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';

const msalInstance = new PublicClientApplication(msalConfig);
//import msalInstance from '../authConfig';

const msalMiddleware = (storeAPI) => (next) => async (action) => {
  if (action.type.includes('/pending')) {
    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      try {
        const tokenRequest = {
          scopes: ['openid', 'offline_access'],
          account: accounts[0],
        };
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        const accessToken = response.accessToken;
        action.meta.arg.headers = {
          ...action.meta.arg.headers,
          Authorization: `Bearer ${accessToken}`,
        };
      } catch (error) {
        console.error('Failed to acquire token', error);
      }
    }
  }
  return next(action);
};

export default msalMiddleware;
