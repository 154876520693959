import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetFloorsByBuildingIdQuery } from '../../services/building';
import { Box, Container, Grid, Loader, Table, Text } from '@ies-ds/react-ui';
import { useGetBuildingByIdQuery } from '../../services/building';
import FloorCreate from './FloorCreate';
import FloorDelete from './FloorDelete';

import { BoundingBox, Broadcast, CaretLeft, StackSimple } from '@phosphor-icons/react';
import FloorEdit from './FloorEdit';
import styled from 'styled-components';
import ZoneCreate from '../zone/ZoneCreate';
import ZoneDelete from '../zone/ZoneDelete';
import ZoneEdit from '../zone/ZoneEdit';
import ZoneSensorCreate from '../zone/ZoneSensorCreate';
import ZoneSensorEdit from '../zone/ZoneSensorEdit';
import SensorDelete from '../sensor/SensorDelete';
import ZoneSetLocation from '../zone/ZoneSetLocation';

const StyledFloorRow = styled(Box)`
  border-bottom: 1px dashed #d4dfe9;
  padding: 10px 16px;
  min-height: 40px;

  .actions {
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    background-color: #f8f9fb;
    .actions {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const StyledSensorActionBtn = styled(Text)`
  font-size: 12px;
  color: #2563eb;
  cursor: pointer;
  &:hover {
    color: #000;
    text-decoration: underline;
  }
`;

const FloorListByBuilding = () => {
  const { id } = useParams();

  const { isLoading, data: floors, error } = useGetFloorsByBuildingIdQuery(id);
  const { isLoading: isLoadingBuilding, data: building } = useGetBuildingByIdQuery(id);

  if (isLoadingBuilding) return 'Loading';

  return (
    <Grid>
      <Grid.Col sm={12}>
        <Box justifyContent="space-between" alignItems="center">
          <FloorCreate id={id} />
        </Box>
        <br />

        {isLoading ? (
          'Loader'
        ) : floors.length === 0 ? (
          <tr>
            <td colSpan={3}>
              <Text>No floors</Text>
            </td>
          </tr>
        ) : (
          floors.map(
            (floor) => (
              console.log('floor', floor),
              (
                <Container
                  key={floor.id}
                  shadow="low"
                  style={{ borderRadius: '4px', marginBottom: '24px', userSelect: 'none' }}
                >
                  <Box justifyContent="space-between">
                    <Box justifyContent="flex-start" gap={6}>
                      <StackSimple size={24} color={'#3b82f6'} />
                      <Box gap={6} alignItems="start" direction="column">
                        <Text size="xlarge" weight="bold">
                          {floor.name}
                        </Text>
                        <Text size="small" color="lighter">
                          ID: {floor.id} | Sort Order :{floor.sort_order}
                        </Text>{' '}
                      </Box>
                    </Box>
                    <Box justifyContent="flex-end" gap={6}>
                      <ZoneCreate id={id} floorId={floor.id} />
                      <FloorEdit floor={floor} />
                      <FloorDelete id={floor.id} />
                    </Box>
                  </Box>
                  <div style={{ borderTop: '1px dashed #d4dfe9', marginTop: '12px' }}>
                    {floor.zones.length == 0 ? (
                      <StyledFloorRow>
                        {' '}
                        <Text color="lighter">No Rooms Added Yet</Text>
                      </StyledFloorRow>
                    ) : (
                      floor.zones.map((zone) => (
                        <StyledFloorRow key={zone.id} gap={4} alignItems="flex-start" justifyContent="space-between">
                          <Box gap={6} alignItems="center" flex={1}>
                            <BoundingBox size={18} color={'#f6803b'} />
                            <Text>{zone.name}</Text>
                          </Box>
                          <Box gap={6} alignItems="flex-start" flex={2} direction="column">
                            {zone.sensors.map((sensor) => (
                              <Box
                                alignItems="end"
                                gap={12}
                                style={{
                                  border: '1px solid #cfdeed',
                                  padding: '2px 8px',
                                  borderRadius: '8px',
                                  background: '#fff',
                                }}
                              >
                                <Box gap={10}>
                                  <Broadcast size={17} color={'#0e953e'} />{' '}
                                  <Text size="small" weight="medium">
                                    {sensor.name}
                                  </Text>{' '}
                                </Box>
                                <Text size="xsmall" color="lightest" transform="capitalize">
                                  | {sensor.type.name} - {sensor.type.description} - {sensor.type.location} |{' '}
                                  {sensor.channel}
                                </Text>
                                <Box gap={6} alignItems="center">
                                  <ZoneSensorEdit
                                    buildingId={id}
                                    zoneId={zone.id}
                                    sensor={sensor}
                                    floorId={floor.id}
                                    trigger={<StyledSensorActionBtn>Edit</StyledSensorActionBtn>}
                                  />
                                  <SensorDelete
                                    sensor={sensor}
                                    trigger={<StyledSensorActionBtn>Delete</StyledSensorActionBtn>}
                                  />
                                </Box>
                              </Box>
                            ))}
                          </Box>
                          {/* <Box gap={6} alignItems="center" flex={1}>
                          sensors: {zone.sensors.length}
                        </Box> */}

                          <Box gap={6} className="actions" flex={1} justifyContent="flex-end">
                            <ZoneSensorCreate buildingId={id} floorId={floor.id} zoneId={zone.id} />
                            <ZoneSetLocation floorId={zone.floorId} zone={zone} building={building} />
                            <ZoneEdit zone={zone} id={zone.floorId} />
                            <ZoneDelete id={zone.id} />
                          </Box>
                        </StyledFloorRow>
                      ))
                    )}
                  </div>
                </Container>
              )
            )
          )
        )}
        {/* <br />
      <Container shadow="low">
        <Table>
          <tr>
            <th style={{ width: '20px' }}>ID</th>
            <th>Name</th>
            <th>buildingId</th>
            <th></th>
          </tr>
          {isLoading ? (
            'Loader'
          ) : floors.length === 0 ? (
            <tr>
              <td colSpan={3}>
                <Text>No floors</Text>
              </td>
            </tr>
          ) : (
            floors.map((floor) => (
              <>
                <tr style={{ margin: '8px 0' }}>
                  <td>{floor.id}</td>
                  <td>{floor.name}</td>
                  <td>{floor.buildingId}</td>
                  <td>
                    <Box justifyContent="flex-end">
                      <FloorEdit floor={floor} />
                      <FloorDelete id={floor.id} />
                    </Box>
                  </td>
                </tr>
              </>
            ))
          )}
        </Table>
      </Container> */}
      </Grid.Col>
    </Grid>
  );
};

export default FloorListByBuilding;
