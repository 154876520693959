import { Box, dark, ThemeProvider } from '@ies-ds/react-ui';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Buildings from './Buildings/Buildings';
import useCheckRole from '../hooks/useCheckRole';
import PageLoading from './ui/PageLoading';
import PageNotAuthorised from './ui/PageNotAuthorised';
import { useGetUserByEmailQuery } from '../services/user';
import { useMsal } from '@azure/msal-react';

export const StyledDashboardContainer = styled.div`
  background-color: #1e293b;
  position: fixed;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: auto;
`;

export const PanelLv1 = styled.div`
  background-color: #1a2332;
  padding: 20px;
  border-radius: 4px;
`;

const LayoutDashboardV2 = () => {
  const { accounts } = useMsal();
  const { email, sub } = accounts[0]?.idTokenClaims;
  const { isLoading } = useGetUserByEmailQuery({ idp_user_id: sub, email: email }); // CREATE USER IF NOT EXIST - TO BE REFACTORED

  const { isLoadingRole, notAuthorised } = useCheckRole('VIEWER');

  if (isLoading || isLoadingRole) {
    return <PageLoading />;
  }

  //if (!/^[^@\s]+@iesve.com$/i.test(email)) {  **** check if IES EMAIL
  if (notAuthorised) {
    return <PageNotAuthorised />;
  }

  return (
    <ThemeProvider theme={dark}>
      <StyledDashboardContainer>
        <Box direction="column" flex={1} style={{ position: 'relative' }}>
          <Routes>
            <Route path="/" element={<Buildings />} />
            <Route path="/buildings/*" element={<Buildings />} />
            {/* <Route path="/buildings/*" element={<BuildingList />} /> */}
          </Routes>
        </Box>
      </StyledDashboardContainer>
    </ThemeProvider>
  );
};

export default LayoutDashboardV2;
