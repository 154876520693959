import { Container, Table, Text } from '@ies-ds/react-ui';
import { useGetAllUsersQuery } from '../../services/user';

function UserList() {
  const { data: users = [], isLoading, isError } = useGetAllUsersQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching users</div>;
  }

  return (
    <div>
      <Text size="large" weight="medium">
        Current active users
      </Text>
      <br />
      <Container shadow="low" style={{ borderRadius: '6px' }}>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>IDP User ID</th>
              <th>Status</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>{user.idp_user_id}</td>
                <td>{user.status}</td>
                <td>
                  {user.role.name} [{user.role.id}]
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default UserList;
