import { Loader } from '@ies-ds/react-ui';
import { Heart, X } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { useCreateFavoriteMutation, useDeleteFavoriteMutation } from '../../services/building';

const ToggleFavorite = ({ building, ...props }) => {
  const [isFavorite, setIsFavorite] = useState(building.isFavorite);

  const [createFavorite, { isLoading: isCreating }] = useCreateFavoriteMutation();
  const [deleteFavorite, { isLoading: isDeleting }] = useDeleteFavoriteMutation();

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const setFavorite = async () => {
    await createFavorite({
      buildingId: building.id,
      entity_type: 'building',
      entity_id: building.id,
    });
  };

  const unsetFavorite = async () => {
    await deleteFavorite(building.favorite.id);
  };

  if (isCreating || isDeleting) return <Loader type="dots" size="xsmall" />;

  return (
    <span {...props}>
      {isFavorite ? (
        // <PushPinSlash
        //   className="btnRemove"
        //   weight="bold"
        //   onClick={(e) => {
        //     e.preventDefault();
        //     unsetFavorite();
        //   }}
        // />
        <X
          size={16}
          onClick={(e) => {
            e.preventDefault();
            unsetFavorite();
          }}
        />
      ) : (
        <Heart
          size={18}
          weight="duotone"
          className="btnAdd"
          onClick={(e) => {
            e.preventDefault();
            setFavorite();
          }}
        />
      )}
    </span>
  );
};

export default ToggleFavorite;
